import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
// Models
import { config } from "@/models/constant/routes.constant";
// Containers
const TheContainer = () =>
  import(/* webpackPrefetch: true */ "@/containers/TheContainer.vue");
// Auth Routes
import AuthRoutes from "@/views/auth/index.routes";

// Error Routes
import ErrorRoutes from "@/views/error-page/index.routes";

// Not Authorize Routes
import Notauthorized from "@/views/notauthorized-page/index.routes";
// Dashboard Routes
import DashboardRoutes from "@/views/dashboard/index.routes";
// User Routes
import UserRoutes from "@/views/user-management/index.routes";
// Contact Data
import ContactData from "@/views/contact-data/index.routes";
// Setup
import Settings from "@/views/settings/index.routes";
import Inventory from "@/views/logistic/index.routes";
import GeneralJournal from "@/views/general-journal/index.routes";
import { Mutations } from "@/models/enums/mutations.enum";
// QR Page
import QR from "@/views/qrcode/index.routes";

// sales
import Sales from "@/views/sales/index.routes";

import AccountReceivables from "@/views/account-receivables/index.routes";

import Reports from "@/views/reports/index.routes";

import { authLoader } from "@/loader";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: config.MAIN_ROUTE.path,
    redirect: config.MAIN_ROUTE.redirect,
    name: config.MAIN_ROUTE.name,
    component: TheContainer,
    meta: config.MAIN_ROUTE.meta,
    beforeEnter: authLoader,
    children: [
      DashboardRoutes,
      UserRoutes,
      ContactData,
      Settings,
      Inventory,
      GeneralJournal,
      QR,
      Sales,
      AccountReceivables,
      Reports,
    ],
  },
  AuthRoutes,
  ErrorRoutes,
  Notauthorized,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const state = store.state;
  // set loading component
  store.commit(Mutations.SET_LOADING_COMPONENT, true);
  if (to.meta.need_auth) {
    if (!state.access_token) {
      next({ path: config.SIGNIN_ROUTE.url });
    } else {
      next();
    }
  } else if (to.name === "SignIn" && state.access_token) {
    next({ path: config.DASHBOARD_ROUTE.url });
  } else {
    next();
  }
});

router.afterEach(() => {
  store.commit(Mutations.SET_LOADING_COMPONENT, false);
});

export default router;
