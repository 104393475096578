import {
  initAdditionalPrivilegeRow,
  initForm,
} from "@/store/resource/user.resource";
import { AdditionalPrivilegeRow, FormValue } from "@/store/user.store";
import { ListAdditionalPrivilegeDto } from "@interface/privilege.interface";
import {
  CreateAdditionalPrivilegeDto,
  CreateUserDto,
  DetailUserDto,
  UpdateUserDto,
} from "@interface/user.interface";
import { userServices } from "@service/user.service";

const useUser = () => {
  const create = (body: CreateUserDto): Promise<boolean> => {
    return userServices.create(body);
  };

  const findById = (id: string): Promise<DetailUserDto> => {
    return userServices.getDetail(id);
  };

  const update = (id: string, body: UpdateUserDto) => {
    return userServices.updateUser(body, id);
  };

  const initCreateDto = (): CreateUserDto => ({
    active: false,
    additionalPrivilege: [],
    email: "",
    employeeId: "",
    password: "",
    roleIds: [],
    username: "",
  });

  const initUpdateDto = (): UpdateUserDto => ({
    active: false,
    additionalPrivilege: [],
    email: "",
    employeeId: "",
    roleIds: [],
    username: "",
    deleteAddPrivilegeIds: [],
  });

  const initAdditionalPrivilegeCreateDto = (): CreateAdditionalPrivilegeDto => ({
    create: "",
    delete: "",
    id: "",
    idMenu: "",
    read: "",
    update: "",
  });

  const mapAdditionalPrivilegeFormToCreateDto = (
    row: AdditionalPrivilegeRow
  ): CreateAdditionalPrivilegeDto => {
    const req: CreateAdditionalPrivilegeDto = initAdditionalPrivilegeCreateDto();

    req.create = row.isCreate ? row.create : "";
    req.delete = row.isDelete ? row.delete : "";
    req.id = row.id;
    req.idMenu = row.idMenu;
    req.read = row.isRead ? row.read : "";
    req.update = row.isUpdate ? row.update : "";

    return req;
  };

  const mapFormToCreateDto = (form: FormValue): CreateUserDto => {
    const req: CreateUserDto = initCreateDto();

    req.active = form.active;
    req.additionalPrivilege = form.additionalPrivilege.map(
      mapAdditionalPrivilegeFormToCreateDto
    );
    req.email = form.email;
    req.employeeId = form.employeeId;
    req.password = form.password;
    req.roleIds = form.roleNames.map((r) => r.key);
    req.username = form.username;

    return req;
  };

  const mapFormToUpdateDto = (form: FormValue): UpdateUserDto => {
    const req = initUpdateDto();

    req.active = form.active;
    req.additionalPrivilege = form.additionalPrivilege.map(
      mapAdditionalPrivilegeFormToCreateDto
    );
    req.email = form.email;
    req.employeeId = form.employeeId;
    req.roleIds = form.roleNames.map((r) => r.key);
    req.username = form.username;
    req.deleteAddPrivilegeIds = form.deleteAddPrivilegeIds;

    return req;
  };

  const mapDetailAdditionalPrivilegeToForm = (
    dto: ListAdditionalPrivilegeDto
  ): AdditionalPrivilegeRow => {
    const row = initAdditionalPrivilegeRow();

    row.create = dto.create;
    row.delete = dto.delete;
    row.id = dto.id;
    row.idMenu = dto.menuId;
    row.read = dto.read;
    row.update = dto.update;
    row.menuDesc = dto.menuDesc;
    row.menuName = dto.menuName;
    row.isCreate = !!dto.create;
    row.isDelete = !!dto.delete;
    row.isRead = !!dto.read;
    row.isUpdate = !!dto.update;

    return row;
  };

  const mapDetailDtoToForm = (dto: DetailUserDto): FormValue => {
    const form = initForm();

    form.active = dto.active;
    form.additionalPrivilege = dto.additionalPrivileges.map(
      mapDetailAdditionalPrivilegeToForm
    );
    form.email = dto.email;
    form.employeeId = dto.employeeId;
    form.employeeName = dto.employeeName;
    form.roleNames = dto.roles.map((item) => ({
      key: item.id,
      label: item.name,
    }));
    form.username = dto.username;
    form.deleteAddPrivilegeIds = [];

    return form;
  };

  return {
    create,
    initCreateDto,
    mapFormToCreateDto,
    initAdditionalPrivilegeCreateDto,
    findById,
    mapDetailDtoToForm,
    mapDetailAdditionalPrivilegeToForm,
    update,
    mapFormToUpdateDto,
    initUpdateDto,
  };
};

export { useUser };
