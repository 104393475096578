export const APP_ROLES = {
  ADMINISTRATOR: "Administrator",
  ACCOUNTING: "Accounting",
  ACCOUNTING_PIC: "Accounting PIC",
  HR: "HR",
  FINANCE: "Finance",
  FINANCE_PIC: "Finance PIC",
  MARKETING: "Marketing",
  MARKETING_PIC: "Marketing PIC",
  WAREHOUSE: "Warehouse",
  SALES: "Sales",
  SALES_PIC: "Sales PIC",
  WAREHOUSE_PIC: "Warehouse PIC",
  PURCHASING: "Purchasing",
  PURCHASING_PIC: "Purchasing PIC",
  MEATROOM: "Meatroom",
  MEATROOM_PIC: "Meatroom PIC",
  OPERATIONAL: "Operational",
  OPERATIONAL_PIC: "Operational PIC",
  DELIVERY: "Delivery",
  DELIVERY_PIC: "Delivery PIC",
  SUPER_ADMINISTRATOR: "SUPER ADMINISTRATOR",
};
