
import { Mutations } from '@/models/enums/mutations.enum'
import { PropsModel,  StorageStateProductModel } from '@/models/interface/common.interface'
import { productData , multiPriceData  , uomData , supplierData} from "@/models/interface/product.interface";
const state:StorageStateProductModel = {
  productData : {} as productData ,
  multiPriceData: [] as multiPriceData[],
  uomData: [] as uomData[],
  supplierData: [] as supplierData[],
}

const mutations = {
  [Mutations.SET_PRODUCT]: (state: PropsModel, payload: any) => {
    state.productData = payload
  },
  [Mutations.SET_MULTIPRICE]: (state: PropsModel, payload: any) => {
    state.multiPriceData = payload
  },
  [Mutations.SET_UOM]: (state: PropsModel, payload: any) => {
    state.uomData = payload
  },
  [Mutations.SET_SUPPLIER]: (state: PropsModel, payload: any) => {
    state.supplierData = payload
  },
}

export default {
  namespaced: true,
  state,
  mutations
}