
import * as auth from "./routes/auth.constant";
import * as dashboard from "./routes/dashboard.constant";
import * as user from "./routes/user.constant";
import * as contactdata from "./routes/contactdata.constant";
import * as settings from "./routes/settings.constant";
import * as accountreceivables from "./routes/accountreceivables.constant";
import * as inquiryassets from "./routes/inquiryassets.constant";
import * as inventory from "./routes/logistic.constant";
import * as qr from "./routes/qr.constant";
import * as generaljournal from "./routes/generaljournal.constant";
import * as sales from "./routes/sales.constant";
import * as reports from "./routes/reports.constant";
// Routes
export const MAIN_ROUTE = {
  path: "/",
  url: "/",
  name: "Main",
  // redirect: "/dashboard",
  redirect: "/auth/signin",
  meta: {
    breadcrumb: {
      name: "lbl_home",
      icon: "home",
    },
    title_head: "lbl_home",
    need_auth: true,
  }
};

export const config = {
  MAIN_ROUTE,
  ...auth,
  ...user,
  ...dashboard,
  ...contactdata,
  ...settings,
  ...accountreceivables,
  ...inquiryassets,
  ...inventory,
  ...qr,
  ...generaljournal,
  ...sales,
  ...reports,
};