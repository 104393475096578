import { initFormResidue, IResidueForm } from "./resource/job-costing-meatroom.resource";

type Store = {
    placeholder: {
        residueForm: IResidueForm,
    },
};

const state: Store = {
    placeholder: {
        residueForm: initFormResidue(),
    },
};

const mutations = {
    ["SET_PLACEHOLDER_FORM_RESIDUE"]: (st: Store, payload: IResidueForm): void => {
        st.placeholder.residueForm = payload;
    },
};

const actions = {
    ["RESET_STORE"]: ({ commit }): void => {
        commit("SET_PLACEHOLDER_FORM_RESIDUE", initFormResidue());
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};