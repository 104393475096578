import { DrawerErrorDto } from "./drawer-error-types";

export const actions = {
  toggle: ({ commit }, payload: DrawerErrorDto): void => {
    commit("setTitle", payload.title);
    commit("setMessage", payload.message);
    commit("setCode", payload.code);
    commit("setVisible", payload.visible);
  },
};
