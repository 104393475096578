export enum MENU_STRING {
  FILE_SERVICE_MANAGEMENT = "File Service Management",
  INVENTORY_LINE_MANAGEMENT = "Inventory Line Management",
  STOCK_VALUATION_MANAGEMENT = "Stock Valuation Management",
  STOCK_CARD_MANAGEMENT = "Stock Card Management",
  TAX_INVOICE_SERIAL_NUMBER_MANAGEMENT = "Tax Invoice Serial Number Management",
  TAX_INVOICE_CODE_MANAGEMENT = "Tax Invoice Code Management",
  PREFERENCES_MANAGEMENT = "Preferences Management",
  RESULT_WORK_ORDER_MANAGEMENT = "Result Work Order Management",
  WAREHOUSE_TRANSFER_MANAGEMENT = "Warehouse Transfer Management",
  BRANCH_WAREHOUSE_MANAGEMENT = "Branch Warehouse Management",
  WAREHOUSE_MANAGEMENT = "Warehouse Management",
  WAREHOUSE_LOCATION_MANAGEMENT = "Warehouse Location Management",
  BANK_MANAGEMENT = "Bank Management",
  MASTER_TYPE_MANAGEMENT = "Master Type Management",
  CALENDAR_MANAGEMENT = "Calendar Management",
  COMPANY_MANAGEMENT = "Company Management",
  ROLE_MANAGEMENT = "Role Management",
  MENU_MANAGEMENT = "Menu Management",
  USER_MANAGEMENT = "User Management",
  PRIVILEGE_MANAGEMENT = "Privilege Management",
  PRODUCT_MANAGEMENT = "Product Management",
  PRODUCT_UOM_MANAGEMENT = "Product Uom Management",
  UOM_CONVERSION_MANAGEMENT = "Uom Conversion Management",
  PRODUCT_CATEGORY_MANAGEMENT = "Product Category Management",
  ASSET_BOOK_MANAGEMENT = "Asset Book Management",
  ASSET_CATEGORY_MANAGEMENT = "Asset Category Management",
  ASSET_ADDITION_MANAGEMENT = "Asset Addition Management",
  ASSET_HISTORY_MANAGEMENT = "Asset History Management",
  ASSET_MANAGEMENT = "Asset Management",
  ASSET_RETIREMENT_MANAGEMENT = "Asset Retirement Management",
  ACCOUNTING_ACCOUNT_MANAGEMENT = "Accounting Account Management",
  ACCOUNTING_TYPE_MANAGEMENT = "Accounting Type Management",
  ACCOUNTING_TAX_MANAGEMENT = "Accounting Tax Management",
  ACCOUNTING_CURRENCY_MANAGEMENT = "Accounting Currency Management",
  ACCOUNTING_CONVERSION_MANAGEMENT = "Accounting Conversion Management",
  ACCOUNTING_GENERAL_JOURNAL_MANAGEMENT = "Accounting General Journal Management",
  CONTACT_DATA_MANAGEMENT = "Contact Data Management",
  ASSET_DEPRECIATION = "Asset Depreciation",
  STOCK_ADJUSTMENT_MANAGEMENT = "Stock Adjustment Management",
  ASSET_RECLASS = "Asset Reclass",
  INVENTORY_RECEIVE = "Inventory Receive",
  SALES_RETURN = "Sales Return",
  WORK_ORDER = "Work Order",
  GOOD_RECEIPT_MANAGEMENT_WAREHOUSE = "Good Receipt Management Warehouse",
  BATCH = "Batch",
  QUOTATION = "Quotation",
  JOB_COASTING_WAREHOUSE = "Job Coasting Warehouse",
  JOB_COASTING_MEATROOM = "Job Coasting Meatroom",
  DELIVERY_ORDER = "Delivery Order",
  SALES_ORDER = "Sales Order",
  APPROVAL_MANAGEMENT_OPNAME = "Approval Management Opname",
  SALES_ORDER_TRACKING = "Sales Order Tracking",
  APPROVAL_MANAGEMENT_SALES_ORDER = "Approval Management Sales Order",
  APPROVAL_MANAGEMENT_DELIVERY_ORDER = "Approval Management Delivery Order",
  SALES_ORDER_WAREHOUSE = "Sales Order Warehouse",
  INVOICE_PREPAYMENT = "Invoice Prepayment",
  INVOICE_RECEIPT = "Invoice Receipt",
  INVOICE_AR = "Invoice AR",
  CODE_TRANSLATOR = "Code Translator",
  HPP = "HPP",
  APPROVAL_INVENTORY_RECEIVE = "Approval Inventory Receive",
  INVOICE_JOIN = "Invoice Join",
  APPROVAL_INVOICE_RECEIPT = "Approval Invoice Receipt",
  SALES_DETAIL = "Sales Detail",
  SALES_DETAIL_MARKETING = "Sales Detail Marketing",
}
