// Interfaces
import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../../interface/common.interface";

export const LOGISTIC_ROUTE: RoutePathModel = {
  path: "/logistic",
  url: "/logistic",
  name: "Logistic",
  meta: {
    breadcrumb: "Logistic",
    title_head: "Logistic",
    need_auth: true,
  },
};

export const REPORT_VALUATION_DETAIL: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/report/valuation-detail`,
  url: `${LOGISTIC_ROUTE.url}/report/valuation-detail`,
  name: "logistic.report.valuationdetail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "lbl_report",
        icon: "book",
      },
      {
        name: "lbl_valuation_report_detail",
        href: `${LOGISTIC_ROUTE.url}/report/valuation-detail`,
      },
    ],
    title_head: "lbl_valuation_report_detail",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const REPORT_HISTORY_BATCH: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/report/history-batch`,
  url: `${LOGISTIC_ROUTE.url}/report/history-batch`,
  name: "logistic.report.historybatch",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "lbl_report",
        icon: "book",
      },
      {
        name: "lbl_report_history_batch",
        href: `${LOGISTIC_ROUTE.url}/report/history-batch`,
      },
    ],
    title_head: "lbl_report_history_batch",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const REPORT_STOCK_OPNAME: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/report/stock-opname`,
  url: `${LOGISTIC_ROUTE.url}/report/stock-opname`,
  name: "logistic.report.stockopname",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "lbl_report",
        icon: "book",
      },
      {
        name: "lbl_report_stock_opname",
        href: `${LOGISTIC_ROUTE.url}/report/stock-opname`,
      },
    ],
    title_head: "lbl_report_stock_opname",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const REPORT_HPP_AVERAGE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/report/hpp`,
  url: `${LOGISTIC_ROUTE.url}/report/hpp`,
  name: "logistic.report.hpp",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "lbl_report",
        icon: "book",
      },
      {
        name: "lbl_report_hpp",
        href: `${LOGISTIC_ROUTE.url}/report/hpp`,
      },
    ],
    title_head: "lbl_report_hpp",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const CLOSEPERIODELOGISTIC_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/closeperiod`,
  url: `${LOGISTIC_ROUTE.url}/closeperiod`,
  name: "Close Period",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "Close Period",
        href: `${LOGISTIC_ROUTE.url}/closeperiod`,
      },
    ],
    title_head: "Close Period",
    need_auth: true,
  },
};

export const GOODRECEIPT_IMPORT_COST: RoutePathModel = {
  path: "/logistic/receivingitems/goodreceipt/import-cost",
  url: "/logistic/receivingitems/goodreceipt/import-cost",
  name: "logistic.receivingitems.goodreceipt.importcost",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "lbl_good_receipt",
        href: "/logistic/receivingitems/goodreceipt",
      },
      {
        name: "lbl_import_cost",
        href: "",
      },
    ],
    title_head: "lbl_import_cost",
    need_auth: true,
    mode: Mode.CREATE,
  },
};

export const GOODRECEIPT_ROUTE: RoutePathModel = {
  path: "/logistic/receivingitems/goodreceipt",
  url: "/logistic/receivingitems/goodreceipt",
  name: "logistic.receivingitems.goodreceipt",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "lbl_good_receipt",
        href: "/logistic/receivingitems/goodreceipt",
      },
    ],
    title_head: "lbl_good_receipt",
    mode: Mode.VIEW,
    need_auth: true,
  },
};
export const GOODRECEIPT_EDIT_ROUTE: RoutePathModel = {
  path: "/logistic/receivingitems/goodreceipt/edit/:id",
  url: "/logistic/receivingitems/goodreceipt/edit/:id",
  name: "logistic.receivingitems.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "lbl_good_receipt",
        href: "/logistic/receivingitems/goodreceipt",
      },
      {
        name: "lbl_edit_good_receipt",
        href: "",
      },
    ],
    title_head: "lbl_edit_good_receipt",
    mode: Mode.EDIT,
    need_auth: true,
  },
};
export const GOODRECEIPT_VIEW_ROUTE: RoutePathModel = {
  path: "/logistic/receivingitems/goodreceipt/view/:id",
  url: "/logistic/receivingitems/goodreceipt/view/:id",
  name: "logistic.receivingitems.view",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "lbl_good_receipt",
        href: "/logistic/receivingitems/goodreceipt",
      },
      {
        name: "lbl_view_good_receipt",
        href: "",
      },
    ],
    title_head: "lbl_view_good_receipt",
    mode: Mode.VIEW,
    need_auth: true,
  },
};
export const GOODRECEIPT_CHECKLIST_CREATE: RoutePathModel = {
  path: "/logistic/receivingitems/goodreceiptchecklist/create",
  url: "/logistic/receivingitems/goodreceiptchecklist/create",
  name: "logistic.receivingitems.grchecklist.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "lbl_transaction_logistic",
        icon: "file-text",
      },
      {
        name: "lbl_good_receipt",
        href: "/logistic/receivingitems/goodreceipt",
      },
      {
        name: "lbl_create_good_receipt_checklist",
        href: "/logistic/receivingitems/goodreceiptchecklist/create",
      },
    ],
    title_head: "lbl_create_good_receipt_checklist",
    mode: Mode.CREATE,
    need_auth: true,
  },
};
export const GOODRECEIPT_CHECKLIST_VIEW: RoutePathModel = {
  path: "/logistic/receivingitems/goodreceiptchecklist/view/:id",
  url: "/logistic/receivingitems/goodreceiptchecklist/view/:id",
  name: "logistic.receivingitems.grchecklist.view",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "lbl_transaction_logistic",
        icon: "file-text",
      },
      {
        name: "lbl_good_receipt",
        href: "/logistic/receivingitems/goodreceipt",
      },
      {
        name: "lbl_view_good_receipt",
        href: "/logistic/receivingitems/goodreceiptchecklist/view/:id",
      },
    ],
    title_head: "lbl_view_good_receipt",
    mode: Mode.VIEW,
    need_auth: true,
  },
};
export const GOODRECEIPT_CHECKLIST_EDIT: RoutePathModel = {
  path: "/logistic/receivingitems/goodreceiptchecklist/edit/:id",
  url: "/logistic/receivingitems/goodreceiptchecklist/edit/:id",
  name: "logistic.receivingitems.grchecklist.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "lbl_transaction_logistic",
        icon: "file-text",
      },
      {
        name: "lbl_good_receipt",
        href: "/logistic/receivingitems/goodreceipt",
      },
      {
        name: "lbl_edit_good_receipt",
        href: "/logistic/receivingitems/goodreceiptchecklist/view/:id",
      },
    ],
    title_head: "lbl_edit_good_receipt",
    mode: Mode.EDIT,
    need_auth: true,
  },
};
export const SETUPPRODUCTSREAD_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/setupproduct/read`,
  url: `${LOGISTIC_ROUTE.url}/setupproduct/read`,
  name: "logistic.setupproduct",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "lbl_product",
        icon: "snippets",
      },
      {
        name: "lbl_setup_product",
        href: "/logistic/setupproduct/read",
      },
    ],
    title_head: "lbl_product",
    mode: Mode.READ,
    need_auth: true,
  },
};
export const SETUPPRODUCTSUPDATE_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/setupproduct/update/:id`,
  url: `${LOGISTIC_ROUTE.url}/setupproduct/update/:id`,
  name: "logistic.setupproduct.update",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "lbl_product",
        icon: "snippets",
      },
      {
        name: "lbl_setup_product",
        href: "/logistic/setupproduct/read",
      },
      {
        name: "lbl_setup_product_update",
        href: "/logistic/setupproduct/update/:id",
      },
    ],
    title_head: "lbl_setup_product_update",
    mode: Mode.EDIT,
    need_auth: true,
  },
};
export const SETUPPRODUCTSCREATE_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/setupproduct/create`,
  url: `${LOGISTIC_ROUTE.url}/setupproduct/create`,
  name: "logistic.setupproduct.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "lbl_product",
        icon: "snippets",
      },
      {
        name: "lbl_setup_product",
        href: "/logistic/setupproduct/read",
      },
      {
        name: "lbl_setup_product_create",
        href: "/logistic/setupproduct/create",
      },
    ],
    title_head: "lbl_setup_product_create",
    mode: Mode.CREATE,
    need_auth: true,
  },
};
export const CREATEPRODUCTSCATEGORY_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/setupproduct/category/create`,
  url: `${LOGISTIC_ROUTE.url}/setupproduct/category/create`,
  name: "Setup Products Category Create",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "Product",
        icon: "snippets",
      },
      {
        name: "Setup Product Category",
        href: "/logistic/setupproduct/category/read",
      },
      {
        name: "Setup Product Category Create",
        href: "/logistic/setupproduct/category/create",
      },
    ],
    title_head: "Setup Products Category Create",
    need_auth: true,
  },
};
export const READPRODUCTSCATEGORY_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/setupproduct/category/read`,
  url: `${LOGISTIC_ROUTE.url}/setupproduct/category/read`,
  name: "Setup Products Category",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "Product",
        icon: "snippets",
      },
      {
        name: "Setup Product Category",
        href: "/logistic/setupproduct/category/read",
      },
    ],
    title_head: "Setup Products Category",
    need_auth: true,
  },
};
export const WAREHOUSE_ROUTE: RoutePathModel = {
  path: "warehouse",
  url: `${LOGISTIC_ROUTE.url}/warehouse`,
  name: "Warehouse",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "Warehouse",
        href: "/logistic/warehouse",
      },
    ],
    title_head: "Warehouse",
    need_auth: true,
  },
};
export const WAREHOUSEBRANCH_ROUTE: RoutePathModel = {
  path: "warehousebranch",
  url: `${LOGISTIC_ROUTE.url}/warehousebranch`,
  name: "Warehouse Branch",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "Warehouse Branch",
        href: "/logistic/warehousebranch",
      },
    ],
    title_head: "Warehouse Branch",
    need_auth: true,
  },
};
export const WAREHOUSELOCATION_ROUTE: RoutePathModel = {
  path: "warehouselocation",
  url: `${LOGISTIC_ROUTE.url}/warehouselocation`,
  name: "Warehouse Location",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "Warehouse Location",
        href: "/logistic/warehouselocation",
      },
    ],
    title_head: "Warehouse Location",
    need_auth: true,
  },
};
export const WAREHOUSETRANSFER_ROUTE: RoutePathModel = {
  path: "warehousetransfer",
  url: `${LOGISTIC_ROUTE.url}/warehousetransfer`,
  name: "logistic.warehousetransfer.form",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "Warehouse Transfer Setup",
        href: "/logistic/warehousetransfer",
      },
    ],
    title_head: "Warehouse Transfer",
    mode: Mode.EDIT,
    need_auth: true,
  },
};
export const WAREHOUSETRANSFERLIST_ROUTE: RoutePathModel = {
  path: "warehousetransferlist",
  url: `${LOGISTIC_ROUTE.url}/warehousetransferlist`,
  name: "logistic.warehousetransfer.list",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "Warehouse Transfer List",
        href: "/logistic/warehousetransferlist",
      },
    ],
    title_head: "Warehouse Transfer List",
    need_auth: true,
  },
};
export const WORKORDERCREATE_ROUTE: RoutePathModel = {
  path: "workorder/create",
  url: `${LOGISTIC_ROUTE.url}/workorder/create`,
  name: "Create Work Order",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "List Work Order",
        href: `${LOGISTIC_ROUTE.url}/workorder/list`,
      },
      {
        name: "Create Work Order",
        href: `${LOGISTIC_ROUTE.url}/workorder/create`,
      },
    ],
    title_head: "Create Work Order",
    need_auth: true,
  },
};
export const WORKORDEREDIT_ROUTE: RoutePathModel = {
  path: "workorder/edit/:id",
  url: `${LOGISTIC_ROUTE.url}/workorder/edit/:id`,
  name: "Edit Work Order",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "List Work Order",
        href: `${LOGISTIC_ROUTE.url}/workorder/list`,
      },
      {
        name: "Create Work Order",
        href: `${LOGISTIC_ROUTE.url}/workorder/edit/:id`,
      },
    ],
    title_head: "Edit Work Order",
    need_auth: true,
  },
};
export const WORKORDERLIST_ROUTE: RoutePathModel = {
  path: "workorder/list",
  url: `${LOGISTIC_ROUTE.url}/workorder/list`,
  name: "List Work Order",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "List Work Order",
        href: `${LOGISTIC_ROUTE.url}/workorder/list`,
      },
    ],
    title_head: "List Work Order",
    need_auth: true,
  },
};
export const RESULTWORKORDER_ROUTE: RoutePathModel = {
  path: "workorder/result",
  url: `${LOGISTIC_ROUTE.url}/workorder/result`,
  name: "logistic.workorder.result",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "Result Customer Maintenance",
        href: `${LOGISTIC_ROUTE.url}/workorder/result`,
      },
    ],
    title_head: "Result Customer Maintenance",
    need_auth: true,
  },
};
export const CREATERESULTWORKORDER_ROUTE: RoutePathModel = {
  path: "workorder/result/create",
  url: `${LOGISTIC_ROUTE.url}/workorder/result/create`,
  name: "logistic.workorder.result.create",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "Result Customer Maintenance",
        href: `${LOGISTIC_ROUTE.url}/workorder/result`,
      },
      {
        name: "Create Result Customer Maintenance",
        href: `${LOGISTIC_ROUTE.url}/workorder/result/create`,
      },
    ],
    title_head: "Result Customer Maintenance",
    need_auth: true,
  },
};
export const VIEWRESULTWORKORDER_ROUTE: RoutePathModel = {
  path: "workorder/result/view/:id",
  url: `${LOGISTIC_ROUTE.url}/workorder/result/view/:id`,
  name: "Result Customer Maintenance",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "Result Customer Maintenance",
        href: `${LOGISTIC_ROUTE.url}/workorder/result`,
      },
      {
        name: "Create Result Customer Maintenance",
        href: `${LOGISTIC_ROUTE.url}/workorder/result/view/:id`,
      },
    ],
    title_head: "Result Customer Maintenance",
    mode: Mode.VIEW,
    need_auth: true,
  },
};
export const INVENTORY_ROUTE: RoutePathModel = {
  path: "inventory",
  url: `${LOGISTIC_ROUTE.url}/inventory`,
  name: "Inventory",
  meta: {
    breadcrumb: "Inventory",
    title_head: "Inventory",
    need_auth: true,
  },
};

export const READSTOCKADJUSMENT_ROUTE: RoutePathModel = {
  path: "stockadjustment/read",
  url: `${LOGISTIC_ROUTE.url}/stockadjustment/read`,
  name: "logistic.stockadjustment.list",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "Stock Adjustment",
        href: `${LOGISTIC_ROUTE.url}/stockadjustment/read`,
      },
    ],
    title_head: "Stock Adjustment",
    need_auth: true,
  },
};

export const EDITSTOCKADJUSMENT_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/stockadjustment/edit/:id`,
  url: `${LOGISTIC_ROUTE.url}/stockadjustment/edit/:id`,
  name: "logistic.stockadjustment.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "Edit Stock Adjustment",
        href: `${LOGISTIC_ROUTE.url}/stockadjustment/edit/:id`,
      },
    ],
    title_head: "Edit Stock Adjustment",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const VIEWSTOCKADJUSMENT_ROUTE: RoutePathModel = {
  path: "stockadjustment/view/:id",
  url: `${LOGISTIC_ROUTE.url}/stockadjustment/view/:id`,
  name: "logistic.stockadjustment.view",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "View Stock Adjustment",
        href: `${LOGISTIC_ROUTE.url}/stockadjustment/view/:id`,
      },
    ],
    title_head: "View Stock Adjustment",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const STOCKADJUSMENT_ROUTE: RoutePathModel = {
  path: "stockadjustment/create",
  url: `${LOGISTIC_ROUTE.url}/stockadjustment/create`,
  name: "logistic.stockadjustment.create",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "Create Stock Adjustment",
        href: `${LOGISTIC_ROUTE.url}/stockadjustment/create`,
      },
    ],
    title_head: "Create Stock Adjustment",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

export const REPORTS_STOCK_CARD_ROUTE: RoutePathModel = {
  path: "/logistic/reports/stockcard",
  url: "/logistic/reports/stockcard",
  name: "logistic.reports.stockcard",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "lbl_stock_card",
        href: `${LOGISTIC_ROUTE.url}/reports/stockcard`,
      },
    ],
    title_head: "lbl_stock_card",
    need_auth: true,
  },
};

export const REPORTSWORKORDER_ROUTE: RoutePathModel = {
  path: "/logistic/reports/workorder",
  url: "/logistic/reports/workorder",
  name: "Report Work Order",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "Report Work Order",
        href: `${LOGISTIC_ROUTE.url}/reports/workorder`,
      },
    ],
    title_head: "Reports Logistic",
    need_auth: true,
  },
};

export const LISTOFPRODUCTBRANCH_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/listofproduct/branch`,
  url: `${LOGISTIC_ROUTE.url}/listofproduct/branch`,
  name: "List Of Product Branch",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "List Of Product",
        icon: "book",
      },
      {
        name: "Branch",
        href: "/logistic/listofproduct/branch",
      },
    ],
    title_head: "List Of Product Branch",
    need_auth: true,
  },
};

export const LISTOFPRODUCTWAREHOUSE_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/listofproduct/warehouse`,
  url: `${LOGISTIC_ROUTE.url}/listofproduct/warehouse`,
  name: "List Of Product Warehouse",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "List Of Product",
        icon: "book",
      },
      {
        name: "Warehouse",
        href: "/logistic/listofproduct/warehouse",
      },
    ],
    title_head: "List Of Product Warehouse",
    need_auth: true,
  },
};
export const LISTOFPRODUCTRACK_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/listofproduct/rack`,
  url: `${LOGISTIC_ROUTE.url}/listofproduct/rack`,
  name: "List Of Product Rack",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "List Of Product",
        icon: "book",
      },
      {
        name: "Rack",
        href: "/logistic/listofproduct/rack",
      },
    ],
    title_head: "List Of Product Rack",
    need_auth: true,
  },
};
export const LISTOFPRODUCT_ROUTE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/listofproduct`,
  url: `${LOGISTIC_ROUTE.url}/listofproduct`,
  name: "logistic.listofproduct",
  meta: {
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "database",
      },
      {
        name: "lbl_list_of_product",
        icon: "book",
        href: "/logistic/listofproduct",
      },
    ],
    title_head: "lbl_list_of_product",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const PRODUCT_REPORT_BOTTOM_PRICE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.url}/product/report/bottom-price`,
  url: `${LOGISTIC_ROUTE.url}/product/report/bottom-price`,
  name: "logistic.product.report.bottomprice",
  meta: {
    breadcrumb: [
      {
        name: "Logistic",
        icon: "database",
      },
      {
        name: "Report Bottom Price",
        href: "/logistic/product/report/bottom-price",
      },
    ],
    title_head: "Product Report Bottom Price",
    need_auth: true,
  },
};

export const JOB_COSTING_LIST: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.path}/job-costing`,
  url: `${LOGISTIC_ROUTE.url}/job-costing`,
  name: "sales.transactionsales.jobcosting",
  meta: {
    title_head: "lbl_job_costing",
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "",
      },
      {
        name: "lbl_job_costing",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const JOB_COSTING_LOAF_CREATE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.path}/job-costing/loaf/create`,
  url: `${LOGISTIC_ROUTE.url}/job-costing/loaf/create`,
  name: "sales.transactionsales.jobcosting.loaf.create",
  meta: {
    title_head: "lbl_job_costing_loaf",
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "",
      },
      {
        name: "lbl_job_costing",
        icon: "",
      },
      {
        name: "lbl_job_costing_loaf",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.CREATE,
  },
};

export const JOB_COSTING_LOAF_VIEW: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.path}/job-costing/loaf/view/:id`,
  url: `${LOGISTIC_ROUTE.url}/job-costing/loaf/view/:id`,
  name: "sales.transactionsales.jobcosting.loaf.view",
  meta: {
    title_head: "lbl_job_costing_loaf",
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "",
      },
      {
        name: "lbl_job_costing",
        icon: "",
      },
      {
        name: "lbl_job_costing_loaf",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const JOB_COSTING_WAREHOUSE_PICKED_CREATE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.path}/job-costing/warehouse-picked/create/:id`,
  url: `${LOGISTIC_ROUTE.url}/job-costing/warehouse-picked/create/:id`,
  name: "sales.transactionsales.jobcosting.warehousepicked.create",
  meta: {
    title_head: "lbl_warehouse_picked",
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "",
      },
      {
        name: "lbl_job_costing",
        icon: "",
      },
      {
        name: "lbl_warehouse_picked",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.CREATE,
  },
};

export const JOB_COSTING_WAREHOUSE_PICKED_VIEW: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.path}/job-costing/warehouse-picked/view/:id`,
  url: `${LOGISTIC_ROUTE.url}/job-costing/warehouse-picked/view/:id`,
  name: "sales.transactionsales.jobcosting.warehousepicked.view",
  meta: {
    title_head: "lbl_warehouse_picked",
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "",
      },
      {
        name: "lbl_job_costing",
        icon: "",
      },
      {
        name: "lbl_warehouse_picked",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const JOB_COSTING_MEATROOM_CREATE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.path}/job-costing/meatroom/create/:id`,
  url: `${LOGISTIC_ROUTE.url}/job-costing/meatroom/create/:id`,
  name: "sales.transactionsales.jobcosting.meatroom.create",
  meta: {
    title_head: "lbl_meatroom_process",
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "",
      },
      {
        name: "lbl_job_costing",
        icon: "",
      },
      {
        name: "lbl_meatroom_process",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.CREATE,
  },
};

export const JOB_COSTING_MEATROOM_VIEW: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.path}/job-costing/meatroom/view/:id`,
  url: `${LOGISTIC_ROUTE.url}/job-costing/meatroom/view/:id`,
  name: "sales.transactionsales.jobcosting.meatroom.view",
  meta: {
    title_head: "lbl_meatroom_process",
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "",
      },
      {
        name: "lbl_job_costing",
        icon: "",
      },
      {
        name: "lbl_meatroom_process",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const JOB_COSTING_POST_MEATROOM_PROCESS_CREATE: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.path}/job-costing/post/meatroom/create/:id`,
  url: `${LOGISTIC_ROUTE.url}/job-costing/post/meatroom/create/:id`,
  name: "sales.transactionsales.jobcosting.post.meatroom.create",
  meta: {
    title_head: "lbl_job_costing_meatroom",
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "",
      },
      {
        name: "lbl_job_costing",
        icon: "",
      },
      {
        name: "lbl_job_costing_meatroom",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.CREATE,
  },
};

export const JOB_COSTING_POST_MEATROOM_PROCESS_VIEW: RoutePathModel = {
  path: `${LOGISTIC_ROUTE.path}/job-costing/post/meatroom/view/:id`,
  url: `${LOGISTIC_ROUTE.url}/job-costing/post/meatroom/view/:id`,
  name: "sales.transactionsales.jobcosting.post.meatroom.view",
  meta: {
    title_head: "lbl_post_meatroom_process",
    breadcrumb: [
      {
        name: "lbl_logistic",
        icon: "",
      },
      {
        name: "lbl_job_costing",
        icon: "",
      },
      {
        name: "lbl_post_meatroom_process",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.VIEW,
  },
};
