import { ITableRow } from "@interface/common.interface";

export const useTable = () => {
  const deleteRows = <T extends ITableRow<string>>(
    source: T[],
    selectedRowKeys: string[]
  ): { newSource: T[]; deletedRows: T[] } => {
    let $deletedLines: T[] = [];
    const $source = source.filter((el) => {
      if (selectedRowKeys.includes(el.key)) {
        $deletedLines = [...$deletedLines, el];
      }
      return !selectedRowKeys.includes(el.key);
    });
    return {
      newSource: $source,
      deletedRows: $deletedLines,
    };
  };

  return { deleteRows };
};
