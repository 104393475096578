






















import { DrawerErrorDto } from "@/store/drawer-error/drawer-error-types";
import Vue from "vue";
import { mapState, mapActions } from "vuex";

export default Vue.extend({
    name: "VDrawerError",
    computed: {
        ...mapState("drawerErrorStore", {
            visible: "visible",
            title: "title",
            message: "message",
            code: "code",
        }),
    },
    methods: {
        ...mapActions("drawerErrorStore", {
            toggle: "toggle",
        }),
        handleClose() {
            const dto: DrawerErrorDto = {
                visible: false,
                title: "",
                message: "",
                code: "",
            };
            this.toggle(dto);
        },
    },
});
