import { Mutations } from "@/models/enums/mutations.enum";
import { IAuthorities } from "@/models/interface-v2/auth.interface";
import { ListUserRoleDto } from "@/models/interface-v2/user.interface";
import { StorageSAuthtModel } from "@/models/interface/common.interface";
import { initAuthData } from "./resource/auth.resource";

const state = {
  authData: initAuthData(),
  userPrivileges: [] as IAuthorities[],
};

const getters = {
  ["GET_USER_ROLE"]: (stateVuex: typeof state): string[] => {
    return stateVuex.authData.roles.map((item: ListUserRoleDto) => item.name);
  },
  ["GET_USER_PRIVILEGES"]: (stateVuex: typeof state): IAuthorities[] => {
    return stateVuex.userPrivileges;
  },
  ["GET_USERNAME"]: (stateVuex: typeof state): string => {
    const [first, last] = stateVuex.authData.username.split("_");
    let username = "";
    if (first) {
      username = first.charAt(0).toUpperCase() + first.slice(1) + " ";
    }
    if (last) {
      username += last.charAt(0).toUpperCase() + last.slice(1);
    }
    return username;
  },
  ["GET_ADDITIONAL_MENU_PRIVILEGE"]: (stateVuex: typeof state): string[] => {
    return stateVuex.authData.additionalPrivileges.map<string>(
      (item) => item.menuName
    );
  },
  ["GET_USER_ROLE_AND_ADDITIONAL_MENU"]: (
    _stateVuex: typeof state,
    gettersVuex: typeof getters
  ): string[] => {
    const userRoles = gettersVuex["GET_USER_ROLE"] as string[];
    const userAdditionalMenu = gettersVuex[
      "GET_ADDITIONAL_MENU_PRIVILEGE"
    ] as string[];
    return userRoles.concat(userAdditionalMenu);
  },
};

const mutations = {
  [Mutations.SET_AUTH]: (stateVuex: StorageSAuthtModel, payload: any): void => {
    stateVuex.authData = payload;
  },
  ["SET_USER_PRIVILEGES"]: (
    stateVuex: typeof state,
    payload: IAuthorities[]
  ): void => {
    stateVuex.userPrivileges = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
