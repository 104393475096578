import { RouteConfig } from "vue-router";

// Models
import { config } from "@/models/constant/routes.constant";

const router: RouteConfig = {
    path: config.GENERALJOURNAL_ROUTE.path,
    name: config.GENERALJOURNAL_ROUTE.name,
    meta: config.GENERALJOURNAL_ROUTE.meta,
    component: {
        render(c) { return c("router-view"); }
    },
    children: [
        {
            path: config.READGENERALJOURNAL_ROUTE.path,
            name: config.READGENERALJOURNAL_ROUTE.name,
            meta: config.READGENERALJOURNAL_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./ListJournal.vue")
        },
        {
            path: config.CREATEGENERALJOURNAL_ROUTE.path,
            name: config.CREATEGENERALJOURNAL_ROUTE.name,
            meta: config.CREATEGENERALJOURNAL_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./CreateJournal.vue")
        },
        {
            path: config.UPDATEGENERALJOURNAL_ROUTE.path,
            name: config.UPDATEGENERALJOURNAL_ROUTE.name,
            meta: config.UPDATEGENERALJOURNAL_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./CreateJournal.vue")
        },
        {
            path: config.DETAILENERALJOURNAL_ROUTE.path,
            name: config.DETAILENERALJOURNAL_ROUTE.name,
            meta: config.DETAILENERALJOURNAL_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./DetailJournal.vue")
        },
        {
            path: config.GL_REPORT.path,
            name: config.GL_REPORT.name,
            meta: config.GL_REPORT.meta,
            component: () => import(/* webpackPrefetch: true */"./report/ReportGL.vue")
        },
    ]
};

export default router;
