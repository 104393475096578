import { Api } from "@/models/class/api.class";
import { ResponseListMenu } from "@interface/menu.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { Pagination } from "@interface/common.interface";
import { DetailRoleDto, ListRoleDto } from "@interface/role.interface";
import {
  CreateUserDto,
  DetailUserDto,
  ResponseDetailUserAuth,
  UpdateUserDto,
} from "@interface/user.interface";
import { AxiosError } from "axios";

export class UserServices extends HttpClient {
  constructor() {
    super();

    this.userDetailAuth = this.userDetailAuth.bind(this);
  }

  /**
   * get detail user from token auth
   * @returns detail user
   */
  userDetailAuth(): Promise<ResponseDetailUserAuth> {
    return this.get<ResponseDetailUserAuth>(Api.UserDetailAuth)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  create(payload: CreateUserDto): Promise<boolean> {
    return this.post<boolean, CreateUserDto>(Api.Users, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateUser(payload: UpdateUserDto, id: string): Promise<boolean> {
    return this.put<boolean, UpdateUserDto>(`${Api.Users}/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetail(id: string): Promise<DetailUserDto> {
    return this.get<DetailUserDto>(`${Api.Users}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getRoles(
    params: RequestQueryParamsModel
  ): Promise<Pagination<ListRoleDto[]>> {
    return this.get<Pagination<ListRoleDto[]>>(Api.Role, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getDetailRole(id: string): Promise<DetailRoleDto> {
    return this.get<DetailRoleDto>(`${Api.Role}/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getMenus(params: RequestQueryParamsModel): Promise<ResponseListMenu> {
    return this.get<ResponseListMenu>(Api.Menus, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const userServices = new UserServices();
