import Vue from "vue";
import { 
  Card,
  Button,
  Checkbox,
  Col,
  Row,
  DatePicker,
  Descriptions,
  Drawer,
  Dropdown,
  Divider,
  Icon,
  Input,
  InputNumber,
  Collapse,
  Avatar,
  Breadcrumb,
  Form,
  FormModel,
  Menu,
  Layout,
  PageHeader,
  Pagination,
  Popconfirm,
  Popover,
  Switch,
  Radio,
  Modal,
  Select,
  Table,
  Tabs,
  Tag, // ini ga tau pke ga
  TimePicker,
  Tooltip,
  Upload,
  Space,
  Empty, // di contact data,
  Result,
  message,
  notification,
  List,
  Alert,
  Badge
} from "ant-design-vue";

Vue.use(Alert);
Vue.use(Card);
Vue.use(Badge);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Col);
Vue.use(Row);
Vue.use(DatePicker);
Vue.use(Descriptions);
Vue.use(Drawer);
Vue.use(Dropdown);
Vue.use(Divider);
Vue.use(Icon);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Collapse);
Vue.use(Breadcrumb);
Vue.use(Form);
Vue.use(FormModel);
Vue.use(Menu);
Vue.use(Layout);
Vue.use(Pagination);
Vue.use(Popconfirm);
Vue.use(Popover);
Vue.use(Switch);
Vue.use(Radio);
Vue.use(Modal);
Vue.use(Select);
Vue.use(Table);
Vue.use(Tabs);
Vue.use(Tag);
Vue.use(TimePicker);
Vue.use(Tooltip);
Vue.use(Upload);
Vue.use(Avatar);
Vue.use(PageHeader);
Vue.use(Space);
Vue.use(Empty);
Vue.use(Result);
Vue.use(List);
Vue.prototype.$message = message;
Vue.prototype.$notification = notification;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$info = Modal.info;
