// Interfaces
import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../../interface/common.interface";

export const QR_ROUTE: RoutePathModel = {
  path: "/qr",
  url: "/qr",
  name: "QR",
  redirect: "/qr/scan",
  meta: {
    breadcrumb: [],
    title_head: "QR",
    need_auth: false
  }
};

export const SCAN_QR: RoutePathModel = {
  path: "/qr/scan",
  url: "/qr/scan",
  name: "Scan QR",
  meta: {
    breadcrumb: [
      {
        name: "QR",
        icon: "qrcode"
      },
      {
        name: "Scan QR Code",
        icon: ""
      },
    ],
    title_head: "Scan QR",
    need_auth: false
  }
};

export const DETAIL_QR: RoutePathModel = {
  path: "/qr/detail/:id",
  url: "/qr/detail/:id",
  name: "Detail QR",
  meta: {
    breadcrumb: [
      {
        name: "QR",
        icon: "qrcode"
      },
      {
        name: "Detail QR Code",
        icon: ""
      },
    ],
    title_head: "Detail QR",
    need_auth: false
  }
};

export const LIST_QR: RoutePathModel = {
  path: "/qr/list",
  url: "/qr/list",
  name: "qr.list",
  meta: {
    breadcrumb: [
      {
        name: "QR",
        icon: "qrcode"
      },
      {
        name: "List QR Code",
        icon: ""
      },
    ],
    title_head: "List QR",
    need_auth: false
  }
};

export const FORM_QR_CREATE: RoutePathModel = {
  path: "/qr/create",
  url: "/qr/create",
  name: "qr.create",
  meta: {
    breadcrumb: [
      {
        name: "QR",
        icon: "qrcode",
        href: "/qr/list"
      },
      {
        name: "Create QR Code",
        icon: ""
      },
    ],
    title_head: "Create QR",
    need_auth: false,
    mode: Mode.CREATE
  }
};

export const FORM_QR_EDIT: RoutePathModel = {
  path: "/qr/edit/:id",
  url: "/qr/edit/:id",
  name: "qr.edit",
  meta: {
    breadcrumb: [
      {
        name: "QR",
        icon: "qrcode",
        href: "/qr/list"
      },
      {
        name: "Edit QR Code",
        icon: ""
      },
    ],
    title_head: "Edit QR",
    need_auth: false,
    mode: Mode.EDIT
  }
};
