// Interfaces
import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../../interface/common.interface";

export const ACCOUNTRECEIVABLES_ROUTE: RoutePathModel = {
  path: "/account-receivables",
  url: "/account-receivables",
  name: "Account Receivables",
  meta: {
    breadcrumb: "Account Receivables",
    title_head: "lbl_account_receivable",
    need_auth: true,
  },
};
export const REPORTAGING_DETAILS_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/report/aging/details`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/report/aging/details`,
  name: "accountreceivables.invoices.report.aging.details",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "read",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_invoice",
        icon: "file",
      },
      {
        name: "lbl_report",
        icon: "carry-out",
      },
      {
        name: "lbl_report_aging_details",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/report/aging/details`,
      },
    ],
    title_head: "lbl_report_aging_details",
    mode: Mode.VIEW,
    need_auth: true,
  },
};
export const REPORTAGING_SUMMARY_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/report/aging/summary`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/report/aging/summary`,
  name: "accountreceivables.invoices.report.aging.summary",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "read",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_invoice",
        icon: "file",
      },
      {
        name: "lbl_report",
        icon: "carry-out",
      },
      {
        name: "lbl_report_aging_summary",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/report/aging/summary`,
      },
    ],
    title_head: "lbl_report_aging_summary",
    mode: Mode.VIEW,
    need_auth: true,
  },
};
export const REPORT_UNIT_CONTROLLING_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/report/unit-controlling`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/report/unit-controlling`,
  name: "lbl_unit_controlling",
  meta: {
    breadcrumb: [
      {
        name: "Finance Accounting",
        icon: "read",
      },
      {
        name: "Account Receivable",
        icon: "database",
      },
      {
        name: "Invoices",
        icon: "file",
      },
      {
        name: "Report",
        icon: "carry-out",
      },
      {
        name: "lbl_unit_controlling",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/report/unit-controlling`,
      },
    ],
    title_head: "lbl_unit_controlling",
    mode: Mode.VIEW,
  },
};
export const REPORT_TAX_INVOICE_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/report/tax-invoice`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/report/tax-invoice`,
  name: "lbl_tax_invoice",
  meta: {
    breadcrumb: [
      {
        name: "Finance Accounting",
        icon: "read",
      },
      {
        name: "Account Receivable",
        icon: "database",
      },
      {
        name: "Invoices",
        icon: "file",
      },
      {
        name: "Report",
        icon: "carry-out",
      },
      {
        name: "lbl_tax_invoice",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/report/tax-invoice`,
      },
    ],
    title_head: "lbl_tax_invoice",
    mode: Mode.VIEW,
  },
};
export const INVOICESPREPAYMENT_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/invoiceprepayment`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/invoiceprepayment`,
  name: "accountreceivables.invoices.prepayment",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "read",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_invoice",
        icon: "file",
      },
      {
        name: "lbl_invoice_prepayment_credit_memo",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/invoiceprepayment`,
      },
    ],
    title_head: "lbl_invoice_prepayment_credit_memo",
    mode: Mode.VIEW,
    need_auth: true,
  },
};
export const INVOICESPREPAYMENT_CREATE_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/invoiceprepayment/create`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/invoiceprepayment/create`,
  name: "accountreceivables.invoices.prepayment.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "read",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_invoice",
        icon: "file",
      },
      {
        name: "lbl_invoice_prepayment_credit_memo",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/invoiceprepayment`,
      },
    ],
    title_head: "lbl_invoice_prepayment_credit_memo",
    mode: Mode.CREATE,
    need_auth: true,
  },
};
export const INVOICESPREPAYMENT_EDIT_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/invoiceprepayment/edit/:id`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/invoiceprepayment/edit/:id`,
  name: "accountreceivables.invoices.prepayment.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "read",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_invoice",
        icon: "file",
      },
      {
        name: "lbl_invoice_prepayment_credit_memo",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/invoiceprepayment`,
      },
    ],
    title_head: "lbl_invoice_prepayment_credit_memo",
    mode: Mode.EDIT,
    need_auth: true,
  },
};
export const BAP_ROUTE: RoutePathModel = {
  path: "bap",
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/bap`,
  name: "BAP",
  meta: {
    breadcrumb: "BAP",
    title_head: "BAP",
  },
};
export const CLOSEPERIODE_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/closeperiod`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/closeperiod`,
  name: "accountreceivables.invoices.closeperiod",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "read",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_open_close_period",
        href: `${ACCOUNTRECEIVABLES_ROUTE.path}/closeperiod`,
      },
    ],
    title_head: "lbl_account_receivable_close_period",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const AR_RECEIPT_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/ar-receipt`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/ar-receipt`,
  name: "accountreceivables.ar.receipt",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "read",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_account_receivable_receipt",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/ar-receipt`,
      },
    ],
    title_head: "lbl_ar_receipt",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const RECEIPT_AR_CREATE_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/ar-receipt/create`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/ar-receipt/create`,
  name: "accountreceivables.ar.receipt.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "read",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/ar-receipt`,
      },
      {
        name: "lbl_create_account_receivable_receipt",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/ar-receipt/create`,
      },
    ],
    title_head: "lbl_create_account_receivable_receipt",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

export const RECEIPT_AR_VIEW_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/ar-receipt/view/:id`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/ar-receipt/view/:id`,
  name: "accountreceivables.ar.receipt.view",
  meta: {
    breadcrumb: [
      {
        name: "lbl_finance_accounting",
        icon: "read",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/ar-receipt`,
      },
      {
        name: "lbl_account_receivable_receipt",
        href: `${ACCOUNTRECEIVABLES_ROUTE.url}/ar-receipt/view/:id`,
      },
    ],
    title_head: "lbl_account_receivable_receipt",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const REPORTS_ACCOUNT_RECEIVABLES_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/reports`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/reports`,
  name: "Account Receivable Reports",
  meta: {
    breadcrumb: "Reports",
    title_head: "lbl_reports",
  },
};

export const JOIN_INVOICES_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/join-invoices`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/join-invoices`,
  name: "accountreceivable.joininvoice",
  meta: {
    breadcrumb: [
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_join_invoices",
        href: `${ACCOUNTRECEIVABLES_ROUTE.path}/join-invoices`,
      },
    ],
    title_head: "lbl_join_invoices",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const JOIN_INVOICES_CREATE_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/join-invoices/create`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/join-invoices/create`,
  name: "accountreceivable.joininvoice.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_join_invoices",
        href: `${ACCOUNTRECEIVABLES_ROUTE.path}/join-invoices`,
      },
      {
        name: "lbl_create_join_invoices",
        href: `${ACCOUNTRECEIVABLES_ROUTE.path}/join-invoices/create`,
      },
    ],
    title_head: "lbl_create_join_invoices",
    need_auth: true,
    mode: Mode.CREATE,
  },
};

export const JOIN_INVOICES_VIEW_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/join-invoices/view/:id`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/join-invoices/view/:id`,
  name: "accountreceivable.joininvoice.view",
  meta: {
    breadcrumb: [
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_join_invoices",
        href: `${ACCOUNTRECEIVABLES_ROUTE.path}/join-invoices`,
      },
      {
        name: "lbl_view_join_invoices",
        href: `${ACCOUNTRECEIVABLES_ROUTE.path}/join-invoices/view`,
      },
    ],
    title_head: "lbl_view_join_invoices",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const INVOICE_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices`,
  name: "accountreceivables.invoices",
  meta: {
    breadcrumb: [
      {
        name: "lbl_invoice",
        icon: "read"
      }
    ],
    title_head: "lbl_invoice",
    mode: Mode.VIEW,
    need_auth: true,
  },
};

export const INVOICES_DETAIL_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/detail/:id`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/detail/:id`,
  name: "accountreceivables.invoices.detail",
  meta: {
    breadcrumb:[
      {
        name: "lbl_finance_accounting",
        icon: "read",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_invoice",
        href: "/account-receivables/invoices"
      },
      {
        name: "lbl_invoice_detail",
        href: "/account-receivables/invoices/detail"
      },
    ],
    mode: Mode.VIEW,
    title_head: "lbl_invoice_detail",
    need_auth: true,
    type: "invoices"
  }
};
export const INVOICES_CREATE_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/create`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/create`,
  name: "accountreceivables.invoices.create",
  meta: {
    breadcrumb:[
      {
        name: "lbl_finance_accounting",
        icon: "read",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_invoice",
        href: "/account-receivables/invoices"
      },
      {
        name: "lbl_invoice_create",
        href: "/account-receivables/invoices/create"
      },
    ],
    mode: Mode.CREATE,
    title_head: "lbl_invoice_create",
    type: "invoices",
    need_auth: true,
  }
};
export const INVOICES_EDIT_ROUTE: RoutePathModel = {
  path: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/edit/:id`,
  url: `${ACCOUNTRECEIVABLES_ROUTE.url}/invoices/edit/:id`,
  name: "accountreceivables.invoices.edit",
  meta: {
    breadcrumb:[
      {
        name: "lbl_finance_accounting",
        icon: "read",
      },
      {
        name: "lbl_account_receivable",
        icon: "database",
      },
      {
        name: "lbl_invoice",
        href: "/account-receivables/invoices"
      },
      {
        name: "lbl_invoice_edit",
        href: "/account-receivables/invoices/create"
      },
    ],
    mode: Mode.EDIT,
    title_head: "lbl_invoice_edit",
    type: "invoices",
    need_auth: true,
  }
};