import { getWithExpiry, setWithExpiry } from "@/helpers/localstorage";
import { config as RoutePath } from "@/models/constant/routes.constant";
import { Actions } from "@/models/enums/actions.enum";
import { GR_LOCAL_STORAGE } from "@/models/enums/good-receipt.enum";
import { Mutations } from "@/models/enums/mutations.enum";
import { StorageKeys } from "@/models/enums/storages.enum";
import { USER } from "@/models/enums/user.enum";
import { INavMenu } from "@/models/interface-v2/navigation.interface";
import { LoginResponseModel } from "@/models/interface/auth.interface";
import {
  PropsModel,
  StorageStateModel,
} from "@/models/interface/common.interface";
import router from "@/router";
import localStorageService from "@/services/localStorage.service";
import Vue from "vue";
import Vuex from "vuex";
import authStore from "./auth.store";
import batchingStore from "./batching.store";
import contactStore from "./contact.store";
import importCostStore from "./import-cost.store";
import invoiceStore from "./invoice.store";
import jobCostingMeatroomStore from "./job-costing-meatroom.store";
import jobCostingWarehouseStore from "./job-costing-warehouse.store";
import preferenceStore from "./preference.store";
import productStoreV2 from "./product-v2.store";
import productStore from "./product.store";
import quotationStore from "./quotation.store";
import { initListMenu } from "./resource/nav.resource";
import salesOrderStore from "./sales-order.store";
import userStore from "./user.store";

Vue.use(Vuex);

const state: StorageStateModel = {
  sidebarShow: "responsive",
  sidebarMinimize: true,
  access_token: getWithExpiry("access_token"),
  refresh_token: getWithExpiry("refresh_token"),
  emailForgotPassword: null,
  innerWidth: window.innerWidth,
  innerHeight: window.innerHeight,
  loadingComponent: false,
  listMenu: initListMenu(),
};

const mutations = {
  [Mutations.SET_LOADING_COMPONENT]: (
    stateVuex: PropsModel,
    payload: boolean
  ) => {
    stateVuex.loadingComponent = payload;
  },
  [Mutations.SET_TOGGLE_SIDEBAR_DEKSTOP]: (
    stateVuex: PropsModel,
    payload: boolean | string
  ) => {
    stateVuex.sidebarShow = payload;
  },
  [Mutations.SET_TOGGLE_SIDEBAR_MOBILE]: (
    stateVuex: PropsModel,
    payload: boolean | string
  ) => {
    stateVuex.sidebarShow = payload;
  },
  [Mutations.SET_ACCESS_TOKEN]: (
    stateVuex: PropsModel,
    access_token: string
  ) => {
    stateVuex.access_token = access_token;
  },
  [Mutations.SET_REFRESH_TOKEN]: (
    stateVuex: PropsModel,
    refresh_token: string
  ) => {
    stateVuex.refresh_token = refresh_token;
  },
  [Mutations.SET_EMAIL_FORGOT_PASSWORD]: (
    stateVuex: PropsModel,
    email: string
  ) => {
    stateVuex.emailForgotPassword = email;
  },
  [Mutations.SET_INNERWIDTH]: (
    stateVuex: PropsModel,
    width: string | number
  ) => {
    stateVuex.innerWidth = width;
  },
  [Mutations.SET_INNERHEIGHT]: (
    stateVuex: PropsModel,
    height: string | number
  ) => {
    stateVuex.innerHeight = height;
  },
  set(stateVuex: PropsModel, [variable, value]: [string, boolean]) {
    stateVuex[variable] = value;
  },
  ["RESET_LIST_MENU"]: (stateVuex, value: INavMenu[]) => {
    stateVuex.listMenu = value;
  },
};

const actions = {
  [Actions.ACTIONS_POST_LOGIN]: (
    { commit }: PropsModel,
    payload: LoginResponseModel
  ) => {
    setWithExpiry(StorageKeys.ACCESS_TOKEN, payload.access_token, 500);
    setWithExpiry(StorageKeys.REFRESH_TOKEN, payload.refresh_token, 500);
    commit(Mutations.SET_ACCESS_TOKEN, payload.access_token);
    commit(Mutations.SET_REFRESH_TOKEN, payload.refresh_token);
    commit(
      "authStore/SET_USER_PRIVILEGES",
      localStorageService.loadUserPrivilege()
    );
    setTimeout(() => router.push(RoutePath.DASHBOARD_ROUTE.url), 200);
  },
  [Actions.ACTIONS_REFRESH_TOKEN]: (
    { commit }: PropsModel,
    payload: LoginResponseModel
  ) => {
    setWithExpiry(StorageKeys.ACCESS_TOKEN, payload.access_token, 500);
    setWithExpiry(StorageKeys.REFRESH_TOKEN, payload.refresh_token, 500);
    commit(Mutations.SET_ACCESS_TOKEN, payload.access_token);
    commit(Mutations.SET_REFRESH_TOKEN, payload.refresh_token);
  },
  [Actions.ACTIONS_TOGGLE_SIDEBAR_DEKSTOP]: ({ commit }: PropsModel) => {
    const sidebarOpened: boolean = [true, "responsive"].includes(
      state.sidebarShow
    );
    const sidebarShowed: boolean | string = sidebarOpened
      ? false
      : "responsive";
    commit(Mutations.SET_TOGGLE_SIDEBAR_DEKSTOP, sidebarShowed);
  },
  [Actions.ACTIONS_TOGGLE_SIDEBAR_MOBILE]: ({ commit }: PropsModel) => {
    const sidebarClosed: boolean = [false, "responsive"].includes(
      state.sidebarShow
    );
    const sidebarShowed: boolean | string = sidebarClosed ? true : "responsive";
    commit(Mutations.SET_TOGGLE_SIDEBAR_MOBILE, sidebarShowed);
  },
  [Actions.ACTIONS_LOGOUT]: ({ commit }: PropsModel) => {
    localStorage.removeItem(StorageKeys.ACCESS_TOKEN);
    localStorage.removeItem(StorageKeys.REFRESH_TOKEN);
    localStorage.removeItem(USER.USER_ROLE);
    sessionStorage.removeItem(GR_LOCAL_STORAGE.GR_PURCHASE_FORM);

    commit(Mutations.SET_ACCESS_TOKEN, "");
    commit(Mutations.SET_REFRESH_TOKEN, "");
    commit("RESET_LIST_MENU", initListMenu());
    router.replace(RoutePath.SIGNIN_ROUTE.url);
  },
  [Actions.ACTIONS_FORGOT_PASSWORD]: (
    { commit }: PropsModel,
    email: string
  ) => {
    commit(Mutations.SET_EMAIL_FORGOT_PASSWORD, email);
  },
};

export default new Vuex.Store({
  state,
  devtools: process.env.NODE_ENV !== "production",
  mutations,
  actions,
  modules: {
    contactStore,
    productStore,
    authStore,
    productStoreV2,
    batchingStore,
    jobCostingWarehouseStore,
    salesOrderStore,
    quotationStore,
    preferenceStore,
    invoiceStore,
    importCostStore,
    jobCostingMeatroomStore,
    userStore,
  },
});
