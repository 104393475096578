import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { ResponseDeliveryOrder } from "@/models/interface-v2/delivery-order.interface";
import { ApplyLine, InvoiceARLine, InvoiceARReceiptDetail, PostInvoice, PostInvoiceARLines, PrepaymentLine, ResponseDetailApplyCredit, ResponseDetailARInvoiceDeliveryOrder, ResponseDetailInvoiceAR } from "@interface/invoice.interface";
import moment, { Moment } from "moment";
import { InvoiceDetailSource } from "../interface/store-invoice.interface";

export const initFormInvoiceQc = () => ({
  description: "",
  notes: "",
});

export const initReqCreate = (): PostInvoice => ({
  accountingDate: "",
  applyPrepayment: {
    prepaymentLines: [] as ApplyLine[],
    deletedPrepaymentLineIds: [] as string[],
  },
  usageType: "",
  icBillingId: "",
  deletedInvoiceARLineIds: [] as string[],
  branchWarehouseId: "",
  currencyId: "",
  currencyRate: 0,
  customerBillToAddress: "",
  customerId: "",
  customerShipToAddress: "",
  customerTaxType: "",
  deliveryOrderIds: [] as string[],
  description: "",
  invoiceARLines: [] as PostInvoiceARLines[],
  invoiceDate: "",
  invoiceReceivedDate: "",
  receivableAccountId: "",
  taxInvoiceNumber: "",
  salesName: "",
  taxInvoiceDate: "",
  taxIsUploaded: false,
  taxRegistrationName: "",
  taxRegistrationNumber: "",
  taxType: "" as TAX_CALCULATION,
  termOfPayment: null as number | null,
  discountValue: 0,
  percentDiscount: 0,
});

export const initForm = () => ({
  branchId: undefined as string | undefined,
  customerId: undefined as string | undefined,
  billAddress: undefined as string | undefined,
  shipAddress: undefined as string | undefined,
  salesName: undefined as string | undefined,
  taxType: TAX_CALCULATION.EXCLUSIVE as TAX_CALCULATION | string | undefined,
  top: undefined as string | undefined,
  invoiceDate: null as string | null,
  accountingDate: null as string | null,
  currencyId: undefined as string | undefined,
  currencyRate: undefined as number | undefined,
  receivableAccountId: "",
  invoiceDescription: "",
  deliveryOrderIds: [] as string[],
  deliveryOrders: [] as any[],
  total: 0,
  additionalDiscount: {
    percent: 0,
    amount: 0,
  },
  totalFreight: 0,
  totalDiscount: 0,
  totalTax: 0,
  grandTotal: 0,
  deletedInvoiceARLineIds: [] as string[]
});

export const initDetail = (): ResponseDetailInvoiceAR => ({
  returnItem: [],
  createdDate: "",
  modifiedDate: "",
  documentNumber: "",
  invoiceType: "",
  invoiceSource: "",
  icBillingId: "",
  returnAmount: "",
  creditMemoUsed: "",
  salesId: "",
  invoiceJoinNo: "",
  salesName: "",
  deliveryOrders: [] as ResponseDetailARInvoiceDeliveryOrder[],
  deliveryOrderIds: [] as string[],
  branchWarehouseName: "",
  branchWarehouseId: "",
  customerName: "",
  customerId: "",
  customerShipToAddress: "",
  customerBillToAddress: "",
  taxType: "",
  termOfPayment: 0,
  invoiceDate: "",
  accountingDate: "",
  invoiceReceivedDate: "",
  currency: "",
  currencyId: "",
  currencyRate: 0,
  receivableAccountId: "",
  receivableAccount: "",
  operatorName: "",
  description: "",
  status: "",
  customerTaxType: "",
  taxRegistrationNumber: "",
  taxRegistrationName: "",
  taxInvoiceDate: "",
  taxInvoiceNumber: "",
  taxIsUploaded: false,
  invoiceAmount: 0,
  invoiceTaxAmount: 0,
  discountValue: "",
  percentDiscount: 0,
  totalFreight: 0,
  paidAmount: 0,
  journalId: "",
  journalNo: "",
  totalDiscount: "",
  totalTax: "",
  grandTotal: "",
  invoiceARLines: [] as InvoiceARLine[],
  applyPrepayment: {
    prepaymentLines: [] as PrepaymentLine[],
  },
  prepaymentUsed: 0,
  invoiceARReceiptDetails: [] as InvoiceARReceiptDetail[],
  remainingInvoiceAmount: 0,
  id: "",
  usageType: "",
  applyCredit: [] as ResponseDetailApplyCredit[],
  invoiceGrossAmount: 0,
  invoiceIncomeTaxAmount: 0,
  message: "",
  invoiceJoinDate: "",
  invoiceJoinNumber: "",
  invoiceJoinId: "",
  invoiceQc: [],
});

export const initTabDetailsSource = (): InvoiceDetailSource => ({
  no: 0,
  documentReference: "",
  productCode: undefined as string | undefined,
  productName: undefined as string | undefined,
  productId: "",
  brand: "",
  qty: 0,
  uomId: undefined as string | undefined,
  uom: "",
  price: 0,
  revenueAccountId: undefined as string | undefined,
  revenueAccount: "",
  baseAmount: 0,
  taxCode: undefined as string | undefined,
  taxId: undefined as string | undefined,
  taxAmount: 0,
  subTotal: 0,
  description: "",
  alias: "",
  id: "",
  incomeTaxId: undefined as string | undefined,
  incomeTaxRate: 0,
  incomeTaxValue: 0,
  batchNumber: "",
  batchNumberId: "",
  
  // additional
  fromDO: false,
  key: 0,
  incomeAccountTaxId: "",
  percentDiscount: 0,
  discountValue: 0,
  billingPeriodModal: "",
  salesOrderLineId: "",
  deliveryOrderLineId: "",
  taxValue: 0,
  location: "",
  taxRate: 0,
  taxRatePercent: 0,
  taxRateValue: 0,
  baseAmountValue: 0,
  invoiceICBillingLineId: "",
  proRateRatio: 0,
  grossAfterDiscount: 0,
  proRateAdditionalDiscountAmount: 0,
  amountAfterProRateAdditionalDiscount: 0,
  proRateAmountAfterAdditionalDiscountRatio: 0, // pre payment ratio on each line
  proRatePrepaymentRatio: 0,
  proRatePrepaymentAmount: 0,
});

export const initTabTaxDetailSource = () => ({
  taxType: "",
  taxRegistrationNumber: "",
  taxRegistrationName: "",
  taxInvoiceUploaded: false,
  taxInvoiceDate: moment() as Moment | null,
  taxInvoiceNumber: "",
});

export const initTabStatusSource = () => ({
  total: 0,
  prepaymentUsed: {
    data: [],
    total: 0,
  },
  remainingInvoiceAmount: 0,
  return: {
    data: [],
    total: 0,
  },
  accountReceivable: 0,
  accountReceivableId: "",
  invoiceJoinId: "",
  invoiceJoinNumber: "",
  invoiceJoinDate: "",
});

