var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-core" },
    [
      _vm.$store.state.loadingComponent
        ? _c(
            "div",
            { staticClass: "custom-container-lottie" },
            [_c("PageLoader")],
            1
          )
        : _vm._e(),
      _c("router-view"),
      _c("VDrawerError")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }