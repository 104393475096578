import { IImportCostCreate, IImportCostLineCreate } from "@/models/interface-v2/import-cost.interface";

export const initForm = (): IImportCostCreate => ({
  importCostNumber: "",
  supplierName: undefined,
  checklistNumber: null,
  priceCurrency: null,

  supplierId: undefined,
  supplierBankAccount: undefined,
  priceCurrencyId: undefined,
  currencyRate: null,
  deletedImportCostLineIds: [],
  airContributionTotal: 0,
  airContributionValue: 0,
  beaMasuk: 0,
  certificateFee: 0,
  cif: 0,
  cnf: 0,
  document: 0,
  documentFee: 0,
  fobTotal: 0,
  freight: 0,
  grossWeight: 0,
  handlingFeeTotal: 0,
  handlingFeeValue: 0,
  importCostLines: [],
  ins: 0,
  kadeChargeTotal: 0,
  kadeChargeValue: 0,
  labFeeTotal: 0,
  labFeeValue: 0,
  movingCostDocumentFee: 0,
  nilaiPabean: 0,
  otherDocumentFee: 0,
  otherQuarantineFee: 0,
  otherUnloading: 0,
  otherWarehouse: 0,
  pjkp2uTotal: 0,
  pjkp2uValue: 0,
  pph: 0,
  pph23: 0,
  ppjkEdi: 0,
  ppn: 0,
  price: 0,
  product: "",
  receivingItemId: "",
  rushHoldingTotal: 0,
  rushHoldingValue: 0,
  stampDuty: 0,
  stampDutyDocumentFee: 0,
  stampDutyUnloading: 0,
  stampDutyWarehouse: 0,
  storageChargeDay: 0,
  storageChargeTotal: 0,
  storageChargeValue: 0,
  storageChargeValue1: 0,
  supplierBillToAddress: "",
  taxDocumentFeePercent: 0,
  taxDocumentFeeTotal: 0,
  taxDocumentFeeValue: 0,
  taxFee: 0,
  taxUnloadingPercent: 0,
  taxUnloadingTotal: 0,
  taxWarehousePercent: 0,
  taxWarehouseTotal: 0,
  taxWarehouseValue: 0,
  totalAmountPrice: 0,
  totalCarton: 0,
  totalConversion: 0,
  totalDetail: 0,
  totalDocumentFee: 0,
  totalFee: 0,
  totalFeeAfterIDR: 0,
  totalImportDuty: 0,
  totalLocalTransportation: 0,
  totalPayment: 0,
  totalPortion: 0,
  totalPpjk: 0,
  totalPrice: 0,
  totalQtyReceived: 0,
  totalQuarantineFee: 0,
  totalService: 0,
  totalUnloadingCost: 0,
  totalWarehouseRent: 0,
  transportFee: 0,
  travelExpanse: 0,
  weightNett: 0,
});

export const initImportCostLine = (): IImportCostLineCreate => ({
  batchId: "",
  cogs: 0,
  gijConversion: 0,
  importDuty: 0,
  localTransportation: 0,
  portion: 0,
  price: 0,
  productId: "",
  qty: 0,
  receivingItemLineId: "",
  total: 0,
  totalIDR: 0,
  totalPrice: 0,
  transport: 0,
  uomId: "",
  id: null,
});
