import { Mutations } from "@/models/enums/mutations.enum";
import { userServices } from "@/services-v2/user.service";
import store from "@/store";
import { NavigationGuard } from "vue-router";

export const authLoader: NavigationGuard = async (_to, _from, next) => {
  try {
    const user = await userServices.userDetailAuth();
    store.commit("authStore/SET_AUTH", user);
    next();
  } catch (error) {
    store.commit(Mutations.SET_LOADING_COMPONENT, false);
  }
};
