var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        visible: _vm.visible,
        "body-style": { paddingBottom: "80px" },
        placement: "bottom"
      },
      on: { close: _vm.handleClose },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c("a-icon", {
                staticStyle: { color: "#f5222d" },
                attrs: { type: "exclamation-circle", theme: "filled" }
              }),
              _c("a-divider", { attrs: { type: "vertical" } }),
              _c("span", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.title))
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [_c("p", [_vm._v(_vm._s(_vm.message))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }