import { DrawerErrorState } from "./drawer-error-types";

export const mutations = {
  setVisible: (state: DrawerErrorState, payload: boolean): void => {
    state.visible = payload;
  },
  setMessage: (state: DrawerErrorState, payload: string): void => {
    state.message = payload;
  },
  setTitle: (state: DrawerErrorState, payload: string): void => {
    state.title = payload;
  },
  setCode: (state: DrawerErrorState, payload: string | undefined): void => {
    state.code = payload;
  },
};
