import { DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import { Decimal } from "decimal.js-light";
import Vue from "vue";
import numeral from "numeral";

Vue.filter("currency", (value: number) => {
  if (!value) return numeral(0).format("0,0.00");
  const newVal = new Decimal(value)
    .toDecimalPlaces(DECIMAL_PLACES_CURRENCY, Decimal.ROUND_HALF_EVEN)
    .toNumber();
  return numeral(newVal).format("0,0.00");
});
