import { ListAdditionalPrivilegeDto } from "@interface/privilege.interface";
import {
  ListUserRoleDto,
  ResponseDetailUserAuth,
} from "@interface/user.interface";

export const initAuthData = (): ResponseDetailUserAuth => {
  return {
    active: false,
    additionalPrivileges: [] as ListAdditionalPrivilegeDto[],
    companyId: "",
    companyName: "",
    companyTaxRegisNo: "",
    email: "",
    employeeId: "",
    employeeName: "",
    id: "",
    roles: [] as ListUserRoleDto[],
    username: "",
  };
};
