var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    { staticClass: "p-3", attrs: { gutter: [16, 16] } },
    [
      _c(
        "a-col",
        { attrs: { span: 18, xs: 24, sm: 24, md: 24, lg: 18 } },
        [
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.receivablesAccount.label } },
            [
              _c("CSelectAccountingAccount", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.receivablesAccount.decorator,
                    expression: "formRules.receivablesAccount.decorator"
                  }
                ],
                attrs: {
                  "for-list": false,
                  "label-key": ["code", "description"],
                  "prop-account-name": "trigger"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.revenueAccount.label } },
            [
              _c("CSelectAccountingAccount", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.revenueAccount.decorator,
                    expression: "formRules.revenueAccount.decorator"
                  }
                ],
                attrs: {
                  "for-list": false,
                  "label-key": ["code", "description"],
                  "prop-account-name": "trigger"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.payablesAccount.label } },
            [
              _c("CSelectAccountingAccount", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.payablesAccount.decorator,
                    expression: "formRules.payablesAccount.decorator"
                  }
                ],
                attrs: {
                  "for-list": false,
                  "label-key": ["code", "description"],
                  "prop-account-name": "trigger"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.prepaymentAccount.label } },
            [
              _c("CSelectAccountingAccount", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: _vm.formRules.prepaymentAccount.decorator,
                    expression: "formRules.prepaymentAccount.decorator"
                  }
                ],
                attrs: {
                  "for-list": false,
                  "label-key": ["code", "description"],
                  "prop-account-name": "trigger"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.formRules.termOfPayment.label } },
            [
              _c(
                "a-select",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: _vm.formRules.termOfPayment.decorator,
                      expression: "formRules.termOfPayment.decorator"
                    }
                  ],
                  attrs: {
                    name: _vm.formRules.termOfPayment.name,
                    placeholder: _vm.formRules.termOfPayment.placeholder,
                    "filter-option": false,
                    "allow-clear": true
                  }
                },
                _vm._l(_vm.dataTop, function(data) {
                  return _c(
                    "a-select-option",
                    { key: data.value, attrs: { value: data.value } },
                    [
                      _c(
                        "a-tooltip",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.value === "0"
                                    ? _vm.$t(data.key)
                                    : (data.value === "-1"
                                        ? _vm.$t("lbl_cash_in_advance")
                                        : _vm.$t("lbl_top_in_day", {
                                            value: data.value
                                          })) || "-"
                                ) +
                                " "
                            )
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                data.value === "0"
                                  ? _vm.$t(data.key)
                                  : (data.value === "-1"
                                      ? _vm.$t("lbl_cash_in_advance")
                                      : _vm.$t("lbl_top_in_day", {
                                          value: data.value
                                        })) || "-"
                              ) +
                              " "
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }