export enum QUOTATION_STATUS {
  CANCELLED = "Cancelled",
  PROCESSED = "Processed",
  QUOTATION_SUBMITTED = "Quotation Submitted",
  SALES_ORDER_CREATED = "Sales Order Created",
  WAREHOUSE_PICKED = "Warehouse Picked",

  // LOCAL STATUS
  NEW = "New"
}
