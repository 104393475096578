import { RouteConfig } from "vue-router";

// Models
import { config } from "@/models/constant/routes.constant";

import { productDetailLoader } from "@/loader";

const router: RouteConfig = {
  path: config.LOGISTIC_ROUTE.path,
  name: config.LOGISTIC_ROUTE.name,
  meta: config.LOGISTIC_ROUTE.meta,
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: config.REPORT_HISTORY_BATCH.path,
      name: config.REPORT_HISTORY_BATCH.name,
      meta: config.REPORT_HISTORY_BATCH.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/ReportHistoryBatch.vue"),
    },
    {
      path: config.REPORT_STOCK_OPNAME.path,
      name: config.REPORT_STOCK_OPNAME.name,
      meta: config.REPORT_STOCK_OPNAME.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/ReportStockOpname.vue"),
    },
    {
      path: config.REPORT_HPP_AVERAGE.path,
      name: config.REPORT_HPP_AVERAGE.name,
      meta: config.REPORT_HPP_AVERAGE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/ReportHpp.vue"),
    },
    {
      path: config.REPORT_VALUATION_DETAIL.path,
      name: config.REPORT_VALUATION_DETAIL.name,
      meta: config.REPORT_VALUATION_DETAIL.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./reports/ReportValuationDetail.vue"
        ),
    },
    {
      path: config.CLOSEPERIODELOGISTIC_ROUTE.path,
      name: config.CLOSEPERIODELOGISTIC_ROUTE.name,
      meta: config.CLOSEPERIODELOGISTIC_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./ClosePeriode.vue"),
    },
    {
      path: config.GOODRECEIPT_ROUTE.path,
      name: config.GOODRECEIPT_ROUTE.name,
      meta: config.GOODRECEIPT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./good-receipt/GoodReceiptList.vue"
        ),
    },
    {
      path: config.GOODRECEIPT_EDIT_ROUTE.path,
      name: config.GOODRECEIPT_EDIT_ROUTE.name,
      meta: config.GOODRECEIPT_EDIT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./good-receipt/GoodReceiptPurchase.vue"
        ),
    },
    {
      path: config.GOODRECEIPT_VIEW_ROUTE.path,
      name: config.GOODRECEIPT_VIEW_ROUTE.name,
      meta: config.GOODRECEIPT_VIEW_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./good-receipt/GoodReceiptPurchase.vue"
        ),
    },
    {
      path: config.GOODRECEIPT_CHECKLIST_CREATE.path,
      name: config.GOODRECEIPT_CHECKLIST_CREATE.name,
      meta: config.GOODRECEIPT_CHECKLIST_CREATE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./good-receipt/GoodReceiptChecklist.vue"
        ),
    },
    {
      path: config.GOODRECEIPT_CHECKLIST_VIEW.path,
      name: config.GOODRECEIPT_CHECKLIST_VIEW.name,
      meta: config.GOODRECEIPT_CHECKLIST_VIEW.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./good-receipt/GoodReceiptChecklist.vue"
        ),
    },
    {
      path: config.GOODRECEIPT_CHECKLIST_EDIT.path,
      name: config.GOODRECEIPT_CHECKLIST_EDIT.name,
      meta: config.GOODRECEIPT_CHECKLIST_EDIT.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./good-receipt/GoodReceiptChecklist.vue"
        ),
    },
    {
      path: config.GOODRECEIPT_IMPORT_COST.path,
      name: config.GOODRECEIPT_IMPORT_COST.name,
      meta: config.GOODRECEIPT_IMPORT_COST.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./good-receipt/import-cost/GoodReceiptImportCost.vue"
        ),
    },
    {
      path: config.REPORTS_STOCK_CARD_ROUTE.path,
      name: config.REPORTS_STOCK_CARD_ROUTE.name,
      meta: config.REPORTS_STOCK_CARD_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/StockCard.vue"),
    },
    {
      path: config.REPORTSWORKORDER_ROUTE.path,
      name: config.REPORTSWORKORDER_ROUTE.name,
      meta: config.REPORTSWORKORDER_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./reports/WorkOrder.vue"),
    },
    {
      path: config.SETUPPRODUCTSREAD_ROUTE.path,
      name: config.SETUPPRODUCTSREAD_ROUTE.name,
      meta: config.SETUPPRODUCTSREAD_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./product/SetupProduct.vue"),
    },
    {
      path: config.SETUPPRODUCTSCREATE_ROUTE.path,
      name: config.SETUPPRODUCTSCREATE_ROUTE.name,
      meta: config.SETUPPRODUCTSCREATE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./product/CreateProductV2.vue"),
    },
    {
      path: config.SETUPPRODUCTSUPDATE_ROUTE.path,
      name: config.SETUPPRODUCTSUPDATE_ROUTE.name,
      meta: config.SETUPPRODUCTSUPDATE_ROUTE.meta,
      beforeEnter: productDetailLoader,
      props: true,
      component: () =>
        import(/* webpackPrefetch: true */ "./product/CreateProductV2.vue"),
    },
    {
      path: config.READPRODUCTSCATEGORY_ROUTE.path,
      name: config.READPRODUCTSCATEGORY_ROUTE.name,
      meta: config.READPRODUCTSCATEGORY_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./product/ProductCategory.vue"),
    },
    {
      path: config.WAREHOUSE_ROUTE.path,
      name: config.WAREHOUSE_ROUTE.name,
      meta: config.WAREHOUSE_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./Warehouse.vue"),
    },
    {
      path: config.WAREHOUSEBRANCH_ROUTE.path,
      name: config.WAREHOUSEBRANCH_ROUTE.name,
      meta: config.WAREHOUSEBRANCH_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./WarehouseBranch.vue"),
    },
    {
      path: config.WAREHOUSELOCATION_ROUTE.path,
      name: config.WAREHOUSELOCATION_ROUTE.name,
      meta: config.WAREHOUSELOCATION_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./WarehouseLocation.vue"),
    },
    {
      path: config.WAREHOUSETRANSFER_ROUTE.path,
      name: config.WAREHOUSETRANSFER_ROUTE.name,
      meta: config.WAREHOUSETRANSFER_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./warehouse-transfer/WarehouseTransfer.vue"
        ),
    },
    {
      path: config.WAREHOUSETRANSFERLIST_ROUTE.path,
      name: config.WAREHOUSETRANSFERLIST_ROUTE.name,
      meta: config.WAREHOUSETRANSFERLIST_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./warehouse-transfer/WarehouseTransferList.vue"
        ),
    },
    {
      path: config.READSTOCKADJUSMENT_ROUTE.path,
      name: config.READSTOCKADJUSMENT_ROUTE.name,
      meta: config.READSTOCKADJUSMENT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./ListStockAdjustment.vue"),
    },
    {
      path: config.VIEWSTOCKADJUSMENT_ROUTE.path,
      name: config.VIEWSTOCKADJUSMENT_ROUTE.name,
      meta: config.VIEWSTOCKADJUSMENT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./FormStockAdjustment.vue"),
    },
    {
      path: config.EDITSTOCKADJUSMENT_ROUTE.path,
      name: config.EDITSTOCKADJUSMENT_ROUTE.name,
      meta: config.EDITSTOCKADJUSMENT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./FormStockAdjustment.vue"),
    },
    {
      path: config.STOCKADJUSMENT_ROUTE.path,
      name: config.STOCKADJUSMENT_ROUTE.name,
      meta: config.STOCKADJUSMENT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./FormStockAdjustment.vue"),
    },
    {
      path: config.WORKORDERCREATE_ROUTE.path,
      name: config.WORKORDERCREATE_ROUTE.name,
      meta: config.WORKORDERCREATE_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./work-order/WorkOrder.vue"),
    },
    {
      path: config.WORKORDEREDIT_ROUTE.path,
      name: config.WORKORDEREDIT_ROUTE.name,
      meta: config.WORKORDEREDIT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./work-order/WorkOrder.vue"),
    },
    {
      path: config.WORKORDERLIST_ROUTE.path,
      name: config.WORKORDERLIST_ROUTE.name,
      meta: config.WORKORDERLIST_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./work-order/ListWorkOrder.vue"),
    },
    {
      path: config.RESULTWORKORDER_ROUTE.path,
      name: config.RESULTWORKORDER_ROUTE.name,
      meta: config.RESULTWORKORDER_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./work-order/ResultWorkOrder.vue"),
    },
    {
      path: config.CREATERESULTWORKORDER_ROUTE.path,
      name: config.CREATERESULTWORKORDER_ROUTE.name,
      meta: config.CREATERESULTWORKORDER_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./work-order/CreateResultWO.vue"),
    },
    {
      path: config.VIEWRESULTWORKORDER_ROUTE.path,
      name: config.VIEWRESULTWORKORDER_ROUTE.name,
      meta: config.VIEWRESULTWORKORDER_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./work-order/CreateResultWO.vue"),
    },
    {
      path: config.INVENTORY_ROUTE.path,
      name: config.INVENTORY_ROUTE.name,
      meta: config.INVENTORY_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./Inventory.vue"),
    },
    {
      path: config.LISTOFPRODUCT_ROUTE.path,
      name: config.LISTOFPRODUCT_ROUTE.name,
      meta: config.LISTOFPRODUCT_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./ListOfProduct.vue"),
    },
    {
      path: config.PRODUCT_REPORT_BOTTOM_PRICE.path,
      name: config.PRODUCT_REPORT_BOTTOM_PRICE.name,
      meta: config.PRODUCT_REPORT_BOTTOM_PRICE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./product/bottom-price/BottomPrice.vue"
        ),
    },
  ],
};

export default router;
