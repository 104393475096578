import { useTable } from "@/hooks";
import {
  initAdditionalPrivilegeRow,
  initForm,
} from "@/store/resource/user.resource";
import { ITableRow } from "@interface/common.interface";
import {
  CreateAdditionalPrivilegeDto,
  CreateUserDto,
} from "@interface/user.interface";

export type AdditionalPrivilegeRow = ITableRow<string> &
  CreateAdditionalPrivilegeDto & {
    menuName: string;
    menuDesc: string;
    isRead: boolean;
    isCreate: boolean;
    isUpdate: boolean;
    isDelete: boolean;
  };
type OmitCreateDto = Omit<CreateUserDto, "additionalPrivilege">;
export type FormValue = OmitCreateDto & {
  employeeName: string;
  confirmPassword: string;
  roleNames: { key: string; label }[];
  additionalPrivilege: Array<AdditionalPrivilegeRow>;
  deleteAddPrivilegeIds: string[];
};
export type PatchFormField = Partial<FormValue>;
export interface State {
  form: FormValue;
}

const state: State = {
  form: initForm(),
};

const mutations = {
  setForm: (st: State, payload: Partial<FormValue>): void => {
    const copy = {
      ...st.form,
    };
    st.form = {
      ...copy,
      ...payload,
    };
  },
};

const getters = {};

const actions = {
  resetStore: ({ commit }): void => {
    const form: FormValue = initForm();
    commit("setForm", form);
  },
  addAdditionalPrivilege: ({ state }): void => {
    const { form }: State = state;
    const row = initAdditionalPrivilegeRow();
    form.additionalPrivilege.push(row);
  },
  deleteAdditionalPrivileges: ({ state, commit }, keys: string[]): void => {
    const { form }: State = state;
    const { deleteRows } = useTable();
    const { newSource, deletedRows } = deleteRows(
      form.additionalPrivilege,
      keys
    );
    const field: PatchFormField = {
      additionalPrivilege: newSource,
      deleteAddPrivilegeIds: deletedRows
        .filter((item) => item.id)
        .map((item) => item.id),
    };
    commit("setForm", field);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
