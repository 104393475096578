export interface IResidueForm {
    productCode: string,
    productName: string,
    productId: string,
    qty: number,
    uomId: string,
    uom: string,
    batchConsume: string
}

export const initFormResidue = (): IResidueForm => ({
    productCode: "",
    productName: "",
    productId: "",
    qty: 0,
    uomId: "",
    uom: "",
    batchConsume: "",
});