import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../../interface/common.interface";

export const SALES_ROUTE: RoutePathModel = {
  path: "/sales",
  url: "/sales",
  name: "sales",
  meta: {
    title_head: "lbl_sales",
    breadcrumb: [
      {
        name: "lbl_sales",
        icon: "",
      },
    ],
    need_auth: true,
  },
};

export const QUOTATION_SALES_LIST_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.path}/transaction-sales/quotation`,
  url: `${SALES_ROUTE.url}/transaction-sales/quotation`,
  name: "sales.transactionsales.quotation",
  meta: {
    title_head: "lbl_quotation",
    breadcrumb: [
      {
        name: "lbl_sales",
        icon: "",
      },
      {
        name: "lbl_transaction_sales",
        icon: "",
      },
      {
        name: "lbl_list_quotation",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const QUOTATION_SALES_CREATE_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.path}/transaction-sales/quotation/create`,
  url: `${SALES_ROUTE.url}/transaction-sales/quotation/create`,
  name: "sales.transactionsales.quotation.create",
  meta: {
    title_head: "lbl_quotation",
    breadcrumb: [
      {
        name: "lbl_sales",
        icon: "",
      },
      {
        name: "lbl_transaction_sales",
        icon: "",
      },
      {
        name: "lbl_quotation",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.CREATE,
  },
};

export const QUOTATION_SALES_EDIT_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.path}/transaction-sales/quotation/edit/:id`,
  url: `${SALES_ROUTE.url}/transaction-sales/quotation/edit/:id`,
  name: "sales.transactionsales.quotation.edit",
  meta: {
    title_head: "lbl_quotation",
    breadcrumb: [
      {
        name: "lbl_sales",
        icon: "",
      },
      {
        name: "lbl_transaction_sales",
        icon: "",
      },
      {
        name: "lbl_quotation",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.EDIT,
  },
};

export const SALES_ORDER_LIST: RoutePathModel = {
  path: `${SALES_ROUTE.path}/transaction-sales/sales-order`,
  url: `${SALES_ROUTE.url}/transaction-sales/sales-order`,
  name: "sales.transactionsales.salesorder",
  meta: {
    title_head: "lbl_sales_order",
    breadcrumb: [
      {
        name: "lbl_sales",
        icon: "",
      },
      {
        name: "lbl_sales_order",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const SALES_ORDER_FORM_NEW: RoutePathModel = {
  path: `${SALES_ROUTE.path}/transaction-sales/sales-order/new`,
  url: `${SALES_ROUTE.url}/transaction-sales/sales-order/new`,
  name: "sales.transactionsales.salesorder.new",
  meta: {
    title_head: "lbl_new_sales_order",
    breadcrumb: [
      {
        name: "lbl_sales",
        icon: "",
      },
      {
        name: "lbl_sales_order",
        icon: "",
        href: "/sales/transaction-sales/sales-order",
      },
      {
        name: "lbl_new_sales_order",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.CREATE,
  },
};

export const SALES_ORDER_FORM_EDIT: RoutePathModel = {
  path: `${SALES_ROUTE.path}/transaction-sales/sales-order/edit/:id`,
  url: `${SALES_ROUTE.url}/transaction-sales/sales-order/edit/:id`,
  name: "sales.transactionsales.salesorder.edit",
  meta: {
    title_head: "lbl_edit_sales_order",
    breadcrumb: [
      {
        name: "lbl_sales",
        icon: "",
      },
      {
        name: "lbl_sales_order",
        icon: "",
        href: "/sales/transaction-sales/sales-order",
      },
      {
        name: "lbl_edit_sales_order",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.EDIT,
  },
};

export const SALES_ORDER_FORM_DETAIL: RoutePathModel = {
  path: `${SALES_ROUTE.path}/transaction-sales/sales-order/detail/:id`,
  url: `${SALES_ROUTE.url}/transaction-sales/sales-order/detail/:id`,
  name: "sales.transactionsales.salesorder.detail",
  meta: {
    title_head: "lbl_sales_order_detail",
    breadcrumb: [
      {
        name: "lbl_sales",
        icon: "",
      },
      {
        name: "lbl_sales_order",
        icon: "",
        href: "/sales/transaction-sales/sales-order",
      },
      {
        name: "lbl_sales_order_detail",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.DETAIL,
  },
};

export const DELIVERY_ORDER_LIST: RoutePathModel = {
  path: `${SALES_ROUTE.path}/transaction-sales/delivery-order`,
  url: `${SALES_ROUTE.url}/transaction-sales/delivery-order`,
  name: "sales.transactionsales.deliveryorder",
  meta: {
    title_head: "lbl_delivery_order",
    breadcrumb: [
      {
        name: "lbl_sales",
        icon: "",
      },
      {
        name: "lbl_transaction_sales",
        icon: "",
      },
      {
        name: "lbl_delivery_order",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const DELIVERY_ORDER_CREATE: RoutePathModel = {
  path: `${SALES_ROUTE.path}/transaction-sales/delivery-order/create`,
  url: `${SALES_ROUTE.url}/transaction-sales/delivery-order/create`,
  name: "sales.transactionsales.deliveryorder.create",
  meta: {
    title_head: "lbl_delivery_order",
    breadcrumb: [
      {
        name: "lbl_sales",
        icon: "",
      },
      {
        name: "lbl_transaction_sales",
        icon: "",
      },
      {
        name: "lbl_delivery_order",
        icon: "",
        href: "/sales/transaction-sales/delivery-order",
      },
      {
        name: "lbl_create_delivery_order",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.CREATE,
  },
};

export const DELIVERY_ORDER_EDIT: RoutePathModel = {
  path: `${SALES_ROUTE.path}/transaction-sales/delivery-order/edit/:id`,
  url: `${SALES_ROUTE.url}/transaction-sales/delivery-order/edit/:id`,
  name: "sales.transactionsales.deliveryorder.edit",
  meta: {
    title_head: "lbl_delivery_order",
    breadcrumb: [
      {
        name: "lbl_sales",
        icon: "",
      },
      {
        name: "lbl_transaction_sales",
        icon: "",
      },
      {
        name: "lbl_delivery_order",
        icon: "",
        href: "/sales/transaction-sales/delivery-order",
      },
      {
        name: "lbl_edit_delivery_order",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.EDIT,
  },
};

export const DELIVERY_ORDER_DETAIL: RoutePathModel = {
  path: `${SALES_ROUTE.path}/transaction-sales/delivery-order/detail/:id`,
  url: `${SALES_ROUTE.url}/transaction-sales/delivery-order/detail`,
  name: "sales.transactionsales.deliveryorder.detail",
  meta: {
    title_head: "lbl_delivery_order",
    breadcrumb: [
      {
        name: "lbl_sales",
        icon: "",
      },
      {
        name: "lbl_transaction_sales",
        icon: "",
      },
      {
        name: "lbl_delivery_order",
        icon: "",
        href: "/sales/transaction-sales/delivery-order",
      },
      {
        name: "lbl_detail_delivery_order",
        icon: "",
      },
    ],
    need_auth: true,
    mode: Mode.DETAIL,
  },
};

export const CUSTOMER_RETURN_LIST_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/transaction-sales/customer-return`,
  url: `${SALES_ROUTE.url}/transaction-sales/customer-return`,
  name: "sales.transactionsales.customerreturn",
  meta: {
    breadcrumb: [
      {
        name: "Sales",
        icon: "",
      },
      {
        name: "Transaction Sales",
        icon: "",
      },
      {
        name: "Customer Return",
        icon: "",
      },
    ],
    title_head: "Customer Return",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const CUSTOMER_RETURN_CREATE_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/transaction-sales/customer-return/create`,
  url: `${SALES_ROUTE.url}/transaction-sales/customer-return/create`,
  name: "sales.transactionsales.customerreturn.create",
  meta: {
    breadcrumb: [
      {
        name: "Sales",
        icon: "",
      },
      {
        name: "Transaction Sales",
        icon: "",
      },
      {
        name: "Customer Return",
        icon: "",
        href: "/sales/transaction-sales/customer-return",
      },
      {
        name: "Create",
        icon: "",
      },
    ],
    title_head: "Create Customer Return",
    need_auth: true,
    mode: Mode.CREATE,
  },
};

export const CUSTOMER_RETURN_EDIT_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/transaction-sales/customer-return/edit/:id`,
  url: `${SALES_ROUTE.url}/transaction-sales/customer-return/edit/:id`,
  name: "sales.transactionsales.customerreturn.edit",
  meta: {
    breadcrumb: [
      {
        name: "Sales",
        icon: "",
      },
      {
        name: "Transaction Sales",
        icon: "",
      },
      {
        name: "Customer Return",
        icon: "",
        href: "/sales/transaction-sales/customer-return",
      },
      {
        name: "Edit",
        icon: "",
      },
    ],
    title_head: "Edit Customer Return",
    need_auth: true,
    mode: Mode.EDIT,
  },
};

export const CUSTOMER_RETURN_DETAIL_ROUTE: RoutePathModel = {
  path: `${SALES_ROUTE.url}/transaction-sales/customer-return/detail/:id`,
  url: `${SALES_ROUTE.url}/transaction-sales/customer-return/detail/:id`,
  name: "sales.transactionsales.customerreturn.detail",
  meta: {
    breadcrumb: [
      {
        name: "Sales",
        icon: "",
      },
      {
        name: "Transaction Sales",
        icon: "",
      },
      {
        name: "Customer Return",
        icon: "",
        href: "/sales/transaction-sales/customer-return",
      },
      {
        name: "Detail",
        icon: "",
      },
    ],
    title_head: "Customer Return Detail",
    need_auth: true,
    mode: Mode.DETAIL,
  },
};

export const REPORT_SALES_DETAIL: RoutePathModel = {
  path: `${SALES_ROUTE.url}/reports/sales-detail`,
  url: `${SALES_ROUTE.url}/reports/sales-detail`,
  name: "sales.reports.salesdetail",
  meta: {
    breadcrumb: [
      {
        name: "lbl_sales",
        icon: "",
      },
      {
        name: "lbl_reports",
        icon: "",
      },
      {
        name: "lbl_sales_report_detail",
        icon: "",
        href: "",
      },
    ],
    title_head: "lbl_sales_report_detail",
    need_auth: true,
    mode: Mode.VIEW,
  },
};
