import { DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import { ResponsePreference } from "@/models/interface-v2/preferences.interface";
import { initPreference } from "./resource/preference.resource";

interface State {
  appPreference: ResponsePreference[],
  decimalPlaces: number;
}

const state: State = {
  appPreference: initPreference(),
  decimalPlaces: DECIMAL_PLACES_CURRENCY,
};

const mutations = {
  ["SET_APP_PREFERENCE"]: (st: State, payload: ResponsePreference[]): void => {
    st.appPreference = payload;
  },
  ["SET_DECIMAL_PLACES"]: (st: State, payload: number): void => {
    st.decimalPlaces = payload;
  },
};

const getters = {
  ["GET_PREFERENCE_VALUE"]: (st: State) => (key: string): ResponsePreference | undefined => {
    return st.appPreference.find(x => x.key === key);
  },
  ["GET_DECIMAL_PLACES"]: (st: State): number => {
    return st.decimalPlaces;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
