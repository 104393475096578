import { AdditionalPrivilegeRow, FormValue } from "@/store/user.store";
import { v4 as uuidv4 } from "uuid";

export const initForm = (): FormValue => ({
  active: true,
  additionalPrivilege: [],
  email: "",
  employeeId: "",
  employeeName: "",
  password: "",
  roleIds: [],
  roleNames: [],
  username: "",
  confirmPassword: "",
  deleteAddPrivilegeIds: [],
});

export const initAdditionalPrivilegeRow = (): AdditionalPrivilegeRow => ({
  create: "",
  delete: "",
  id: "",
  idMenu: "",
  key: uuidv4(),
  read: "",
  update: "",
  menuDesc: "",
  menuName: "",
  isCreate: false,
  isDelete: false,
  isRead: false,
  isUpdate: false,
});
