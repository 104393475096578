export enum TAX_TYPE {
  INCOME_TAX = "Income Tax",
  VAT_IN = "VAT In",
  VAT_OUT = "VAT Out"
}

export enum TAX_CALCULATION {
  EXCLUSIVE = "Exclusive",
  INCLUSIVE = "Inclusive",
  NONE = "None",
}
