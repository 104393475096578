import { RouteConfig } from "vue-router";

// Models
import { config } from "@/models/constant/routes.constant";

const router: RouteConfig = {
    path: config.ACCOUNTRECEIVABLES_ROUTE.path,
    name: config.ACCOUNTRECEIVABLES_ROUTE.name,
    meta: config.ACCOUNTRECEIVABLES_ROUTE.meta,
    component: {
        render(c) { return c("router-view"); }
    },
    children: [
        {
            path: config.CLOSEPERIODE_ROUTE.path,
            name: config.CLOSEPERIODE_ROUTE.name,
            meta: config.CLOSEPERIODE_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./ClosePeriode.vue")
        },
        {
            path: config.REPORTAGING_DETAILS_ROUTE.path,
            name: config.REPORTAGING_DETAILS_ROUTE.name,
            meta: config.REPORTAGING_DETAILS_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./reports/ReportAgingDetails.vue")
        },
        {
            path: config.REPORTAGING_SUMMARY_ROUTE.path,
            name: config.REPORTAGING_SUMMARY_ROUTE.name,
            meta: config.REPORTAGING_SUMMARY_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./reports/ReportAgingSummary.vue")
        },
        {
            path: config.REPORT_TAX_INVOICE_ROUTE.path,
            name: config.REPORT_TAX_INVOICE_ROUTE.name,
            meta: config.REPORT_TAX_INVOICE_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./reports/ReportTaxInvoice.vue")
        },
        {
            path: config.INVOICE_ROUTE.path,
            name: config.INVOICE_ROUTE.name,
            meta: config.INVOICE_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./Invoices.vue")
        },
        {
            path: config.INVOICES_EDIT_ROUTE.path,
            name: config.INVOICES_EDIT_ROUTE.name,
            meta: config.INVOICES_EDIT_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./invoice-detail/InvoiceIndex.vue")
        },
        {
            path: config.INVOICES_CREATE_ROUTE.path,
            name: config.INVOICES_CREATE_ROUTE.name,
            meta: config.INVOICES_CREATE_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./invoice-detail/InvoiceIndex.vue")
        },
        {
            path: config.AR_RECEIPT_ROUTE.path,
            name: config.AR_RECEIPT_ROUTE.name,
            meta: config.AR_RECEIPT_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./ar-receipt/ReceiptAr.vue")
        },
        {
            path: config.REPORTS_ACCOUNT_RECEIVABLES_ROUTE.path,
            name: config.REPORTS_ACCOUNT_RECEIVABLES_ROUTE.name,
            meta: config.REPORTS_ACCOUNT_RECEIVABLES_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./ar-receipt/ReceiptArForm.vue")
        },
        {
            path: config.RECEIPT_AR_CREATE_ROUTE.path,
            name: config.RECEIPT_AR_CREATE_ROUTE.name,
            meta: config.RECEIPT_AR_CREATE_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./ar-receipt/ReceiptArForm.vue")
        },
        {
            path: config.RECEIPT_AR_VIEW_ROUTE.path,
            name: config.RECEIPT_AR_VIEW_ROUTE.name,
            meta: config.RECEIPT_AR_VIEW_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./ar-receipt/ReceiptArForm.vue")
        },
        {
            path: config.INVOICESPREPAYMENT_ROUTE.path,
            name: config.INVOICESPREPAYMENT_ROUTE.name,
            meta: config.INVOICESPREPAYMENT_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./invoice-prepayment/ListInvoicePrepayment.vue")
        },
        {
            path: config.INVOICESPREPAYMENT_CREATE_ROUTE.path,
            name: config.INVOICESPREPAYMENT_CREATE_ROUTE.name,
            meta: config.INVOICESPREPAYMENT_CREATE_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./invoice-prepayment/CreateInvoicePrepayment.vue")
        },
        {
            path: config.INVOICESPREPAYMENT_EDIT_ROUTE.path,
            name: config.INVOICESPREPAYMENT_EDIT_ROUTE.name,
            meta: config.INVOICESPREPAYMENT_EDIT_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./invoice-prepayment/CreateInvoicePrepayment.vue")
        },
        {
            path: config.JOIN_INVOICES_ROUTE.path,
            name: config.JOIN_INVOICES_ROUTE.name,
            meta: config.JOIN_INVOICES_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./join-invoices/JoinInvoiceList.vue")
        },
        {
            path: config.JOIN_INVOICES_CREATE_ROUTE.path,
            name: config.JOIN_INVOICES_CREATE_ROUTE.name,
            meta: config.JOIN_INVOICES_CREATE_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./join-invoices/JoinInvoicesForm.vue")
        },
        {
            path: config.JOIN_INVOICES_VIEW_ROUTE.path,
            name: config.JOIN_INVOICES_VIEW_ROUTE.name,
            meta: config.JOIN_INVOICES_VIEW_ROUTE.meta,
            component: () => import(/* webpackPrefetch: true */"./join-invoices/JoinInvoicesForm.vue")
        },
    ]
};

export default router;
