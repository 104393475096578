export enum Mutations {
  SET_CATEGORY                  = "SET_CATEGORY",
  SET_COUNTRY                   = "SET_COUNTRY",
  SET_TOGGLE_SIDEBAR_DEKSTOP = 'SET_TOGGLE_SIDEBAR_DEKSTOP',
  SET_TOGGLE_SIDEBAR_MOBILE = 'SET_TOGGLE_SIDEBAR_MOBILE',
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN',
  SET_EMAIL_FORGOT_PASSWORD = 'SET_EMAIL_FORGOT_PASSWORD',
  SET_INNERWIDTH = 'SET_INNERWIDTH',
  SET_INNERHEIGHT = 'SET_INNERHEIGHT',
  SET_FORM_GENERAL_DETAILS = 'SET_FORM_GENERAL_DETAILS',
  SET_FORM_ACCOUNT_DETAILS = 'SET_FORM_ACCOUNT_DETAILS',
  SET_FORM_CUSTOMER_DETAILS = 'SET_FORM_CUSTOMER_DETAILS',
  SET_FORM_SUPPLIER_DETAILS = 'SET_FORM_SUPPLIER_DETAILS',
  SET_FORM_EMPLOYEE_DETAILS = 'SET_FORM_EMPLOYEE_DETAILS',
  SET_ADDRESS_DATA_LIST = 'SET_ADDRESS_DATA_LIST',
  SET_BANK_DATA_LIST = 'SET_BANK_DATA_LIST',
  SET_LIST_BILL_TO = 'SET_LIST_BILL_TO',
  SET_LIST_SHIP_TO = 'SET_LIST_SHIP_TO',
  SET_DATA_EMPLOYEE = 'SET_DATA_EMPLOYEE',
  SET_VALUE_TYPE = 'SET_VALUE_TYPE',
  SET_LOADING_COMPONENT = 'SET_LOADING_COMPONENT',
  SET_PRODUCT = "SET_PRODUCT",
  SET_MULTIPRICE = "SET_MULTIPRICE",
  SET_SUPPLIER = "SET_SUPPLIER",
  SET_UOM = "SET_UOM",
  SET_AUTH = "SET_AUTH"
}