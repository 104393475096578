import { INavMenu } from "@/models/interface-v2/navigation.interface";
import { APP_ROLES } from "@/models/constant/role.constant";
import { MENU_STRING } from "@/models/enums/menu.enum";

export const initListMenu = (): INavMenu[] => {
  return [
    {
      key: "1",
      title: "lbl_dashboard",
      menus: [],
      icon: "dashboard",
      route: "/dashboard",
      show: true,
      isHeader: false,
      privileges: [],
      children: [],
      roles: [],
    },
    {
      key: "2",
      title: "lbl_user_management",
      menus: [MENU_STRING.ROLE_MANAGEMENT, MENU_STRING.USER_MANAGEMENT],
      icon: "team",
      route: "",
      show: false,
      isHeader: true,
      privileges: ["user", "role", "privilege", "menu"],
      children: [
        {
          key: "2-1",
          title: "lbl_privilege",
          menus: [],
          icon: "line",
          route: "/privilege/read",
          show: false,
          isHeader: false,
          privileges: ["privilege"],
          children: [],
          roles: [APP_ROLES.SUPER_ADMINISTRATOR],
        },
        {
          key: "2-2",
          title: "lbl_menu",
          menus: [],
          icon: "line",
          route: "/menu/read",
          show: false,
          isHeader: false,
          privileges: ["menu"],
          children: [],
          roles: [APP_ROLES.SUPER_ADMINISTRATOR],
        },
        {
          key: "2-3",
          title: "lbl_role",
          menus: [MENU_STRING.ROLE_MANAGEMENT],
          icon: "line",
          route: "/role/read",
          show: false,
          isHeader: false,
          privileges: ["role"],
          children: [],
          roles: [APP_ROLES.ADMINISTRATOR, APP_ROLES.SUPER_ADMINISTRATOR],
        },
        {
          key: "2-4",
          title: "lbl_user",
          menus: [MENU_STRING.USER_MANAGEMENT],
          icon: "line",
          route: "/user-management/users",
          show: false,
          isHeader: false,
          privileges: ["user"],
          children: [],
          roles: [APP_ROLES.ADMINISTRATOR, APP_ROLES.SUPER_ADMINISTRATOR],
        },
      ],
      roles: [APP_ROLES.ADMINISTRATOR, APP_ROLES.SUPER_ADMINISTRATOR],
    },
    {
      key: "3",
      title: "lbl_settings",
      menus: [
        MENU_STRING.BANK_MANAGEMENT,
        MENU_STRING.ACCOUNTING_ACCOUNT_MANAGEMENT,
        MENU_STRING.CALENDAR_MANAGEMENT,
        MENU_STRING.ACCOUNTING_CURRENCY_MANAGEMENT,
        MENU_STRING.COMPANY_MANAGEMENT,
        MENU_STRING.ACCOUNTING_TAX_MANAGEMENT,
        MENU_STRING.PRODUCT_UOM_MANAGEMENT,
        MENU_STRING.TAX_INVOICE_SERIAL_NUMBER_MANAGEMENT,
        MENU_STRING.TAX_INVOICE_CODE_MANAGEMENT,
        MENU_STRING.PREFERENCES_MANAGEMENT,
        MENU_STRING.CONTACT_DATA_MANAGEMENT,
        MENU_STRING.CODE_TRANSLATOR,
      ],
      icon: "setting",
      route: "",
      show: false,
      isHeader: true,
      privileges: [
        "bank",
        "accounting-account",
        "calendar",
        "accounting-currency",
        "company",
        "product-uom",
        "tax-invoice-sn",
        "tax-invoice-code",
        "preferences",
        "contact-data",
        "accounting-tax",
        "code-translator",
      ],
      roles: [
        APP_ROLES.ADMINISTRATOR,
        APP_ROLES.SUPER_ADMINISTRATOR,
        APP_ROLES.ACCOUNTING,
        APP_ROLES.FINANCE,
        APP_ROLES.SALES,
        APP_ROLES.ACCOUNTING_PIC,
        APP_ROLES.FINANCE_PIC,
        APP_ROLES.SALES_PIC,
        APP_ROLES.MARKETING,
        APP_ROLES.MARKETING_PIC,
        APP_ROLES.DELIVERY_PIC,
        APP_ROLES.DELIVERY,
        APP_ROLES.PURCHASING_PIC,
        APP_ROLES.PURCHASING,
      ],
      children: [
        {
          key: "3-1",
          title: "lbl_bank",
          menus: [MENU_STRING.BANK_MANAGEMENT],
          icon: "line",
          route: "/settings/bank",
          show: false,
          isHeader: false,
          privileges: ["bank"],
          children: [],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.FINANCE,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.FINANCE_PIC,
          ],
        },
        {
          key: "3-2",
          title: "lbl_chart_of_account",
          menus: [],
          icon: "line",
          route: "/settings/coa",
          show: false,
          isHeader: false,
          privileges: ["accounting-account"],
          children: [],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.FINANCE,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.FINANCE_PIC,
          ],
        },
        {
          key: "3-3",
          title: "lbl_calendar",
          menus: [MENU_STRING.CALENDAR_MANAGEMENT],
          icon: "line",
          route: "/settings/calendar",
          show: false,
          isHeader: false,
          privileges: ["calendar"],
          children: [],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.FINANCE,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.FINANCE_PIC,
          ],
        },
        {
          key: "3-4",
          title: "lbl_currency",
          menus: [MENU_STRING.ACCOUNTING_CURRENCY_MANAGEMENT],
          icon: "line",
          route: "/settings/currency",
          show: false,
          isHeader: false,
          privileges: ["accounting-currency"],
          children: [],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.FINANCE,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.FINANCE_PIC,
          ],
        },
        {
          key: "3-5",
          title: "lbl_company",
          menus: [MENU_STRING.COMPANY_MANAGEMENT],
          icon: "line",
          route: "/settings/company",
          show: false,
          isHeader: false,
          privileges: ["company"],
          children: [],
          roles: [APP_ROLES.ADMINISTRATOR, APP_ROLES.SUPER_ADMINISTRATOR],
        },
        {
          key: "3-6",
          title: "lbl_sales",
          menus: [],
          icon: "line",
          route: "/settings/sales",
          show: false,
          isHeader: false,
          privileges: [],
          children: [],
          roles: [],
        },
        {
          key: "3-7",
          title: "lbl_tax",
          menus: [MENU_STRING.ACCOUNTING_TAX_MANAGEMENT],
          icon: "line",
          route: "/settings/tax",
          show: false,
          isHeader: false,
          privileges: ["accounting-tax"],
          children: [],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.FINANCE,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.FINANCE_PIC,
          ],
        },
        {
          key: "3-8",
          title: "lbl_uom",
          menus: [MENU_STRING.PRODUCT_UOM_MANAGEMENT],
          icon: "line",
          route: "/settings/uom",
          show: false,
          isHeader: false,
          privileges: ["product-uom"],
          children: [],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.FINANCE_PIC,
            APP_ROLES.FINANCE,
          ],
        },
        {
          key: "3-9",
          title: "lbl_tax_invoice_sn",
          menus: [MENU_STRING.TAX_INVOICE_SERIAL_NUMBER_MANAGEMENT],
          icon: "line",
          route: "/settings/taxinvoiceserialnumber",
          show: false,
          isHeader: false,
          privileges: ["tax-invoice-sn"],
          children: [],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.FINANCE,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.FINANCE_PIC,
          ],
        },
        {
          key: "3-10",
          title: "lbl_tax_invoice_code",
          menus: [MENU_STRING.TAX_INVOICE_CODE_MANAGEMENT],
          icon: "line",
          route: "/settings/taxinvoicecode",
          show: false,
          isHeader: false,
          privileges: ["tax-invoice-code"],
          children: [],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.FINANCE,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.FINANCE_PIC,
          ],
        },
        {
          key: "3-11",
          title: "lbl_preferences",
          menus: [MENU_STRING.PREFERENCES_MANAGEMENT],
          icon: "line",
          route: "/settings/preferences",
          show: false,
          isHeader: false,
          privileges: ["preferences"],
          children: [],
          roles: [APP_ROLES.ADMINISTRATOR, APP_ROLES.SUPER_ADMINISTRATOR],
        },
        {
          key: "3-12",
          title: "lbl_contact",
          menus: [MENU_STRING.CONTACT_DATA_MANAGEMENT],
          icon: "line",
          route: "/contact",
          show: false,
          isHeader: false,
          privileges: ["contact-data"],
          children: [],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.SALES,
            APP_ROLES.SALES_PIC,
            APP_ROLES.FINANCE,
            APP_ROLES.FINANCE_PIC,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.MARKETING,
            APP_ROLES.MARKETING_PIC,
            APP_ROLES.DELIVERY_PIC,
            APP_ROLES.DELIVERY,
            APP_ROLES.PURCHASING_PIC,
            APP_ROLES.PURCHASING,
          ],
        },
        {
          key: "3-13",
          title: "lbl_batch_translator",
          menus: [MENU_STRING.CODE_TRANSLATOR],
          icon: "line",
          route: "/translator-batch",
          show: false,
          isHeader: false,
          privileges: ["code-translator"],
          children: [],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.PURCHASING_PIC,
            APP_ROLES.PURCHASING,
          ],
        },
      ],
    },
    {
      key: "4",
      title: "lbl_logistic",
      menus: [
        MENU_STRING.CALENDAR_MANAGEMENT,
        MENU_STRING.INVENTORY_RECEIVE,
        MENU_STRING.GOOD_RECEIPT_MANAGEMENT_WAREHOUSE,
        MENU_STRING.APPROVAL_INVENTORY_RECEIVE,
        MENU_STRING.JOB_COASTING_WAREHOUSE,
        MENU_STRING.JOB_COASTING_MEATROOM,
        MENU_STRING.PRODUCT_CATEGORY_MANAGEMENT,
        MENU_STRING.PRODUCT_MANAGEMENT,
        MENU_STRING.BRANCH_WAREHOUSE_MANAGEMENT,
        MENU_STRING.WAREHOUSE_MANAGEMENT,
        MENU_STRING.WAREHOUSE_LOCATION_MANAGEMENT,
        MENU_STRING.WAREHOUSE_TRANSFER_MANAGEMENT,
        MENU_STRING.STOCK_ADJUSTMENT_MANAGEMENT,
        MENU_STRING.INVENTORY_LINE_MANAGEMENT,
        MENU_STRING.STOCK_CARD_MANAGEMENT,
        MENU_STRING.STOCK_VALUATION_MANAGEMENT,
        MENU_STRING.HPP,
        MENU_STRING.STOCK_ADJUSTMENT_MANAGEMENT,
        MENU_STRING.BATCH,
      ],
      icon: "database",
      route: "",
      show: false,
      isHeader: true,
      privileges: [
        "calendar",
        "product",
        "product-category",
        "branch-warehouse",
        "warehouse",
        "warehouse-location",
        "warehouse-transfer",
        "stock-adjustment",
        "sales-return",
        "inventory-line",
        "stock-card",
        "stock-valuation",
        "inventory-receive",
        "good-receipt-warehouse",
        "job-costing-warehouse",
      ],
      roles: [
        APP_ROLES.ADMINISTRATOR,
        APP_ROLES.SUPER_ADMINISTRATOR,
        APP_ROLES.WAREHOUSE,
        APP_ROLES.WAREHOUSE_PIC,
        APP_ROLES.SALES,
        APP_ROLES.SALES_PIC,
        APP_ROLES.PURCHASING,
        APP_ROLES.PURCHASING_PIC,
        APP_ROLES.MEATROOM,
        APP_ROLES.MEATROOM_PIC,
        APP_ROLES.FINANCE,
        APP_ROLES.FINANCE_PIC,
        APP_ROLES.ACCOUNTING,
        APP_ROLES.ACCOUNTING_PIC,
        APP_ROLES.MARKETING,
        APP_ROLES.MARKETING_PIC,
      ],
      children: [
        {
          key: "4-1",
          title: "lbl_open_close_period",
          menus: [MENU_STRING.CALENDAR_MANAGEMENT],
          icon: "login",
          route: "/logistic/closeperiod",
          show: false,
          isHeader: false,
          privileges: ["calendar"],
          children: [],
          roles: [APP_ROLES.ADMINISTRATOR, APP_ROLES.SUPER_ADMINISTRATOR],
        },
        {
          key: "4-2",
          title: "lbl_master_product",
          menus: [
            MENU_STRING.PRODUCT_CATEGORY_MANAGEMENT,
            MENU_STRING.PRODUCT_MANAGEMENT,
          ],
          icon: "snippets",
          route: "",
          show: false,
          isHeader: true,
          privileges: ["product", "product-category"],
          children: [
            {
              key: "4-2-1",
              title: "lbl_product_category",
              menus: [MENU_STRING.PRODUCT_CATEGORY_MANAGEMENT],
              icon: "line",
              route: "/logistic/setupproduct/category/read",
              show: false,
              isHeader: false,
              privileges: ["product-category"],
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.SALES,
                APP_ROLES.SALES_PIC,
                APP_ROLES.WAREHOUSE,
                APP_ROLES.WAREHOUSE_PIC,
                APP_ROLES.PURCHASING,
                APP_ROLES.PURCHASING_PIC,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
                APP_ROLES.MARKETING,
                APP_ROLES.MARKETING_PIC,
              ],
            },
            {
              key: "4-2-2",
              title: "lbl_product",
              menus: [MENU_STRING.PRODUCT_MANAGEMENT],
              icon: "line",
              route: "/logistic/setupproduct/read",
              show: false,
              isHeader: false,
              privileges: ["product"],
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.SALES,
                APP_ROLES.SALES_PIC,
                APP_ROLES.WAREHOUSE,
                APP_ROLES.WAREHOUSE_PIC,
                APP_ROLES.MEATROOM,
                APP_ROLES.MEATROOM_PIC,
                APP_ROLES.PURCHASING,
                APP_ROLES.PURCHASING_PIC,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
                APP_ROLES.MARKETING,
                APP_ROLES.MARKETING_PIC,
              ],
            },
            {
              key: "4-2-3",
              title: "lbl_report_bottom_price",
              menus: [MENU_STRING.PRODUCT_MANAGEMENT],
              icon: "line",
              route: "/logistic/product/report/bottom-price",
              show: false,
              isHeader: false,
              privileges: ["product"],
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.SALES_PIC,
                APP_ROLES.WAREHOUSE_PIC,
                APP_ROLES.ACCOUNTING_PIC,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.FINANCE,
              ],
            },
          ],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.SALES,
            APP_ROLES.SALES_PIC,
            APP_ROLES.WAREHOUSE,
            APP_ROLES.WAREHOUSE_PIC,
            APP_ROLES.MEATROOM,
            APP_ROLES.MEATROOM_PIC,
            APP_ROLES.PURCHASING,
            APP_ROLES.PURCHASING_PIC,
            APP_ROLES.FINANCE,
            APP_ROLES.FINANCE_PIC,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.MARKETING,
            APP_ROLES.MARKETING_PIC,
          ],
        },
        {
          key: "4-3",
          title: "lbl_master_location",
          menus: [
            MENU_STRING.BRANCH_WAREHOUSE_MANAGEMENT,
            MENU_STRING.WAREHOUSE_MANAGEMENT,
            MENU_STRING.WAREHOUSE_LOCATION_MANAGEMENT,
          ],
          icon: "environment",
          route: "",
          show: false,
          isHeader: true,
          privileges: ["branch-warehouse", "warehouse", "warehouse-location"],
          children: [
            {
              key: "4-3-1",
              title: "lbl_branch",
              menus: [MENU_STRING.BRANCH_WAREHOUSE_MANAGEMENT],
              icon: "line",
              route: "/logistic/warehousebranch",
              show: false,
              isHeader: false,
              privileges: ["branch-warehouse"],
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.WAREHOUSE,
                APP_ROLES.WAREHOUSE_PIC,
                APP_ROLES.MEATROOM_PIC,
                APP_ROLES.PURCHASING,
                APP_ROLES.PURCHASING_PIC,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
                APP_ROLES.SALES,
                APP_ROLES.SALES_PIC,
              ],
            },
            {
              key: "4-3-2",
              title: "lbl_warehouse",
              menus: [MENU_STRING.WAREHOUSE_MANAGEMENT],
              icon: "line",
              route: "/logistic/warehouse",
              show: false,
              isHeader: false,
              privileges: ["warehouse"],
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.WAREHOUSE,
                APP_ROLES.WAREHOUSE_PIC,
                APP_ROLES.MEATROOM_PIC,
                APP_ROLES.PURCHASING,
                APP_ROLES.PURCHASING_PIC,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
                APP_ROLES.SALES,
                APP_ROLES.SALES_PIC,
              ],
            },
            {
              key: "4-3-3",
              title: "lbl_rack",
              menus: [MENU_STRING.WAREHOUSE_LOCATION_MANAGEMENT],
              icon: "line",
              route: "/logistic/warehouselocation",
              show: false,
              isHeader: false,
              privileges: ["warehouse-location"],
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.WAREHOUSE,
                APP_ROLES.WAREHOUSE_PIC,
                APP_ROLES.MEATROOM_PIC,
                APP_ROLES.PURCHASING,
                APP_ROLES.PURCHASING_PIC,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
                APP_ROLES.SALES,
                APP_ROLES.SALES_PIC,
              ],
            },
          ],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.WAREHOUSE,
            APP_ROLES.WAREHOUSE_PIC,
            APP_ROLES.MEATROOM_PIC,
            APP_ROLES.PURCHASING,
            APP_ROLES.PURCHASING_PIC,
            APP_ROLES.FINANCE,
            APP_ROLES.FINANCE_PIC,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.SALES,
            APP_ROLES.SALES_PIC,
          ],
        },
        {
          key: "4-4",
          title: "lbl_transaction_logistic",
          menus: [
            MENU_STRING.WAREHOUSE_TRANSFER_MANAGEMENT,
            MENU_STRING.STOCK_ADJUSTMENT_MANAGEMENT,
          ],
          icon: "file-text",
          show: false,
          isHeader: true,
          privileges: [
            "warehouse-transfer",
            "stock-adjustment",
            "sales-return",
          ],
          route: "",
          children: [
            {
              key: "4-4-1",
              title: "lbl_warehouse_transfer",
              menus: [MENU_STRING.WAREHOUSE_TRANSFER_MANAGEMENT],
              icon: "line",
              route: "/logistic/warehousetransferlist",
              show: false,
              isHeader: false,
              privileges: ["warehouse-transfer"],
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.WAREHOUSE,
                APP_ROLES.WAREHOUSE_PIC,
                APP_ROLES.MEATROOM_PIC,
                APP_ROLES.SALES_PIC,
                APP_ROLES.SALES,
                APP_ROLES.PURCHASING_PIC,
                APP_ROLES.PURCHASING,
              ],
            },
            {
              key: "4-4-2",
              title: "lbl_stock_adjustment",
              menus: [MENU_STRING.STOCK_ADJUSTMENT_MANAGEMENT],
              icon: "line",
              route: "/logistic/stockadjustment/read",
              isHeader: false,
              privileges: ["stock-adjustment"],
              show: false,
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.WAREHOUSE,
                APP_ROLES.WAREHOUSE_PIC,
                APP_ROLES.MEATROOM_PIC,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
                APP_ROLES.PURCHASING_PIC,
                APP_ROLES.PURCHASING,
              ],
            },
          ],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.WAREHOUSE,
            APP_ROLES.WAREHOUSE_PIC,
            APP_ROLES.MEATROOM_PIC,
            APP_ROLES.FINANCE,
            APP_ROLES.FINANCE_PIC,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.SALES_PIC,
            APP_ROLES.SALES,
            APP_ROLES.PURCHASING_PIC,
            APP_ROLES.PURCHASING,
          ],
        },
        {
          key: "4-5",
          title: "lbl_reports",
          menus: [
            MENU_STRING.INVENTORY_LINE_MANAGEMENT,
            MENU_STRING.STOCK_CARD_MANAGEMENT,
            MENU_STRING.STOCK_VALUATION_MANAGEMENT,
            MENU_STRING.HPP,
            MENU_STRING.STOCK_ADJUSTMENT_MANAGEMENT,
            MENU_STRING.BATCH,
          ],
          icon: "book",
          show: false,
          isHeader: true,
          privileges: ["inventory-line", "stock-card", "stock-valuation"],
          route: "",
          children: [
            {
              key: "4-5-1",
              title: "lbl_list_of_product",
              menus: [MENU_STRING.INVENTORY_LINE_MANAGEMENT],
              icon: "line",
              route: "/logistic/listofproduct",
              show: false,
              privileges: ["inventory-line"],
              isHeader: false,
              children: [],
              roles: [
                APP_ROLES.ACCOUNTING_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.FINANCE,
                APP_ROLES.MEATROOM,
                APP_ROLES.MEATROOM_PIC,
                APP_ROLES.WAREHOUSE_PIC,
                APP_ROLES.WAREHOUSE,
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.SALES_PIC,
                APP_ROLES.SALES,
                APP_ROLES.PURCHASING_PIC,
                APP_ROLES.PURCHASING,
                APP_ROLES.MARKETING_PIC,
                APP_ROLES.MARKETING,
              ],
            },
            {
              key: "4-5-2",
              title: "lbl_stock_card",
              menus: [MENU_STRING.STOCK_CARD_MANAGEMENT],
              icon: "line",
              route: "/logistic/reports/stockcard",
              show: false,
              privileges: ["stock-card"],
              isHeader: false,
              children: [],
              roles: [
                APP_ROLES.ACCOUNTING_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.FINANCE,
                APP_ROLES.MEATROOM,
                APP_ROLES.MEATROOM_PIC,
                APP_ROLES.WAREHOUSE,
                APP_ROLES.WAREHOUSE_PIC,
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.SALES_PIC,
                APP_ROLES.SALES,
                APP_ROLES.PURCHASING_PIC,
                APP_ROLES.PURCHASING,
                APP_ROLES.MARKETING_PIC,
                APP_ROLES.MARKETING,
              ],
            },
            {
              key: "4-5-3",
              title: "lbl_valuation_report_detail",
              menus: [MENU_STRING.STOCK_VALUATION_MANAGEMENT],
              icon: "line",
              route: "/logistic/report/valuation-detail",
              show: false,
              isHeader: false,
              privileges: ["stock-valuation"],
              children: [],
              roles: [
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
              ],
            },
            {
              key: "4-5-4",
              title: "lbl_report_hpp",
              menus: [MENU_STRING.HPP],
              icon: "line",
              route: "/logistic/report/hpp",
              show: false,
              isHeader: false,
              privileges: [],
              children: [],
              roles: [
                APP_ROLES.ACCOUNTING_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.FINANCE,
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
              ],
            },
            {
              key: "4-5-5",
              title: "lbl_report_stock_opname",
              menus: [MENU_STRING.STOCK_ADJUSTMENT_MANAGEMENT],
              icon: "line",
              route: "/logistic/report/stock-opname",
              show: false,
              isHeader: false,
              privileges: [],
              children: [],
              roles: [
                APP_ROLES.ACCOUNTING_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.FINANCE,
                APP_ROLES.MEATROOM_PIC,
                APP_ROLES.WAREHOUSE,
                APP_ROLES.WAREHOUSE_PIC,
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.SALES_PIC,
                APP_ROLES.SALES,
              ],
            },
            {
              key: "4-5-6",
              title: "lbl_report_history_batch",
              menus: [MENU_STRING.BATCH],
              icon: "line",
              route: "/logistic/report/history-batch",
              show: false,
              isHeader: false,
              privileges: [],
              children: [],
              roles: [
                APP_ROLES.ACCOUNTING_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.FINANCE,
                APP_ROLES.WAREHOUSE,
                APP_ROLES.WAREHOUSE_PIC,
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.SALES_PIC,
                APP_ROLES.SALES,
              ],
            },
          ],
          roles: [
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.FINANCE_PIC,
            APP_ROLES.FINANCE,
            APP_ROLES.MEATROOM_PIC,
            APP_ROLES.MEATROOM,
            APP_ROLES.WAREHOUSE,
            APP_ROLES.WAREHOUSE_PIC,
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.SALES_PIC,
            APP_ROLES.SALES,
            APP_ROLES.PURCHASING_PIC,
            APP_ROLES.PURCHASING,
            APP_ROLES.MARKETING_PIC,
            APP_ROLES.MARKETING,
          ],
        },
        {
          key: "4-6",
          title: "lbl_good_receipt",
          menus: [
            MENU_STRING.INVENTORY_RECEIVE,
            MENU_STRING.GOOD_RECEIPT_MANAGEMENT_WAREHOUSE,
            MENU_STRING.APPROVAL_INVENTORY_RECEIVE,
          ],
          icon: "file-done",
          route: "/logistic/receivingitems/goodreceipt",
          show: false,
          isHeader: false,
          privileges: ["inventory-receive", "good-receipt-warehouse"],
          children: [],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.WAREHOUSE,
            APP_ROLES.WAREHOUSE_PIC,
            APP_ROLES.PURCHASING,
            APP_ROLES.PURCHASING_PIC,
            APP_ROLES.FINANCE,
            APP_ROLES.FINANCE_PIC,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.SALES_PIC,
            APP_ROLES.SALES,
          ],
        },
        {
          key: "4-7",
          title: "lbl_job_costing",
          menus: [
            MENU_STRING.JOB_COASTING_WAREHOUSE,
            MENU_STRING.JOB_COASTING_MEATROOM,
          ],
          icon: "solution",
          isHeader: false,
          route: "/logistic/job-costing",
          show: false,
          privileges: ["job-costing-warehouse"],
          children: [],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.SALES,
            APP_ROLES.SALES_PIC,
            APP_ROLES.WAREHOUSE,
            APP_ROLES.WAREHOUSE_PIC,
            APP_ROLES.MEATROOM,
            APP_ROLES.MEATROOM_PIC,
            APP_ROLES.FINANCE,
            APP_ROLES.FINANCE_PIC,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.ACCOUNTING_PIC,
          ],
        },
      ],
    },
    {
      key: "5",
      title: "lbl_finance_accounting",
      menus: [
        MENU_STRING.ACCOUNTING_GENERAL_JOURNAL_MANAGEMENT,
        MENU_STRING.INVOICE_PREPAYMENT,
        MENU_STRING.INVOICE_AR,
        MENU_STRING.INVOICE_RECEIPT,
        MENU_STRING.CALENDAR_MANAGEMENT,
        MENU_STRING.INVOICE_JOIN,
      ],
      icon: "read",
      route: "",
      show: false,
      isHeader: true,
      privileges: [
        "general-journal",
        "invoice-receipt",
        "invoice-ar",
        "invoice-prepayment",
        "calendar",
      ],
      children: [
        {
          key: "5-1",
          title: "lbl_general_journal",
          menus: [MENU_STRING.ACCOUNTING_GENERAL_JOURNAL_MANAGEMENT],
          icon: "diff",
          show: false,
          isHeader: true,
          privileges: ["general-journal"],
          route: "",
          children: [
            {
              key: "5-1-1",
              title: "lbl_journal",
              menus: [MENU_STRING.ACCOUNTING_GENERAL_JOURNAL_MANAGEMENT],
              icon: "line",
              route: "/generaljournal/journal/read",
              show: false,
              isHeader: false,
              privileges: ["general-journal"],
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
              ],
            },
            {
              key: "5-1-2",
              title: "lbl_report_gl",
              menus: [MENU_STRING.ACCOUNTING_GENERAL_JOURNAL_MANAGEMENT],
              icon: "line",
              route: "/generaljournal/report-gl",
              show: false,
              isHeader: false,
              privileges: ["general-journal"],
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
              ],
            },
          ],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.FINANCE,
            APP_ROLES.FINANCE_PIC,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.ACCOUNTING_PIC,
          ],
        },
        {
          key: "5-2",
          title: "lbl_account_receivable",
          menus: [
            MENU_STRING.INVOICE_PREPAYMENT,
            MENU_STRING.INVOICE_AR,
            MENU_STRING.INVOICE_RECEIPT,
            MENU_STRING.CALENDAR_MANAGEMENT,
            MENU_STRING.INVOICE_JOIN,
          ],
          icon: "database",
          show: false,
          isHeader: true,
          privileges: ["invoice-prepayment", "invoice-ar", "calendar"],
          route: "",
          children: [
            {
              key: "5-2-1",
              title: "lbl_invoice",
              menus: [MENU_STRING.INVOICE_PREPAYMENT, MENU_STRING.INVOICE_AR],
              icon: "audit",
              route: "",
              show: false,
              isHeader: true,
              privileges: ["invoice-ar", "invoice-prepayment"],
              children: [
                {
                  key: "5-2-1-1",
                  title: "lbl_invoice_prepayment_credit_memo",
                  menus: [MENU_STRING.INVOICE_PREPAYMENT],
                  icon: "line",
                  route: "/account-receivables/invoices/invoiceprepayment",
                  show: false,
                  isHeader: false,
                  privileges: ["invoice-prepayment"],
                  children: [],
                  roles: [
                    APP_ROLES.ADMINISTRATOR,
                    APP_ROLES.SUPER_ADMINISTRATOR,
                    APP_ROLES.FINANCE,
                    APP_ROLES.FINANCE_PIC,
                    APP_ROLES.ACCOUNTING,
                    APP_ROLES.ACCOUNTING_PIC,
                  ],
                },
                {
                  key: "5-2-1-2",
                  title: "lbl_invoice",
                  menus: [MENU_STRING.INVOICE_AR],
                  icon: "line",
                  route: "/account-receivables/invoices",
                  show: false,
                  isHeader: false,
                  privileges: ["invoice-prepayment"],
                  children: [],
                  roles: [
                    APP_ROLES.ADMINISTRATOR,
                    APP_ROLES.SUPER_ADMINISTRATOR,
                    APP_ROLES.FINANCE,
                    APP_ROLES.FINANCE_PIC,
                    APP_ROLES.ACCOUNTING,
                    APP_ROLES.ACCOUNTING_PIC,
                    APP_ROLES.MARKETING_PIC,
                    APP_ROLES.MARKETING,
                  ],
                },
              ],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
                APP_ROLES.MARKETING_PIC,
                APP_ROLES.MARKETING,
              ],
            },
            {
              key: "5-2-2",
              title: "lbl_ar_receipt",
              menus: [MENU_STRING.INVOICE_RECEIPT],
              icon: "line",
              show: false,
              isHeader: false,
              privileges: ["invoice-receipt"],
              route: "/account-receivables/ar-receipt",
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
              ],
            },
            {
              key: "5-2-3",
              title: "lbl_open_close_transaction",
              menus: [MENU_STRING.CALENDAR_MANAGEMENT],
              icon: "line",
              show: false,
              isHeader: false,
              privileges: ["calendar"],
              route: "/account-receivables/closeperiod",
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
              ],
            },
            {
              key: "5-2-4",
              title: "lbl_join_invoices",
              menus: [MENU_STRING.INVOICE_JOIN],
              icon: "line",
              show: false,
              isHeader: false,
              privileges: [],
              route: "/account-receivables/join-invoices",
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
              ],
            },
            /**
             * task GPW-1001 sprint support
             */
            // {
            //   key: "5-2-5",
            //   title: "lbl_reports",
            //   icon: "book",
            //   show: false,
            //   isHeader: true,
            //   privileges: [],
            //   route: "",
            //   children: [
            //     {
            //       key: "5-2-4-1",
            //       title: "lbl_report_aging_details",
            //       icon: "line",
            //       show: false,
            //       isHeader: false,
            //       privileges: [],
            //       route: "/account-receivables/invoices/report/aging/details",
            //       children: [],
            //       roles: [
            //         APP_ROLES.ADMINISTRATOR, APP_ROLES.SUPER_ADMINISTRATOR,
            //         APP_ROLES.FINANCE,
            //         APP_ROLES.FINANCE_PIC,
            //         APP_ROLES.ACCOUNTING,
            //         APP_ROLES.ACCOUNTING_PIC,
            //       ],
            //     },
            //     {
            //       key: "5-2-4-2",
            //       title: "lbl_report_aging_summary",
            //       icon: "line",
            //       show: false,
            //       isHeader: false,
            //       privileges: [],
            //       route: "/account-receivables/invoices/report/aging/summary",
            //       children: [],
            //       roles: [
            //         APP_ROLES.ADMINISTRATOR, APP_ROLES.SUPER_ADMINISTRATOR,
            //         APP_ROLES.FINANCE,
            //         APP_ROLES.FINANCE_PIC,
            //         APP_ROLES.ACCOUNTING,
            //         APP_ROLES.ACCOUNTING_PIC,
            //       ],
            //     },
            //   ],
            //   roles: [
            //     APP_ROLES.ADMINISTRATOR, APP_ROLES.SUPER_ADMINISTRATOR,
            //     APP_ROLES.FINANCE,
            //     APP_ROLES.FINANCE_PIC,
            //     APP_ROLES.ACCOUNTING,
            //     APP_ROLES.ACCOUNTING_PIC,
            //   ],
            // },
          ],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.FINANCE,
            APP_ROLES.FINANCE_PIC,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.MARKETING_PIC,
            APP_ROLES.MARKETING,
          ],
        },
        {
          key: "5-3",
          title: "lbl_reports",
          menus: [],
          icon: "book",
          show: false,
          isHeader: true,
          privileges: [],
          route: "",
          children: [
            {
              key: "5-3-1",
              title: "Balance Sheet",
              menus: [],
              icon: "line",
              route: "",
              show: false,
              isHeader: false,
              privileges: [],
              children: [],
              roles: [],
            },
            {
              key: "5-3-2",
              title: "P/L Reports",
              menus: [],
              icon: "line",
              route: "",
              show: false,
              isHeader: false,
              privileges: [],
              children: [],
              roles: [],
            },
          ],
          roles: [],
        },
      ],
      roles: [
        APP_ROLES.ADMINISTRATOR,
        APP_ROLES.SUPER_ADMINISTRATOR,
        APP_ROLES.FINANCE,
        APP_ROLES.FINANCE_PIC,
        APP_ROLES.ACCOUNTING,
        APP_ROLES.ACCOUNTING_PIC,
        APP_ROLES.MARKETING_PIC,
        APP_ROLES.MARKETING,
      ],
    },
    {
      key: "6",
      title: "lbl_qrcode",
      menus: [MENU_STRING.BATCH],
      icon: "qrcode",
      route: "",
      show: false,
      isHeader: true,
      privileges: ["batch"],
      children: [
        {
          key: "6-1",
          title: "lbl_list_qr",
          menus: [MENU_STRING.BATCH],
          icon: "line",
          show: false,
          route: "/qr/list",
          isHeader: false,
          privileges: ["batch"],
          children: [],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.WAREHOUSE,
            APP_ROLES.WAREHOUSE_PIC,
            APP_ROLES.MEATROOM,
            APP_ROLES.FINANCE,
            APP_ROLES.FINANCE_PIC,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.SALES_PIC,
            APP_ROLES.SALES,
          ],
        },
        {
          key: "6-2",
          title: "lbl_create_qr",
          menus: [MENU_STRING.BATCH],
          icon: "line",
          show: false,
          route: "/qr/create",
          isHeader: false,
          privileges: ["batch"],
          children: [],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.WAREHOUSE,
            APP_ROLES.WAREHOUSE_PIC,
            APP_ROLES.FINANCE,
            APP_ROLES.FINANCE_PIC,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.SALES_PIC,
            APP_ROLES.SALES,
          ],
        },
      ],
      roles: [
        APP_ROLES.ADMINISTRATOR,
        APP_ROLES.SUPER_ADMINISTRATOR,
        APP_ROLES.WAREHOUSE,
        APP_ROLES.WAREHOUSE_PIC,
        APP_ROLES.MEATROOM,
        APP_ROLES.FINANCE,
        APP_ROLES.FINANCE_PIC,
        APP_ROLES.ACCOUNTING,
        APP_ROLES.ACCOUNTING_PIC,
        APP_ROLES.SALES_PIC,
        APP_ROLES.SALES,
      ],
    },
    {
      key: "7",
      title: "lbl_sales",
      menus: [
        MENU_STRING.QUOTATION,
        MENU_STRING.SALES_ORDER,
        MENU_STRING.DELIVERY_ORDER,
        MENU_STRING.SALES_RETURN,
        MENU_STRING.SALES_DETAIL,
        MENU_STRING.SALES_DETAIL_MARKETING,
      ],
      icon: "transaction",
      route: "",
      show: false,
      isHeader: true,
      privileges: [
        "quotation",
        "job-costing-warehouse",
        "sales-order",
        "sales-order-warehouse",
        "delivery-order",
        "sales-return",
      ],
      children: [
        {
          key: "7-1",
          title: "lbl_transaction_sales",
          menus: [
            MENU_STRING.QUOTATION,
            MENU_STRING.SALES_ORDER,
            MENU_STRING.DELIVERY_ORDER,
            MENU_STRING.SALES_RETURN,
          ],
          icon: "audit",
          isHeader: true,
          route: "",
          show: false,
          privileges: [
            "quotation",
            "job-costing-warehouse",
            "sales-order",
            "sales-order-warehouse",
            "delivery-order",
            "sales-return",
          ],
          children: [
            {
              key: "7-1-1",
              title: "lbl_quotation",
              menus: [MENU_STRING.QUOTATION],
              icon: "line",
              isHeader: false,
              route: "/sales/transaction-sales/quotation",
              show: false,
              privileges: ["quotation"],
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.SALES,
                APP_ROLES.SALES_PIC,
                APP_ROLES.WAREHOUSE_PIC,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
              ],
            },
            {
              key: "7-1-2",
              title: "lbl_sales_order",
              menus: [MENU_STRING.SALES_ORDER],
              icon: "line",
              isHeader: false,
              route: "/sales/transaction-sales/sales-order",
              show: false,
              privileges: ["sales-order", "sales-order-warehouse"],
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.SALES,
                APP_ROLES.SALES_PIC,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
              ],
            },
            {
              key: "7-1-3",
              title: "lbl_delivery_order",
              menus: [MENU_STRING.DELIVERY_ORDER],
              icon: "line",
              isHeader: false,
              route: "/sales/transaction-sales/delivery-order",
              show: false,
              privileges: ["delivery-order"],
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.SALES,
                APP_ROLES.SALES_PIC,
                APP_ROLES.WAREHOUSE,
                APP_ROLES.WAREHOUSE_PIC,
                APP_ROLES.MEATROOM_PIC,
                APP_ROLES.DELIVERY,
                APP_ROLES.DELIVERY_PIC,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
              ],
            },
            {
              key: "7-1-4",
              title: "lbl_customer_return",
              menus: [MENU_STRING.SALES_RETURN],
              icon: "line",
              isHeader: false,
              route: "/sales/transaction-sales/customer-return",
              show: false,
              privileges: ["sales-return"],
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.WAREHOUSE,
                APP_ROLES.WAREHOUSE_PIC,
                APP_ROLES.DELIVERY,
                APP_ROLES.DELIVERY_PIC,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
                APP_ROLES.MARKETING,
                APP_ROLES.MARKETING_PIC,
                APP_ROLES.SALES_PIC,
                APP_ROLES.SALES,
              ],
            },
          ],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.SALES,
            APP_ROLES.SALES_PIC,
            APP_ROLES.WAREHOUSE,
            APP_ROLES.WAREHOUSE_PIC,
            APP_ROLES.MEATROOM_PIC,
            APP_ROLES.DELIVERY,
            APP_ROLES.DELIVERY_PIC,
            APP_ROLES.FINANCE,
            APP_ROLES.FINANCE_PIC,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.MARKETING,
            APP_ROLES.MARKETING_PIC,
          ],
        },
        {
          key: "7-2",
          title: "lbl_reports",
          menus: [MENU_STRING.SALES_DETAIL, MENU_STRING.SALES_DETAIL_MARKETING],
          icon: "transaction",
          isHeader: true,
          route: "",
          show: false,
          privileges: [],
          children: [
            {
              key: "7-2-1",
              title: "lbl_sales_report_detail",
              menus: [
                MENU_STRING.SALES_DETAIL,
                MENU_STRING.SALES_DETAIL_MARKETING,
              ],
              icon: "line",
              isHeader: false,
              route: "/sales/reports/sales-detail",
              show: false,
              privileges: [],
              children: [],
              roles: [
                APP_ROLES.ADMINISTRATOR,
                APP_ROLES.SUPER_ADMINISTRATOR,
                APP_ROLES.FINANCE,
                APP_ROLES.FINANCE_PIC,
                APP_ROLES.ACCOUNTING,
                APP_ROLES.ACCOUNTING_PIC,
              ],
            },
          ],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.FINANCE,
            APP_ROLES.FINANCE_PIC,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.ACCOUNTING_PIC,
          ],
        },
      ],
      roles: [
        APP_ROLES.ADMINISTRATOR,
        APP_ROLES.SUPER_ADMINISTRATOR,
        APP_ROLES.SALES,
        APP_ROLES.SALES_PIC,
        APP_ROLES.WAREHOUSE,
        APP_ROLES.WAREHOUSE_PIC,
        APP_ROLES.MEATROOM_PIC,
        APP_ROLES.DELIVERY,
        APP_ROLES.DELIVERY_PIC,
        APP_ROLES.FINANCE,
        APP_ROLES.FINANCE_PIC,
        APP_ROLES.ACCOUNTING,
        APP_ROLES.ACCOUNTING_PIC,
        APP_ROLES.MARKETING,
        APP_ROLES.MARKETING_PIC,
      ],
    },
    {
      key: "8",
      title: "lbl_reports",
      menus: [
        MENU_STRING.STOCK_CARD_MANAGEMENT,
        MENU_STRING.JOB_COASTING_MEATROOM,
        MENU_STRING.JOB_COASTING_WAREHOUSE,
      ],
      icon: "book",
      route: "",
      show: false,
      isHeader: true,
      privileges: ["stock-card"],
      children: [
        {
          key: "8-1",
          title: "lbl_report_slow_fast_moving_item",
          menus: [MENU_STRING.STOCK_CARD_MANAGEMENT],
          icon: "line-chart",
          route: "/report/moving",
          show: false,
          isHeader: false,
          privileges: ["stock-card"],
          children: [],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.FINANCE,
            APP_ROLES.FINANCE_PIC,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.ACCOUNTING_PIC,
            APP_ROLES.MARKETING,
            APP_ROLES.MARKETING_PIC,
            APP_ROLES.WAREHOUSE_PIC,
            APP_ROLES.MEATROOM_PIC,
            APP_ROLES.SALES_PIC,
            APP_ROLES.SALES,
            APP_ROLES.WAREHOUSE,
            APP_ROLES.MEATROOM,
          ],
        },
        {
          key: "8-2",
          title: "lbl_report_job_costing",
          menus: [
            MENU_STRING.JOB_COASTING_MEATROOM,
            MENU_STRING.JOB_COASTING_WAREHOUSE,
          ],
          icon: "line-chart",
          route: "/report/job-costing",
          show: false,
          isHeader: false,
          privileges: ["job-costing-warehouse"],
          children: [],
          roles: [
            APP_ROLES.ADMINISTRATOR,
            APP_ROLES.SUPER_ADMINISTRATOR,
            APP_ROLES.FINANCE,
            APP_ROLES.FINANCE_PIC,
            APP_ROLES.ACCOUNTING,
            APP_ROLES.ACCOUNTING_PIC,
          ],
        },
      ],
      roles: [
        APP_ROLES.ADMINISTRATOR,
        APP_ROLES.SUPER_ADMINISTRATOR,
        APP_ROLES.FINANCE,
        APP_ROLES.FINANCE_PIC,
        APP_ROLES.ACCOUNTING,
        APP_ROLES.ACCOUNTING_PIC,
        APP_ROLES.MARKETING,
        APP_ROLES.MARKETING_PIC,
        APP_ROLES.WAREHOUSE_PIC,
        APP_ROLES.MEATROOM_PIC,
        APP_ROLES.SALES_PIC,
        APP_ROLES.WAREHOUSE,
        APP_ROLES.MEATROOM,
      ],
    },
  ];
};
