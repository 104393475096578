import { RouteConfig } from "vue-router";

import { config } from "@/models/constant/routes.constant";

const router: RouteConfig = {
  path: config.SALES_ROUTE.path,
  name: config.SALES_ROUTE.name,
  meta: config.SALES_ROUTE.meta,
  component: {
    render(c) { return c("router-view"); }
  },
  children: [
    {
      path: config.QUOTATION_SALES_LIST_ROUTE.path,
      name: config.QUOTATION_SALES_LIST_ROUTE.name,
      meta: config.QUOTATION_SALES_LIST_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/quotation/Quotation.vue")
    },
    {
      path: config.QUOTATION_SALES_CREATE_ROUTE.path,
      name: config.QUOTATION_SALES_CREATE_ROUTE.name,
      meta: config.QUOTATION_SALES_CREATE_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/quotation/QuotationForm.vue")
    },
    {
      path: config.QUOTATION_SALES_EDIT_ROUTE.path,
      name: config.QUOTATION_SALES_EDIT_ROUTE.name,
      meta: config.QUOTATION_SALES_EDIT_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/quotation/QuotationForm.vue")
    },
    {
      path: config.JOB_COSTING_LIST.path,
      name: config.JOB_COSTING_LIST.name,
      meta: config.JOB_COSTING_LIST.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/job-costing/JobCostingList.vue")
    },
    {
      path: config.JOB_COSTING_LOAF_CREATE.path,
      name: config.JOB_COSTING_LOAF_CREATE.name,
      meta: config.JOB_COSTING_LOAF_CREATE.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/job-costing/JobCostingLoaf.vue")
    },
    {
      path: config.JOB_COSTING_LOAF_VIEW.path,
      name: config.JOB_COSTING_LOAF_VIEW.name,
      meta: config.JOB_COSTING_LOAF_VIEW.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/job-costing/JobCostingLoaf.vue")
    },
    {
      path: config.JOB_COSTING_WAREHOUSE_PICKED_CREATE.path,
      name: config.JOB_COSTING_WAREHOUSE_PICKED_CREATE.name,
      meta: config.JOB_COSTING_WAREHOUSE_PICKED_CREATE.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/job-costing/JobCostingWarehousePickedV2.vue")
    },
    {
      path: config.JOB_COSTING_WAREHOUSE_PICKED_VIEW.path,
      name: config.JOB_COSTING_WAREHOUSE_PICKED_VIEW.name,
      meta: config.JOB_COSTING_WAREHOUSE_PICKED_VIEW.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/job-costing/JobCostingWarehousePickedV2.vue")
    },
    {
      path: config.JOB_COSTING_MEATROOM_CREATE.path,
      name: config.JOB_COSTING_MEATROOM_CREATE.name,
      meta: config.JOB_COSTING_MEATROOM_CREATE.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/job-costing/JobCostingMeatroomV2.vue")
    },
    {
      path: config.JOB_COSTING_MEATROOM_VIEW.path,
      name: config.JOB_COSTING_MEATROOM_VIEW.name,
      meta: config.JOB_COSTING_MEATROOM_VIEW.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/job-costing/JobCostingMeatroomV2.vue")
    },
    {
      path: config.SALES_ORDER_LIST.path,
      name: config.SALES_ORDER_LIST.name,
      meta: config.SALES_ORDER_LIST.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/sales-order/SalesOrderList.vue")
    },
    {
      path: config.SALES_ORDER_FORM_NEW.path,
      name: config.SALES_ORDER_FORM_NEW.name,
      meta: config.SALES_ORDER_FORM_NEW.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/sales-order/SalesOrderFormIndex.vue")
    },
    {
      path: config.SALES_ORDER_FORM_EDIT.path,
      name: config.SALES_ORDER_FORM_EDIT.name,
      meta: config.SALES_ORDER_FORM_EDIT.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/sales-order/SalesOrderFormIndex.vue")
    },
    {
      path: config.SALES_ORDER_FORM_DETAIL.path,
      name: config.SALES_ORDER_FORM_DETAIL.name,
      meta: config.SALES_ORDER_FORM_DETAIL.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/sales-order/SalesOrderFormIndex.vue")
    },
    {
      path: config.JOB_COSTING_POST_MEATROOM_PROCESS_CREATE.path,
      name: config.JOB_COSTING_POST_MEATROOM_PROCESS_CREATE.name,
      meta: config.JOB_COSTING_POST_MEATROOM_PROCESS_CREATE.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/job-costing/JobCostingPostMeatroomProcess.vue")
    },
    {
      path: config.JOB_COSTING_POST_MEATROOM_PROCESS_VIEW.path,
      name: config.JOB_COSTING_POST_MEATROOM_PROCESS_VIEW.name,
      meta: config.JOB_COSTING_POST_MEATROOM_PROCESS_VIEW.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/job-costing/JobCostingPostMeatroomProcess.vue")
    },
    {
      path: config.DELIVERY_ORDER_LIST.path,
      name: config.DELIVERY_ORDER_LIST.name,
      meta: config.DELIVERY_ORDER_LIST.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/delivery-order/DeliveryOrderList.vue")
    },
    {
      path: config.DELIVERY_ORDER_CREATE.path,
      name: config.DELIVERY_ORDER_CREATE.name,
      meta: config.DELIVERY_ORDER_CREATE.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/delivery-order/DeliveryOrderForm.vue")
    },
    {
      path: config.DELIVERY_ORDER_EDIT.path,
      name: config.DELIVERY_ORDER_EDIT.name,
      meta: config.DELIVERY_ORDER_EDIT.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/delivery-order/DeliveryOrderForm.vue")
    },
    {
      path: config.DELIVERY_ORDER_DETAIL.path,
      name: config.DELIVERY_ORDER_DETAIL.name,
      meta: config.DELIVERY_ORDER_DETAIL.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/delivery-order/DeliveryOrderForm.vue")
    },
    {
      path: config.CUSTOMER_RETURN_LIST_ROUTE.path,
      name: config.CUSTOMER_RETURN_LIST_ROUTE.name,
      meta: config.CUSTOMER_RETURN_LIST_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/customer-return/CustomerReturnList.vue")
    },
    {
      path: config.CUSTOMER_RETURN_CREATE_ROUTE.path,
      name: config.CUSTOMER_RETURN_CREATE_ROUTE.name,
      meta: config.CUSTOMER_RETURN_CREATE_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/customer-return/CustomerReturnForm.vue")
    },
    {
      path: config.CUSTOMER_RETURN_EDIT_ROUTE.path,
      name: config.CUSTOMER_RETURN_EDIT_ROUTE.name,
      meta: config.CUSTOMER_RETURN_EDIT_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/customer-return/CustomerReturnForm.vue")
    },
    {
      path: config.CUSTOMER_RETURN_DETAIL_ROUTE.path,
      name: config.CUSTOMER_RETURN_DETAIL_ROUTE.name,
      meta: config.CUSTOMER_RETURN_DETAIL_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */"./transaction-sales/customer-return/CustomerReturnForm.vue")
    },
    {
      path: config.REPORT_SALES_DETAIL.path,
      name: config.REPORT_SALES_DETAIL.name,
      meta: config.REPORT_SALES_DETAIL.meta,
      component: () => import(/* webpackPrefetch: true */"./reports/ReportSalesDetail.vue")
    },
  ]
};

export default router;
