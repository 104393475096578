




































import Vue from "vue";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { debounceProcess } from "@/helpers/debounce";
import { IOption } from "@/models/interface-v2/common.interface";
import MNotificationVue from "@/mixins/MNotification.vue";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
import { accountingAccountService } from "@/services-v2/accounting-account.service";
import { ResponseAccountingAccount, ResponseListAccountingAccount } from "@/models/interface-v2/accounting-account.interface";

export default Vue.extend({
  name: "CSelectAccountingAccount",
  mixins: [
    MNotificationVue
  ],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: undefined
    },
    propSearchBy: {
      type: String,
      default: undefined,
    },
    forList: {
      type: Boolean,
      default: true
    },
    propAccountName: {
      type: String,
      default: undefined,
    },
    labelOpt: {
      type: String,
      default: "code"
    },
    labelKey: {
      type: Array as () => string[],
      default: undefined,
    },
  },
  data() {
    this.searchAccount = debounceProcess(this.searchAccount, 300);
    return {
      loading: false,
      dtAcc: {} as ResponseListAccountingAccount,
      optAcc: [] as IOption<ResponseAccountingAccount>[],
      queryParams: {
        page: 0,
        limit: DEFAULT_PAGE_SIZE,
        search: ""
      },
    };
  },
  watch: {
    propAccountName: {
      immediate: true,
      handler: "handleMissingOpts"
    }
  },
  created() {
    if (this.propSearchBy) this.queryParams.search = `active~true_AND_${this.propSearchBy}`;
    this.getList(this.queryParams);
  },
  methods: {
    getListAccountingAccount(params: RequestQueryParamsModel): Promise<ResponseListAccountingAccount> {
      return accountingAccountService.getListCOA(params);
    },
    async searchAccount(search = ""): Promise<void> {
      try {
        this.loading = true;
        const searchBy: string[] = [];
        if (search) searchBy.push(`code~*${search}*_OR_description~*${search}*`);
        if (this.propSearchBy) searchBy.push(this.propSearchBy);
        this.queryParams.search = searchBy.join("_AND_");
        this.queryParams.page = 0;
        this.optAcc = [];
        this.getList(this.queryParams, true);
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    onSelect(e: string, meta: ResponseAccountingAccount): void {
      this.$emit("input", e);
      this.$emit("on-select", { value: e, meta });
      this.$emit("change", e);
    },
    popupScroll(e): void {
      if ((this.dtAcc.totalPages - 1) === this.dtAcc.currentPage) return;
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.queryParams.page += 1;
        this.getList(this.queryParams);
      }
    },
    async getList(params: RequestQueryParamsModel, search = false): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListAccountingAccount(params);
        const { optAcc } = this;
        const opts = res.data.map(x => {
          return {
            key: this.getLabel(x),
            value: x.id,
            meta: x,
          };
        });
        this.optAcc = [...optAcc, ...opts];
        this.dtAcc = res;
        if (!search) {
          this.handleMissingOpts();
        }
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    getLabel(item: ResponseAccountingAccount) {
      let str: string[] = [];
      for (const prop in item) {
        for (const el of this.labelKey) {
          if (prop === el) {
            str.push(item[prop]);
          }
        }
      }
      return str.join(" - ");
    },
    async handleMissingOpts(): Promise<void> {
      try {
        if (this.forList || !this.value || !this.optAcc.length) return;
        const opt = this.optAcc.find(x => x.value === this.value);
        if (!opt) {
          const { data } = await this.getListAccountingAccount({ search: `secureId~${this.value}` });
          const newOpt = { key: this.getLabel(data[0]), value: data[0].id, meta: data[0] };
          const { optAcc } = this;
          this.optAcc = [...optAcc, newOpt];
        }
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    clear(): void {
      this.$emit("input", undefined);
      this.$emit("on-clear", { value: "", meta: undefined });
    },
  }
});

