// AUTH
class ApiUrl {
  readonly MASTER_PATH_GPW = "/api/";
  readonly MASTER_PATH_SCALE_API = process.env.VUE_APP_SCALE_APP_PROXY_PATH;

  // AUTH
  readonly LOGIN = this.getPath("oauth/token");

  // Users
  readonly ForgotPassword = this.getPath("v1/user/forgot/password");
  readonly ChangePassword = this.getPath("v1/user/password");
  readonly Users = this.getPath("v1/user");
  readonly Menus = this.getPath("v1/menu");
  readonly Privilege = this.getPath("v1/privilege");
  readonly Role = this.getPath("v1/role");
  readonly UserDetailAuth = this.getPath("v1/user/detail/auth");

  // Settings
  readonly Coa = this.getPath("v1/accounting/account");
  readonly Tax = this.getPath("v1/accounting/tax");
  readonly Currency = this.getPath("v1/accounting/currency");
  readonly CurrencyConversion = this.getPath(
    "v1/accounting/currency/conversion"
  );
  readonly MasterUom = this.getPath("v1/product/uom");
  readonly AssetsBook = this.getPath("v1/asset/book");
  readonly InventoryLine = this.getPath("v1/logistic/inventory/line");
  readonly InventoryLineBatch = this.getPath(
    "v1/logistic/inventory/line/batch"
  );
  readonly AccountType = this.getPath("v1/accounting/account/type");
  readonly Company = this.getPath("v1/company");
  readonly Calendar = this.getPath("v1/calendar");
  readonly CalendarUpdate = this.getPath("v1/calendar/close/period");
  readonly Product = this.getPath("v1/product");
  readonly ProductBottomPrice = this.getPath("v1/product/bottom-price");
  readonly ProductItemCode = this.getPath("v1/product/item-code");
  readonly ProductCategory = this.getPath("v1/product/category");
  readonly TaxCode = this.getPath("v1/tax/invoice/code");
  readonly TaxSerialNumber = this.getPath("v1/tax/invoice/sn");
  readonly TaxSerialNumberDetail = this.getPath("v1/tax/invoice/sn/detail");
  readonly Preference = this.getPath("v1/preferences");

  // QR
  readonly QR = this.getPath("v1/asset/qr-code/detail");
  readonly QRAssetAddition = this.getPath(
    "v1/asset/asset-addition/qr-code/detail"
  );

  // BATCH
  readonly Batch = this.getPath("v1/batch");

  // BATCHING
  readonly Batching = this.getPath("v1/batching");

  // upload
  readonly uploadFileModul = this.getPath("v1/file");

  // Assets
  readonly Category = this.getPath("v1/asset/category");
  readonly AssetCategory = this.getPath("v1/asset/asset-category");
  readonly AssetCategoryActive = this.getPath("v1/asset/asset-category/active");
  readonly Segment = this.getPath("v1/asset/segment");
  readonly AssetAditions = this.getPath("v1/asset/asset-addition");
  readonly AssetAditionsDraft = this.getPath("v1/asset/asset-addition/draft");
  readonly AssetAditionsTemplate = this.getPath(
    "v1/asset/asset-addition/uploadTemplate"
  );
  readonly AssetAdditionsUpload = this.getPath(
    "v1/asset/asset-addition/upload"
  );
  readonly AssetAdditionsPosting = this.getPath("v1/asset/asset-addition/post");
  readonly AssetHistory = this.getPath("v1/asset/asset-history");
  readonly AssetDepreciation = this.getPath("v1/asset/depreciation");
  readonly AssetMaster = this.getPath("v1/asset");
  readonly AssetCorporateBook = this.getPath("v1/asset/book/corporate");
  readonly AssetRetirement = this.getPath("v1/asset/retirement");
  readonly AssetAdjustmentFrom = this.getPath("v1/asset/adjustment/form");
  readonly AssetAdjustmentFinancial = this.getPath(
    "v1/asset/adjustment/financial"
  );
  readonly AssetAdjustmentAssignment = this.getPath(
    "v1/asset/adjustment/assignment"
  );
  readonly AssetAdditionTemplateDownload = this.getPath(
    "v1/asset/asset-addition/template"
  );
  readonly AssetInquiry = this.getPath("v1/asset/inquiry");
  readonly AssetInquiryDetail = this.getPath("v1/asset/inquiry/detail");
  readonly AssetTag = this.getPath("v1/asset/tag");
  readonly getReportAsset = this.getPath("v1/asset/report");
  readonly getReportAssetAdditions = this.getPath(
    "v1/asset/asset-addition/report"
  );
  readonly getReportAssetRetirement = this.getPath(
    "v1/asset/retirement/report"
  );
  readonly getReportAssetRetirementDownload = this.getPath(
    "v1/asset/retirement/report/download"
  );
  readonly getReportAssetRetirementPrint = this.getPath(
    "v1/asset/retirement/report/print"
  );
  readonly getReportAssetAdjustment = this.getPath("v1/asset/adjustment");
  readonly getReportAssetAdjustmentDownload = this.getPath(
    "v1/asset/adjustment/report/download"
  );
  readonly getReportAssetAdjustmentPrint = this.getPath(
    "v1/asset/adjustment/report/print"
  );
  readonly getAssetAdjustment = this.getPath("v1/asset/adjustment/detail");
  readonly reclass = this.getPath("v1/asset/reclass");
  readonly AssetAdditionReport = this.getPath("v1/asset/asset-addition/report");

  //General Journal
  readonly generalJournal = this.getPath("v1/accounting/generaljournal");

  // Contact Data
  readonly Region = this.getPath("v1/region");
  readonly ContactData = this.getPath("v1/contact-data");

  // Master
  readonly MasterType = this.getPath("v1/master/type");

  // Bank
  readonly Bank = this.getPath("v1/bank");
  readonly BankCompany = this.getPath("v1/company/bank");

  // Warehouse
  readonly Warehouse = this.getPath("v1/logistic/warehouse");
  readonly WarehouseBranch = this.getPath("v1/logistic/warehouse/branch");
  readonly WarehouseLocation = this.getPath("v1/logistic/warehouse/location");
  readonly WarehouseTransfer = this.getPath("v1/logistic/warehouse/transfer");
  readonly WarehouseTransferCancel = this.getPath(
    "v1/logistic/warehouse/transfer/cancel"
  );
  readonly WarehouseTransferReceive = this.getPath(
    "v1/logistic/warehouse/transfer/receive"
  );
  readonly WarehouseTransferSubmit = this.getPath(
    "v1/logistic/warehouse/transfer/submit"
  );

  readonly StockAdjustment = this.getPath("v1/logistic/stock-adjustment");
  readonly ReportValuation = this.getPath("v1/logistic/stock-report/valuation");
  readonly ReportStockCard = this.getPath(
    "v1/logistic/stock-report/stock-card"
  );

  // RFQ
  readonly Quotation = this.getPath("v1/rfq");
  readonly QuotationOrderCustomer = this.getPath("v1/rfq/order-customer");

  //logistic
  readonly ReceiveItemDocument = this.getPath(
    "v1/logistic/receive-item/doc-no"
  );
  readonly ReceiveItem = this.getPath("v1/logistic/receive-item");
  readonly ReceiveItemDownload = this.getPath(
    "v1/logistic/receive-item/report/download"
  );
  readonly ReceiveItemPrint = this.getPath(
    "v1/logistic/receive-item/report/print"
  );
  readonly ReceiveItemDraft = this.getPath("v1/logistic/receive-item/draft");
  readonly ReceiveItemUpate = this.getPath("v1/logistic/receive-item/submit");
  readonly Workorder = this.getPath("v1/logistic/work-order");
  readonly ClosePeriodInventory = this.getPath(
    "v1/logistic/inventory/close-period"
  );
  readonly SalesReturn = this.getPath("v1/sales/return-item");
  readonly SalesReturnDownload = this.getPath(
    "v1/sales/return-item/report/download"
  );
  readonly PrintReturnFromCustomer = this.getPath(
    "v1/sales/return-item/report/print"
  );

  // receive item checklist
  readonly ReceiveItemWarehouse = this.getPath(
    "v1/logistic/receive-item/warehouse"
  );

  // job costing
  readonly JobCosting = this.getPath("v1/job-costing");
  readonly JobCostingPostMeatroom = this.getPath(
    "v1/job-costing/meatroom/postmeatroom"
  );

  // sales order
  readonly SalesOrder = this.getPath("v1/sales-order");

  // delivery order
  readonly DeliveryOrder = this.getPath("v1/sales/delivery-order");
  readonly AvailableDeliveryOrder = this.getPath(
    "v1/sales/delivery-order/available/ar"
  );

  //Invoice Prepayment
  readonly InvoicePrepayment = this.getPath("v1/ar/invoice-prepayment");
  readonly CancelInvoicePrepayment = this.getPath(
    "v1/ar/invoice-prepayment/cancel"
  );

  readonly InvoicePrepaymentCode = this.getPath(
    "v1/ar/invoice-prepayment/tax-number"
  );

  // REPORTS
  readonly ReportsMovement = this.getPath(
    "v1/logistic/stock-report/movement/download"
  );
  readonly ReportsHpp = this.getPath(
    "v1/logistic/inventory/hpp-value/report/download"
  );
  readonly ReportsAgingDetails = this.getPath("v1/ar/invoice/excel/download");
  readonly ReportsAgingSummary = this.getPath(
    "v1/ar/invoice/excel-summary/download"
  );
  readonly ReportsStockOpname = this.getPath(
    "v1/logistic/stock-report/stock-opname/download"
  );
  readonly ReportsHistoryBatch = this.getPath(
    "v1/batch/history/report/download"
  );
  readonly ReportsJobCosting = this.getPath(
    "v1/job-costing/download/waste-report"
  );
  readonly ReportsSalesDetail = this.getPath(
    "v1/sales-order/sales-detail/download"
  );
  readonly ReportsStockValuationDetail = this.getPath(
    "v1/logistic/stock-report/valuation-detail/download"
  );

  // Invoice
  readonly InvoiceRecurring = this.getPath("v1/ar/invoice/recurring");
  readonly InvoiceAR = this.getPath("v1/ar/invoice");
  readonly InvoiceARSubmit = this.getPath("v1/ar/invoice/submit");
  readonly InvoiceARCancel = this.getPath("v1/ar/invoice/cancel");
  readonly InvoiceARReject = this.getPath("v1/ar/invoice/reject");
  readonly InvoiceARApprove = this.getPath("v1/ar/invoice/approve");
  readonly InvoiceARCode = this.getPath("v1/ar/invoice/tax-number");
  readonly JoinInvoice = this.getPath("v1/ar/invoice-join");
  readonly JoinInvoiceAndPrepayment = this.getPath(
    "v1/ar/invoice-join/ar-prepayment"
  );
  readonly ReportTaxInvoice = this.getPath("v1/ar/invoice-join/printCSV");

  // AR Receipt
  readonly ArReceipt = this.getPath("v1/ar/receipt");
  readonly GenerateArReceipt = this.getPath("v1/ar/invoice/generateReceipt");
  readonly PrintArReceipt = this.getPath("v1/ar/invoice/printReceipt");
  readonly UploadInvoice = this.getPath("v1/file/invoice");

  // Aging
  readonly AgingDashboard = this.getPath("v1/report/aging/dashboard");
  readonly Aging = this.getPath("v1/report/aging");

  // Import Cost
  readonly ImportCost = this.getPath("v1/import-cost");

  // Code Translator
  readonly CodeTranslator = this.getPath("v1/code-translator");

  private getPath(path: string): string {
    return this.MASTER_PATH_GPW + path;
  }
}

// Export as Object
export const Api = new ApiUrl();

// Export as Class
export default ApiUrl;
