// Interfaces
import { RoutePathModel } from "../../interface/common.interface";

export const CONTACT_ROUTE: RoutePathModel = {
  path: "/contact",
  url: "/contact",
  name: "Contact Data",
  redirect: "/contact/read",
  meta: {
    breadcrumb: "Contact",
    title_head: "Contact",
    need_auth: true
  }
}

export const LIST_CONTACT_ROUTE: RoutePathModel = {
  path: "read",
  url: `${CONTACT_ROUTE.url}/read`,
  name: "List Contact",
  meta: {
    breadcrumb:[
      {
          name:'Contact Data',
          icon:'idcard',
          href:`${CONTACT_ROUTE.url}/read`
      },
    ],
    title_head: "List",
    need_auth: true
  }
}

export const CREATE_CONTACT_ROUTE: RoutePathModel = {
  path: "create",
  url: `${CONTACT_ROUTE.url}/create`,
  name: "Create Contact",
  meta: {
    breadcrumb:[
      {
          name:'Contact Data',
          icon:'idcard',
          href:`${CONTACT_ROUTE.url}/read`
      },
      {
        name:'Contact Data Create',
        href:`${CONTACT_ROUTE.url}/create`
    },
    ],
    title_head: "Create",
    mode: 'create',
    need_auth: true
  }
}

export const EDIT_CONTACT_ROUTE: RoutePathModel = {
  path: `${CONTACT_ROUTE.url}/update/:id`,
  url: `${CONTACT_ROUTE.url}/update/:id`,
  name: "Update Contact",
  meta: {
    breadcrumb:[
      {
          name:'Contact Data',
          icon:'idcard',
          href:`${CONTACT_ROUTE.url}/read`
      },
      {
        name:'Contact Data Update',
        href:`${CONTACT_ROUTE.url}/update`
    },
    ],
    title_head: "Update",
    mode: "edit",
    need_auth: true
  }
}