import { ResponseDetailProduct } from "@/models/interface-v2/product.interface";
import { initProduct } from "./resource/product.resource";

const state = {
  theProduct: initProduct(),
};

const mutations = {
  ["SET_PRODUCT"]: (st, payload) => {
    st.theProduct = payload;
  },
};

const actions = {
  mapDetailToForm: (context, data: ResponseDetailProduct): void => {
    const { commit } = context;
    const prod = {
      active: data.status,
      barcode: data.barcode,
      baseUnit: data.baseUnit,
      brand: data.brand,
      deletedItemCodeIds: [],
      deletedUomConversionIds: [],
      description: data.description,
      code: data.code,
      cutsCode: data.cutsCode,
      cutsCodeId: data.cutsCodeId,
      image: data.image,
      itemCodes: data.itemCodes,
      minimumStock: data.minimumStock,
      name: data.name,
      parent: data.parent,
      parentId: data.parentId,
      parentName: data.parentName,
      productAccount: {
        costOfSalesAccountId: data.costOfSalesAccountId,
        costOfSalesAccountName: data.costOfSalesAccountName,
        expensePurchaseAccountId: data.expensePurchaseAccountId,
        expensePurchaseAccountName: data.expensePurchaseAccountName,
        inventoryAccountId: data.inventoryAccountId,
        inventoryAccountName: data.inventoryAccountName,
        jobCostingAccountId: data.jobCostingAccountId,
        jobCostingAccountName: data.jobCostingAccountName,
        purchaseDiscountAccountId: data.purchaseDiscountAccountId,
        purchaseDiscountAccountName: data.purchaseDiscountAccountName,
        purchaseReturnAccountId: data.purchaseReturnAccountId,
        purchaseReturnAccountName: data.purchaseReturnAccountName,
        purchaseTaxId: data.purchaseTaxId,
        purchaseTaxName: data.purchaseTaxName,
        salesAccountId: data.salesAccountId,
        salesAccountName: data.salesAccountName,
        salesDiscountAccountId: data.salesDiscountAccountId,
        salesDiscountAccountName: data.salesDiscountAccountName,
        salesReturnAccountId: data.salesReturnAccountId,
        salesReturnAccountName: data.salesReturnAccountName,
        salesTaxId: data.salesTaxId,
        salesTaxName: data.salesTaxName,
        unbilledAccountId: data.unbilledAccountId,
        unbilledAccountName: data.unbilledAccountName,
      },
      productCategoryId: data.categoryId,
      categoryName: data.categoryName,
      trackAsAsset: data.trackAsAsset,
      trackAsInventory: data.trackAsInventory,
      trackAsPurchase: data.trackAsPurchase,
      trackAsSell: data.trackAsSell,
      type: data.type,
      supplierId: data.supplierId,
      supplierCode: data.supplierCode,
      supplierFullName: data.supplierFullName,
      uomConversions: data.uomConversions,
      id: data.id || "",
    };
    commit("SET_PRODUCT", prod);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
