import { RouteConfig } from 'vue-router';

// Models
import { config } from '@/models/constant/routes.constant';

const router: RouteConfig = {
    path: config.QR_ROUTE.path,
    name: config.QR_ROUTE.name,
    meta: config.QR_ROUTE.meta,
    redirect: config.QR_ROUTE.redirect,
    component: {
        render(c) { return c("router-view") }
    },
    children: [
        {
            path: config.SCAN_QR.path,
            name: config.SCAN_QR.name,
            meta: config.SCAN_QR.meta,
            component: () => import(/* webpackPrefetch: true */"./ScanQr.vue")
        },
        {
            path: config.DETAIL_QR.path,
            name: config.DETAIL_QR.name,
            meta: config.DETAIL_QR.meta,
            component: () => import(/* webpackPrefetch: true */"./DetailQr.vue")
        },
        {
            path: config.LIST_QR.path,
            name: config.LIST_QR.name,
            meta: config.LIST_QR.meta,
            component: () => import(/* webpackPrefetch: true */"./ListQr.vue")
        },
        {
            path: config.FORM_QR_CREATE.path,
            name: config.FORM_QR_CREATE.name,
            meta: config.FORM_QR_CREATE.meta,
            component: () => import(/* webpackPrefetch: true */"./FormQr.vue")
        },
        {
            path: config.FORM_QR_EDIT.path,
            name: config.FORM_QR_EDIT.name,
            meta: config.FORM_QR_EDIT.meta,
            component: () => import(/* webpackPrefetch: true */"./FormQr.vue")
        },
    ]
};

export default router;
