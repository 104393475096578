import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ISelectOption } from "@interface/common.interface";
import { ListMenuDto, ResponseListMenu } from "@interface/menu.interface";
import { userServices } from "@service/user.service";

type MenuField = Partial<{
  name: string;
}>;

export const useMenu = () => {
  const findAll = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListMenu> => {
    return userServices.getMenus(params);
  };

  const toOptions = (
    dt: Array<ListMenuDto>
  ): Array<ISelectOption<ListMenuDto>> => {
    return dt.map((item) => ({
      label: item.name,
      value: item.id,
      key: item.id,
      meta: item,
    }));
  };

  const findByQuery = ({ name = "" }: MenuField): Array<string> => {
    const queries: Array<string> = [];

    if (name) queries.push(`name~*${name}*`);

    return queries;
  };

  return { findAll, toOptions, findByQuery };
};
