import { UomConversions } from "@interface/uom.interface";

export const initProduct = () => {
  return {
    active: false,
    barcode: "",
    baseUnit: "",
    brand: "",
    deletedItemCodeIds: [],
    deletedUomConversionIds: [],
    description: "",
    image: "",
    itemCodes: [],
    minimumStock: 0,
    name: "",
    code: "",
    cutsCode: "",
    cutsCodeId: "",
    parent: false,
    parentId: "",
    productAccount: {
      costOfSalesAccountId: "",
      costOfSalesAccountName: "",
      expensePurchaseAccountId: "",
      expensePurchaseAccountName: "",
      inventoryAccountId: "",
      inventoryAccountName: "",
      jobCostingAccountId: "",
      jobCostingAccountName: "",
      assetCostAccountId: "",
      assetCostAccountName: "",
      assetClearingAccountId: "",
      assetClearingAccountName: "",
      assetDepreciationAccountId: "",
      assetDepreciationAccountName: "",
      assetAccumulationAccountId: "",
      assetAccumulationAccountName: "",
      purchaseDiscountAccountId: "",
      purchaseDiscountAccountName: "",
      purchaseReturnAccountId: "",
      purchaseReturnAccountName: "",
      purchaseTaxId: "",
      purchaseTaxName: "",
      salesAccountId: "",
      salesAccountName: "",
      salesDiscountAccountId: "",
      salesDiscountAccountName: "",
      salesReturnAccountId: "",
      salesReturnAccountName: "",
      salesTaxId: "",
      salesTaxName: "",
      unbilledAccountId: "",
      unbilledAccountName: "",
    },
    productCategoryId: "",
    categoryName: "",
    trackAsAsset: false,
    trackAsInventory: false,
    trackAsPurchase: false,
    trackAsSell: false,
    type: "", // as PRODUCT TYPE,
    supplierId: "",
    supplierCode: "",
    supplierFullName: "",
    uomConversions: [] as UomConversions[],
    id: "",
  };
};
