












import Vue from "vue";
import * as package_ from "../package.json";
import PageLoader from "@/components/custom/loader/PageLoader.vue";
import { createNamespacedHelpers } from "vuex";
import localStorageService from "./services/localStorage.service";

const { mapMutations } = createNamespacedHelpers("authStore");

export default Vue.extend({
  name: "App",
  components: {
    PageLoader,
  },
  data: () => ({
    title: "" ,
  }),
  watch: {
    $route: function(route) {
      document.title = `${this.$t(route.meta.title_head)} | ${process.env.VUE_APP_TITLE_PAGE}`;
    },
  },
  created() {
    this.setUserPrivileges();
  },
  mounted() {
    if("serviceWorker" in navigator) {
      // delete navigator if unused
      navigator.serviceWorker.getRegistrations()
        .then(registrations => {
          registrations.forEach(registration => {
            registration.unregister();
          });
        });
      // navigator.serviceWorker.register("/service-worker.js", { scope: window.location.href.split("/").slice(0, window.location.href.split("/").length -1).join("/") }).then(reg => {
      //     console.log("Service Worker Registration succesful, scope: " + reg.scope);
      // })
      // .catch(err => {
      //     console.log(err);
      // });
    }
    console.log(`Web Application Version ${process.env.NODE_ENV} ${package_.version}`);
  },
  methods: {
    ...mapMutations(
      ["SET_USER_PRIVILEGES"]
    ),
    setUserPrivileges(): void {
      const userPrivileges = localStorageService.loadUserPrivilege();
      this.SET_USER_PRIVILEGES(userPrivileges);
    }
  }
});
