export enum Actions {
  SET_USER_INFO                 = "setUserInfo",
  SET_LOGOUT                    = "setLogout",
  SET_LOADING                   = "setLoading",
  SET_DOWNLOAD_KEEP_GOING       = "setDownloadKeepGoing",
  SET_UPLOAD_KEEP_GOING         = "setUploadKeepGoing",
  SET_CLAIM_TIMEOUT             = "setClaimTimeout",
  SET_CLEAR_CLAIM_TIMEOUT       = "setClearClaimTimeout",
  SET_NEED_CHANGE_PASSWORD      = "setNeedChangePassword",
  SET_CATEGORY                  = "SET_CATEGORY",
  SET_COUNTRY                   = "SET_COUNTRY",
  ACTIONS_POST_LOGIN = 'ACTIONS_POST_LOGIN',
  ACTIONS_TOGGLE_SIDEBAR_MOBILE = 'ACTIONS_TOGGLE_SIDEBAR_MOBILE',
  ACTIONS_TOGGLE_SIDEBAR_DEKSTOP = 'ACTIONS_TOGGLE_SIDEBAR_DEKSTOP',
  ACTIONS_LOGOUT = 'ACTIONS_LOGOUT',
  ACTIONS_REFRESH_TOKEN = 'ACTIONS_REFRESH_TOKEN',
  ACTIONS_FORGOT_PASSWORD = 'ACTIONS_FORGOT_PASSWORD',
}