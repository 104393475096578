import { Api } from "@/models/class/api.class";
import {
  RequestProductCreate,
  RequestProductUpdate,
  ResponseDetailProduct,
  ResponseListProduct,
  ResponseProduct,
  ResponseProductCreate,
} from "@/models/interface-v2/product.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class ProductServices extends HttpClient {
  public constructor() {
    super();

    this.listProduct = this.listProduct.bind(this);
    this.detailProduct = this.detailProduct.bind(this);
    this.createProduct = this.createProduct.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
  }

  public listProduct(
    params: RequestQueryParamsModel
  ): Promise<ResponseListProduct> {
    return this.get<ResponseListProduct>(Api.Product, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public detailProduct(id: string): Promise<ResponseDetailProduct> {
    return this.get<ResponseDetailProduct>(Api.Product + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public createProduct(
    payload: RequestProductCreate
  ): Promise<ResponseProductCreate> {
    return this.post<ResponseProductCreate, RequestProductCreate>(
      Api.Product,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public updateProduct(
    payload: RequestProductUpdate,
    id: string
  ): Promise<ResponseProduct> {
    return this.put<ResponseProduct, RequestProductUpdate>(
      Api.Product + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const productService = new ProductServices()
