// Interfaces
import { RoutePathModel } from "../../interface/common.interface";

export const AUTH_ROUTE: RoutePathModel = {
  path: "/auth",
  url: "/auth",
  name: "Auth",
  redirect: "/auth/signin",
  meta: {
    breadcrumb: "Auth",
    title_head: "lbl_authentication",
    need_auth: false
  }
};

export const SIGNIN_ROUTE: RoutePathModel = {
  path: "signin",
  url: `${AUTH_ROUTE.url}/signin`,
  name: "SignIn",
  meta: {
    breadcrumb: "Sign In",
    title_head: "lbl_signin",
    need_auth: false
  }
};


export const SIGNUP_ROUTE: RoutePathModel = {
  path: "signup",
  url: `${AUTH_ROUTE.url}/signup`,
  name: "SignUp",
  meta: {
    breadcrumb: "Sign Up",
    title_head: "Sign Up",
    need_auth: false
  }
};

export const SIGNOUT_ROUTE: RoutePathModel = {
  path: "signout",
  url: `${AUTH_ROUTE.url}/signout`,
  name: "SignOut",
  meta: {
    breadcrumb: "Sign Out",
    title_head: "Sign Out",
    need_auth: false
  }
};

export const FORGOT_PASSWORD_ROUTE: RoutePathModel = {
  path: "forgot-password",
  url: `${AUTH_ROUTE.url}/forgot-password`,
  name: "ForgotPassword",
  meta: {
    breadcrumb: "Forgot Password",
    title_head: "Forgot Password",
    need_auth: false
  }
};

export const RESET_PASSWORD_ROUTE: RoutePathModel = {
  path: "reset-password/:token",
  url: `${AUTH_ROUTE.url}/reset-password`,
  name: "ResetPassword",
  meta: {
    breadcrumb: "Reset Password",
    title_head: "Reset Password",
    need_auth: false
  }
};

export const CHANGE_PASSWORD_ROUTE: RoutePathModel = {
  path: "change-password",
  url: `${AUTH_ROUTE.url}/change-password`,
  name: "ChangePassword",
  meta: {
    breadcrumb: "Change Password",
    title_head: "Change Password",
    need_auth: true
  }
};
