import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../../interface/common.interface";
export const SETTINGS_ROUTE: RoutePathModel = {
  path: "settings",
  url: "/settings",
  name: "Settings",
  meta: {
    breadcrumb: "Settings",
    title_head: "Settings",
    need_auth: true,
  },
};

//COA
export const COA_ROUTE: RoutePathModel = {
  path: "coa",
  url: "/coa",
  name: "Chart Of Account",
  meta: {
    breadcrumb: [
      {
        name: "Settings",
        icon: "setting",
      },
      {
        name: "Coa",
        href: "/coa",
      },
    ],
    title_head: "Chart Of Account",
    need_auth: true,
  },
};

//CURRENCY
export const CURRENCY_ROUTE: RoutePathModel = {
  path: "currency",
  url: "/currency",
  name: "Currency",
  meta: {
    title_head: "Currency",
    breadcrumb: [
      {
        name: "Settings",
        icon: "setting",
      },
      {
        name: "Currency",
        href: "/currency",
      },
    ],
    need_auth: true,
  },
};

//COMPANY
export const COMPANY_ROUTE: RoutePathModel = {
  path: "company",
  url: "/company",
  name: "Company",
  meta: {
    title_head: "Company",
    breadcrumb: [
      {
        name: "Settings",
        icon: "setting",
      },
      {
        name: "Company",
        href: "/company",
      },
    ],
    need_auth: true,
  },
};

//CALENDAR
export const CALENDAR_ROUTE: RoutePathModel = {
  path: "calendar",
  url: "/calendar",
  name: "Calendar",
  meta: {
    title_head: "Calendar",
    breadcrumb: [
      {
        name: "Settings",
        icon: "setting",
      },
      {
        name: "Calendar",
        href: "/calendar",
      },
    ],
    need_auth: true,
  },
};

export const UOM_ROUTE: RoutePathModel = {
  path: "uom",
  url: "/uom",
  name: "Uom",
  meta: {
    title_head: "Uom",
    breadcrumb: [
      {
        name: "Settings",
        icon: "setting",
      },
      {
        name: "Uom",
        href: "/uom",
      },
    ],
    need_auth: true,
  },
};

export const BANK_ROUTE: RoutePathModel = {
  path: "bank",
  url: "/bank",
  name: "Bank",
  meta: {
    title_head: "Bank",
    breadcrumb: [
      {
        name: "Settings",
        icon: "setting",
      },
      {
        name: "Bank",
        href: "/bank",
      },
    ],
    need_auth: true,
  },
};
export const TAX_ROUTE: RoutePathModel = {
  path: "tax",
  url: "/tax",
  name: "settings.tax",
  meta: {
    title_head: "Tax",
    breadcrumb: [
      {
        name: "Settings",
        icon: "setting",
      },
      {
        name: "Tax",
        href: "/tax",
      },
    ],
    need_auth: true,
  },
};
export const TAX_INVOICE_SERIAL_NUMBER: RoutePathModel = {
  path: "taxinvoiceserialnumber",
  url: "/taxinvoiceserialnumber",
  name: "Tax Invoice Serial Number",
  meta: {
    title_head: "Tax Invoice Serial Number",
    breadcrumb: [
      {
        name: "Settings",
        icon: "setting",
      },
      {
        name: "Tax Invoice Serial Number",
        href: "/settings/taxinvoiceserialnumber",
      },
    ],
    mode: Mode.CREATE,
    need_auth: true,
  },
};
export const DETAIL_TAX_INVOICE_SERIAL_NUMBER: RoutePathModel = {
  path: "taxinvoiceserialnumber/detail/:id",
  url: "/taxinvoiceserialnumber/detai/:idl",
  name: "settings.taxinvoicesn.detail",
  meta: {
    title_head: "Detail Tax Invoice Serial Number",
    breadcrumb: [
      {
        name: "Settings",
        icon: "setting",
      },
      {
        name: "Tax Invoice Serial Number",
        href: "/settings/taxinvoiceserialnumber",
      },
      {
        name: "Detail Tax Invoice Serial Number",
        href: "/settings/taxinvoiceserialnumber/detail/:id",
      },
    ],
    mode: Mode.EDIT,
    need_auth: true,
  },
};
export const TAX_INVOICE_CODE: RoutePathModel = {
  path: "taxinvoicecode",
  url: "/taxinvoicecode",
  name: "Tax Invoice Code",
  meta: {
    title_head: "Tax Invoice Code",
    breadcrumb: [
      {
        name: "Settings",
        icon: "setting",
      },
      {
        name: "Tax Invoice Code",
        href: "/settings/taxinvoicecode",
      },
    ],
    need_auth: true,
  },
};
export const PREFERENCES_ROUTE: RoutePathModel = {
  path: "preferences",
  url: "/preferences",
  name: "Preferences",
  meta: {
    title_head: "lbl_preferences",
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_preferences",
        href: "/preferences",
      },
    ],
    need_auth: true,
  },
};

export const TRANSLATOR_BATCH_ROUTE: RoutePathModel = {
  path: "/translator-batch",
  url: "/translator-batch",
  name: "settings.translatorbatch",
  meta: {
    title_head: "lbl_batch_translator",
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_batch_translator",
        href: "",
      },
    ],
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const TRANSLATOR_BATCH_CREATE_ROUTE: RoutePathModel = {
  path: "/translator-batch/create",
  url: "/translator-batch/create",
  name: "settings.translatorbatch.create",
  meta: {
    title_head: "lbl_batch_translator",
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_batch_translator",
        href: "/translator-batch",
      },
      {
        name: "lbl_create_batch_translator",
        href: "",
      },
    ],
    need_auth: true,
    mode: Mode.CREATE,
  },
};

export const TRANSLATOR_BATCH_EDIT_ROUTE: RoutePathModel = {
  path: "/translator-batch/edit/:id",
  url: "/translator-batch/edit/:id",
  name: "settings.translatorbatch.edit",
  meta: {
    title_head: "lbl_batch_translator",
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_batch_translator",
        href: "/translator-batch",
      },
      {
        name: "lbl_edit_batch_translator",
        href: "",
      },
    ],
    need_auth: true,
    mode: Mode.EDIT,
  },
};

export const TRANSLATOR_BATCH_DETAIL_ROUTE: RoutePathModel = {
  path: "/translator-batch/detail/:id",
  url: "/translator-batch/detail/:id",
  name: "settings.translatorbatch.detail",
  meta: {
    title_head: "lbl_batch_translator",
    breadcrumb: [
      {
        name: "lbl_settings",
        icon: "setting",
      },
      {
        name: "lbl_batch_translator",
        href: "/translator-batch",
      },
      {
        name: "lbl_view_batch_translator",
        href: "",
      },
    ],
    need_auth: true,
    mode: Mode.DETAIL,
  },
};
