import Vue from "vue";
import App from "./App.vue";
import wb from "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { i18n } from "./locales";
// Vue Components
import "./components";

// Library
import "./plugins/antdv";
import "./plugins/decimal";

// vue filter
import "./filters";

// WorkBox PWA
Vue.prototype.$workbox = wb;

// Vue Config
Vue.config.productionTip = false;
Vue.config.performance = true;
Vue.config.devtools = true;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
