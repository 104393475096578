import { useUser } from "@/hooks";
import { Mutations } from "@/models/enums/mutations.enum";
import store from "@/store";
import { FormValue } from "@/store/user.store";
import { NavigationGuard } from "vue-router";

export const createUserLoader: NavigationGuard = (_to, _from, next) => {
  store.dispatch("userStore/resetStore");
  next();
};

export const detailUserLoader: NavigationGuard = async (to, _from, next) => {
  const { findById, mapDetailDtoToForm } = useUser();
  try {
    const res = await findById(to.params.id);
    const form: FormValue = mapDetailDtoToForm(res);
    store.commit("userStore/setForm", form);
    next();
  } finally {
    store.commit(Mutations.SET_LOADING_COMPONENT, false);
  }
};
