
import Vue from "vue";
export default Vue.extend({
  name: "MNotification",
  methods: {
    showErrorMessage(content: string, prop?) {
      this.$message.error({content: this.$t(content, {...prop}).toString()});
    },
    showSuccessMessage(content: string) {
      this.$message.success({content: this.$t(content).toString()});
    },
    showNotifWarning(description: string, prop?): void {
      this.$notification.warning({
        message: this.$t("lbl_warning_title").toString(),
        description: this.$t(description, {...prop}).toString()
      });
    },
    showNotifError(description: string, prop?): void {
      this.$notification.error({
        message: this.$t("lbl_error_title").toString(),
        description: description ? this.$t(description, {...prop}).toString() : ""
      });
    },
    showNotifSuccess(description: string, prop?): void {
      this.$notification.success({
        message: this.$t("lbl_success").toString(),
        description: this.$t(description, {...prop}).toString()
      });
    },
    showNotifValidationError(key = "") {
      this.$notification.error({
        message: this.$t("lbl_validation_required_error").toString(),
        description: key.toString()
      });
    },
    showNotifFailCreate() {
      this.$notification.error({
        message: this.$t("lbl_error_title").toString(),
        description: this.$t("notif_create_fail").toString(),
      });
    },
    showNotifFailUpdate() {
      this.$notification.error({
        message: this.$t("lbl_error_title").toString(),
        description: this.$t("notif_update_fail").toString(),
      });
    },
    showCreateSuccessMesssage(): void {
      this.$message.success({
        content: this.$t("notif_create_success").toString()
      });
    },
    showUpdateSuccessMesssage(): void {
      this.$message.success({
        content: this.$t("notif_update_success").toString()
      });
    },
    showCancelSuccessMessage(): void {
      this.$message.success({
        content: this.$t("notif_cancel_success").toString()
      });
    },
    showSubmitSuccessMesssage(document = ""): void {
      this.$message.success({
        content: this.$t("notif_submit_success", { document }).toString()
      });
    },
    showSubmitFailedMesssage(): void {
      this.$message.error({
        content: this.$t("notif_submit_fail").toString()
      });
    },
    showUpdateFailedMesssage(): void {
      this.$message.error({
        content: this.$t("notif_update_fail").toString()
      });
    },
    showConfirmation(str = "lbl_are_you_sure_to_continue"): Promise<boolean> {
      return new Promise((resolve) => {
        this.$confirm({
          title: this.$t("lbl_confirmation"),
          content: () => this.$t(str),
          okText: this.$t("lbl_yes").toString(),
          cancelText: this.$t("lbl_cancel").toString(),
          icon: "exclamation-circle",
          onOk() {
            resolve(true);
          },
          onCancel() {
            resolve(false);
          }
        });
      });
    },
    showInfoModal(text: string): Promise<boolean> {
      return new Promise((resolve) => {
        this.$info({
          title: this.$t("lbl_info_title"),
          content: () => text,
          onOk() {
            resolve(true);
          },
          onCancel() {
            resolve(true);
          },
        });
      });
    },
  }
});
