import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ISelectOption, Pagination } from "@interface/common.interface";
import { DetailRoleDto, ListRoleDto } from "@interface/role.interface";
import { userServices } from "@service/user.service";

type RoleField = Partial<{
  name: string;
}>;
type OptionValueAs = keyof ListRoleDto;

export const useRole = () => {
  const findAll = (
    params: RequestQueryParamsModel
  ): Promise<Pagination<ListRoleDto[]>> => {
    return userServices.getRoles(params);
  };

  const findById = (id: string): Promise<DetailRoleDto> => {
    return userServices.getDetailRole(id);
  };

  const findByQuery = ({ name = "" }: RoleField): Array<string> => {
    const queries: Array<string> = [];
    if (name) queries.push(`name~*${name}*`);
    return queries;
  };

  const toOptions = (
    data: Array<ListRoleDto>,
    valueAs: OptionValueAs = "id"
  ): Array<ISelectOption<ListRoleDto>> => {
    return data.map((item) => ({
      key: item.id,
      label: item.name,
      meta: item,
      value: item[valueAs],
    }));
  };

  return { findAll, toOptions, findByQuery, findById };
};
