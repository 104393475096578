import { RequestJobCostingUpdate, ResponseJobCostingDetail } from "@interface/job-costing.interface";
import { initDetailJobCostingMeatroom, initRequestJobCostingMeatroomUpdate } from "./resource/job-costing-warehouse.resource";

const state = {
  reqUpdate: initRequestJobCostingMeatroomUpdate(),
  detailJobCosting: initDetailJobCostingMeatroom(),
};

const mutations = {
  ["SET_PAYLOAD_UPLOAD"]: (st, payload: RequestJobCostingUpdate): void => {
    st.reqUpdate = payload;
  },
  ["SET_DETAIL"]: (st, payload: ResponseJobCostingDetail): void => {
    st.detailJobCosting = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
