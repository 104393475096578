import { Decimal } from "decimal.js-light";
import { v4 as uuidv4 } from "uuid";

export const trimSpaceToUnderscore = (value: string): string => {
  return value.toUpperCase().replace(/\s/gi, "_");
};

export const groupBy = (objectArray: any[], property) => {
  return objectArray.reduce(function(acc, obj) {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

export const removeDuplicate = (arr: any[]): any[] => {
  return arr.filter(function(item, pos, self) {
    return self.indexOf(item) == pos;
  });
};

export const formatDecimalQty = (value: number): string | number => {
  if (value === null || value === undefined || isNaN(value)) return value;
  return new Decimal(value).toFixed(2, Decimal.ROUND_DOWN);
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const generateUuid = (): string => uuidv4();
