/* eslint-disable */

// Core
import store from "@/store";
import axios, { AxiosError, AxiosResponse } from "axios";
import querystring from "querystring";
import Vue from "vue";

import { AXIOS_CREATE, SCALE_TOKEN } from "@/models/constant/global.constant";

import { Messages } from "@/models/enums/messages.enum";

import { getWithExpiry } from "@/helpers/localstorage";
import { StorageKeys } from "@/models/enums/storages.enum";
import { HttpRequestConfig } from "@/models/interface/http.interface";

const instance = axios.create({
  ...AXIOS_CREATE,
});

export class HttpClient {
  [props: string]: any;
  queue: boolean = false;
  public constructor() {
    const noAuthorization = (config: any = {}) => {
      return config.hasOwnProperty("noAuthorization") && config.noAuthorization
        ? true
        : false;
    };

    const requestHandler = (request: any) => {
      // Set Request Time
      request.startTime = new Date().toISOString();

      // Set Authorization
      if (!noAuthorization(request)) {
        const token = getWithExpiry(StorageKeys.ACCESS_TOKEN);

        if (token) {
          request.headers.Authorization = "Bearer " + token;
        }

        if (request.url.includes("/api/scale")) {
          request.headers.Authorization = `Bearer ${SCALE_TOKEN}`;
        }
      }

      // Set Log
      if (process.env.VUE_APP_CONFIG_SHOW_LOG_HTTP === "true")
        this.requestLog(request);
      return request;
    };

    const errorHandler = (error: any) => {
      const vm = new Vue();
      return new Promise(async (resolve, reject) => {
        try {
          // Set Response Time
          error.response.config.endTime = new Date().toISOString();

          // Set Refresh Token When Response Status is 401 / UnAuthorized
          if (error.response.status === 401) {
            const payload = {
              client_id: process.env.VUE_APP_CLIENT_ID,
              client_secret: process.env.VUE_APP_CLIENT_SECRET,
              grant_type: "refresh_token",
              refresh_token: store.state.refresh_token,
            };
            const { data } = await axios.post(
              process.env.VUE_APP_PROXY_PATH + "oauth/token",
              querystring.stringify(payload),
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            );
            const token = `Bearer ${data.access_token}`;
            store.dispatch("ACTIONS_REFRESH_TOKEN", data);
            if (error.response.config.method.toLowerCase() === "get") {
              resolve(
                this.get(error.response.config.url, {
                  ...error.response.config,
                  Authorization: token,
                })
              );
            } else if (error.response.config.method.toLowerCase() === "post") {
              resolve(
                this.post(
                  error.response.config.url,
                  error.response.config.data,
                  { ...error.response.config, Authorization: token }
                )
              );
            } else if (error.response.config.method.toLowerCase() === "put") {
              resolve(
                this.put(
                  error.response.config.url,
                  error.response.config.data,
                  { ...error.response.config, Authorization: token }
                )
              );
            } else if (
              error.response.config.method.toLowerCase() === "delete"
            ) {
              resolve(
                this.delete(error.response.config.url, {
                  ...error.response.config,
                  Authorization: token,
                })
              );
            }
          }
        } catch (error: any) {
          console.log(error, "error catch http services line 90");
          if (error.response && error.response.status === 401) {
            store.dispatch("ACTIONS_LOGOUT");
            vm.$message.error(Messages.SESSION_END);
          }
          reject(error);
        } finally {
          if (process.env.VUE_APP_CONFIG_SHOW_LOG_HTTP === "true")
            this.errorLog(error.response);
          reject(error);
        }
      });
    };

    const successHandler = (response: any) => {
      response.config.endTime = new Date().toISOString();

      if (process.env.VUE_APP_CONFIG_SHOW_LOG_HTTP === "true")
        this.responseLog(response);
      return response;
    };
    instance.interceptors.request.use((request) => requestHandler(request));
    instance.interceptors.response.use(
      (response) => successHandler(response),
      // errorHandler
      (error) => errorHandler(error)
    );

    this.http = instance;

    this.get = this.get.bind(this);
    this.delete = this.delete.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
  }

  public get<T, R = AxiosResponse<T>>(
    url: string,
    config?: HttpRequestConfig
  ): Promise<R> {
    return this.http.get(url, config);
  }

  public post<T, B, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: HttpRequestConfig
  ): Promise<R> {
    return this.http.post(url, data, config);
  }

  public put<T, B, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: HttpRequestConfig
  ): Promise<R> {
    return this.http.put(url, data, config);
  }

  public delete<T, R = AxiosResponse<T>>(
    url: string,
    config?: HttpRequestConfig
  ): Promise<R> {
    return this.http.delete(url, config);
  }

  public success<T>(response: AxiosResponse<T>): T {
    return response.data;
  }

  public error(error: AxiosError<any>, message = true) {
    const vm = new Vue();

    const response = error.response as any;
    const data = response.data;

    if (data && typeof data === "object") {
      if (response.status !== 401 && message) {
        if (data.details) {
          vm.$message.error(data.details.join(" "));
        } else if (data.message) vm.$message.error(data.message);
        else if (data.title) vm.$message.error(data.title);
        else if (data.error_description)
          vm.$message.error(data.error_description);
        else if (data.error) vm.$message.error(data.error);
        else if (response.statusText) vm.$message.error(response.statusText);
        else vm.$message.error(vm.$t("notif_error_status_code").toString());
      }
    } else if (response.statusText) {
      vm.$message.error(response.statusText);
    } else {
      vm.$message.error(vm.$t("notif_error_status_code").toString());
    }

    return response;
  }

  private requestLog(request: any): void {
    const style = [
      "background: #e4f3fe",
      "border: 1px solid #2196f3",
      "color: #2196f3",
      "display: block",
      "width: 100%",
      "padding: 2px 10px",
      "font-weight: bold",
    ].join(";");

    const headers = Object.assign({}, request.headers);
    const data = Object.assign({}, request.data);

    console.group(`%cRequest Summary Log: ${request.url}`, style);
    console.log("%cRequest Time", style, request.startTime);
    console.log("%cMethod", style, request.method);
    console.log("%cHeaders", style, headers);
    console.log("%cData", style, data);
    console.log("%cTimeout", style, request.timeout);
    console.group(`%cRequest Full Log: ${request.url}`, style);
    console.log(request);
    console.groupEnd();
    console.groupEnd();
  }

  private responseLog(response: any): void {
    const style = [
      "background: #e9f6eb",
      "border: 1px solid #10b759",
      "color: #10b759",
      "display: block",
      "width: 100%",
      "padding: 2px 10px",
      "font-weight: bold",
    ].join(";");

    console.group(`%cResponse Summary Log: ${response.config.url}`, style);
    console.log("%cRequest Time", style, response.config.startTime);
    console.log("%cResponse Time", style, response.config.endTime);
    console.log("%cStatus", style, response.status);
    console.log("%cStatus Text", style, response.statusText);
    console.log("%cData", style, response.data);
    console.group(`%cResponse Full Log: ${response.config.url}`, style);
    console.log(response);
    console.groupEnd();
    console.groupEnd();
  }

  private errorLog(error: any): void {
    const style = [
      "background: #FEEAEE",
      "border: 1px solid #f95253",
      "color: #f95253",
      "display: block",
      "width: 100%",
      "padding: 2px 10px",
      "font-weight: bold",
    ].join(";");

    console.group(`%cError Summary Log: ${error.config.url}`, style);
    console.log("%cRequest Time", style, error.config.startTime);
    console.log("%cResponse Time", style, error.config.endTime);
    console.log("%cStatus", style, error.status);
    console.log("%cStatus Text", style, error.statusText);
    console.log("%cData", style, error.data);
    console.group(`%cError Full Log: ${error.config.url}`, style);
    console.log(error);
    console.groupEnd();
    console.groupEnd();
  }
}
