export enum GR_STATUS {
  RETURNED = "Returned",
  BILLED = "Billed",
  UNBILLED = "Unbilled",
  CANCEL = "Cancel",
  WAIT_FOR_APPROVAL = "Wait For Approval",
  DRAFT = "Draft",
  SUBMITTED = "Submitted",

  // newly GR use NEW
  NEW = "New"
}

export enum GR_LOCAL_STORAGE {
  GR_PURCHASE_FORM = "grpform"
}
