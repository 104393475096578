import { removeDuplicate } from "@/helpers/common";
import { ResponseDeliveryOrder } from "@/models/interface-v2/delivery-order.interface";
import { contactServices } from "@/services-v2/contact.service";
import { settingsServices } from "@/services/settings.service";
import {
  PostInvoice,
  RequestSubmitQC,
  ResponseDetailInvoiceAR,
} from "@interface/invoice.interface";
import { Decimal } from "decimal.js-light";
import {
  initDetail,
  initForm,
  initReqCreate,
  initTabDetailsSource,
  initTabStatusSource,
  initTabTaxDetailSource,
} from "./resource/invoice.resource";
import preferenceStore from "@/store/preference.store";
import { PREFERENCE_KEY } from "@/models/constant/preference.constant";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { APP_DECIMAL_PLACES } from "@enum/global.enum";
import localStorageService from "@/services/localStorage.service";
import { DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import { PRODUCT_TYPE } from "@/models/enums/product-type.enum";

interface State {
  reqPost: any;
  form: any;
  formCurrencyCode: string;
  detailInvoice: any;
  tabDetailSource: any[];
  tabTaxDetailSource: any;
  tabApplyPrepaymentSource: any[];
  tabStatusSource: any;
  tabDetailRow: any;
  formInvoiceQc: any[];
}

const state: State = {
  reqPost: initReqCreate(),
  form: initForm(),
  formCurrencyCode: "IDR",
  detailInvoice: initDetail(),
  tabDetailSource: [],
  tabTaxDetailSource: initTabTaxDetailSource(),
  tabApplyPrepaymentSource: [],
  tabStatusSource: initTabStatusSource(),
  tabDetailRow: initTabDetailsSource(), // store detail row from table on tab details
  formInvoiceQc: [] as RequestSubmitQC[],
};

const mutations = {
  ["SET_FORM_INVOICE_QC"]: (st, payload) => {
    st.formInvoiceQc = payload;
  },
  ["SET_DETAIL_INVOICE"]: (st, payload) => {
    st.detailInvoice = payload;
  },
  ["SET_REQ_POST"]: (st, payload: PostInvoice) => {
    st.reqPost = payload;
  },
  ["SET_FORM"]: (st, payload) => {
    st.form = payload;
  },
  ["SET_FORM_CURRENCY_CODE"]: (st, payload: string) => {
    st.formCurrencyCode = payload;
  },
  ["SET_TAB_DETAIL_SOURCE"]: (st, payload: any[]) => {
    st.tabDetailSource = payload;
  },
  ["SET_TAB_APPLY_PREPAYMENT_SOURCE"]: (st, payload: []) => {
    st.tabApplyPrepaymentSource = payload;
  },
  ["SET_TAB_TAX_DETAIL_SOURCE"]: (st, payload) => {
    st.tabTaxDetailSource = payload;
  },
  ["SET_TAB_STATUS_SOURCE"]: (st, payload) => {
    st.tabStatusSource = payload;
  },
  ["SET_TAB_DETAIL_ROW"]: (st, payload) => {
    st.tabDetailRow = payload;
  },
  ["SET_TAB_DETAIL_ROW_BY_KEY"]: (st, payload: { key; data }): void => {
    const idx = st.tabDetailSource.findIndex((x) => x.key === payload.key);
    if (idx === -1) return;
    // st.tabDetailSource[idx] = {...st.tabDetailSource[idx], ...payload.data};
    st.tabDetailSource[idx] = payload.data;
  },
  ["SET_TAB_DETAIL_ROW_BY_KEY_V2"]: (st, payload: { idx; data }): void => {
    st.tabDetailSource.forEach((x, i) => {
      if (i === payload.idx) {
        x.baseAmount = payload.data.baseAmount;
        x.uomId = payload.data.uomId;
        x.uom = payload.data.uom;
        x.qty = payload.data.qty;
        x.price = payload.data.price;
        x.percentDiscount = payload.data.percentDiscount;
        x.discountValue = payload.data.discountValue;
        x.incomeTaxId = payload.data.incomeTaxId;
        x.incomeTaxRate = payload.data.incomeTaxRate;
        x.incomeTaxValue = payload.data.incomeTaxValue;

        /**
         * calc sub total to handle
         * base amount changed
         */
        const taxAmount = new Decimal(x.baseAmount).times(
          new Decimal(x.taxRate).dividedBy(100)
        );
        x.taxAmount = taxAmount.toNumber();
        x.subTotal = new Decimal(x.baseAmount || 0)
          .plus(x.taxAmount || 0)
          .toNumber();
      }
    });
  },
  /**
   * map detail invoice to all
   * resources e.g
   * form header,
   * tab details,
   * tab tax detail,
   * tab prepayment,
   * tab status
   * @param st state
   */
  ["SET_INVOICE_RESOURCE"]: (st: State): void => {
    const detail = st.detailInvoice as ResponseDetailInvoiceAR;
    st.form = {
      branchId: detail.branchWarehouseId,
      customerId: detail.customerId,
      billAddress: detail.customerBillToAddress,
      shipAddress: detail.customerShipToAddress,
      salesName: detail.salesName,
      taxType: detail.taxType,
      top: detail.termOfPayment,
      invoiceDate: detail.invoiceDate,
      accountingDate: detail.accountingDate,
      currencyId: detail.currencyId,
      currency: detail.currency,
      currencyRate: detail.currencyRate,
      receivableAccountId: detail.receivableAccountId,
      invoiceDescription: detail.description,
      deliveryOrders: detail.deliveryOrders.map((x) => ({
        ...x,
        ["deliveryOrderNumber"]: x.documentNumber,
      })), // map documentNumber to deliveryOrderNumber
      deliveryOrderIds: detail.deliveryOrderIds,
      total: detail.invoiceAmount,
      additionalDiscount: {
        percent: detail.percentDiscount,
        amount: detail.discountValue,
      },
      freight: detail.totalFreight,
      totalDiscount: 0,
      totalTax: detail.totalTax,
      grandTotal: 0,
      deletedInvoiceARLineIds: [],
    };
    st.tabDetailSource = detail.invoiceARLines.map((x, i) => ({
      no: i + 1,
      documentReference: x.documentReference,
      productCode: x.productCode,
      productName: x.productName,
      productId: x.productId,
      brand: x.merk,
      qty: x.qty,
      qtyDO: x.qtyDO,
      uomId: x.uomId,
      uom: x.uom,
      price: x.price,
      revenueAccountId: x.revenueAccountId,
      revenueAccount: x.revenueAccount,
      baseAmount: x.baseAmount,
      taxCode: x.taxCode,
      taxId: x.taxId,
      taxAmount: x.taxValue,
      subTotal: x.subTotal || 0,
      description: x.description,
      alias: x.alias,
      id: x.id,
      incomeTaxId: x.incomeAccountTaxId,
      incomeTaxRate: x.incomeAccountTaxRate,
      incomeTaxValue: x.incomeAccountTaxValue,
      batchNumber: x.batchNumber,
      batchNumberId: x.batchNumberId,

      // additional
      fromDO: x.productType === PRODUCT_TYPE.STOCKABLE,
      key: x.id,
      incomeAccountTaxId: x.incomeAccountTax,
      percentDiscount: x.percentDiscount,
      discountValue: x.discountValue,
      billingPeriodModal: "",
      deliveryOrderLineId: x.deliveryOrderLineId,
      taxValue: x.taxValue,
      location: x.location,
      taxRate: x.taxRate || 0,
      taxRatePercent: 0,
      taxRateValue: 0,
      baseAmountValue: x.baseAmount,
      invoiceICBillingLineId: x.invoiceICBillingLineId,
      proRateDiscountPercent: 0,
      proRateDiscountValue: 0,
      dppAfterProRate: 0,
      dppTaxValue: 0,
    }));
    st.tabTaxDetailSource = {
      taxType: detail.taxType,
      taxRegistrationNumber: detail.taxRegistrationNumber,
      taxRegistrationName: detail.taxRegistrationName,
      taxInvoiceUploaded: detail.taxIsUploaded,
      taxInvoiceDate: detail.taxInvoiceDate,
      taxInvoiceNumber: detail.taxInvoiceNumber,
    };
    st.tabApplyPrepaymentSource = detail.applyPrepayment.prepaymentLines.map(
      (x, i) => ({
        no: i + 1,
        invoicePrepaymentNumber: x.invoicePrepaymentNo,
        invoicePrepaymentNumberId: x.invoicePrepaymentId,
        amount: x.appliedAmount,
        description: x.description,
        remainingAmount: x.remainingAmount,
        key: x.id,
        id: x.id,
        manual: true,
      })
    );
    st.tabStatusSource = {
      total: 0, // no need
      prepaymentUsed: {
        data: detail.applyPrepayment,
        total: detail.prepaymentUsed,
      },
      remainingInvoiceAmount: detail.remainingInvoiceAmount,
      return: {
        data: detail.returnItem.map((x, i) => ({ ...x, no: i + 1 })),
        total: detail.returnAmount,
      },
      accountReceivable: "", // no need
      accountReceivableId: detail.receivableAccountId,
      invoiceJoinNumber: detail.invoiceJoinNumber,
      invoiceJoinDate: detail.invoiceJoinDate,
      invoiceJoinId: detail.invoiceJoinId,
    };
    st.formInvoiceQc = detail.invoiceQc;
  },
};

const getters = {
  ["GET_LIST_SALES"]: (st): string[] => {
    const list = st.form.deliveryOrders.map(
      (x: ResponseDeliveryOrder) => x.salesName
    );
    return removeDuplicate(list);
  },
  ["GET_FORM_INVOICE_QC"]: (st): number => {
    return st.formInvoiceQc;
  },
  ["GET_TOTAL"]: (st): number => {
    return st.tabDetailSource.reduce((a, b) => {
      const gross = new Decimal(b.qty || 0).times(b.price || 0);
      return gross.plus(a).toNumber();
    }, 0);
  },
  ["GET_TOTAL_APPLIED_PREPAYMENT"]: (st): number => {
    return st.tabApplyPrepaymentSource.reduce(
      (a, b) => new Decimal(b.amount || 0).plus(a).toNumber(),
      0
    );
  },
  ["GET_TOTAL_DISCOUNT"]: (st): number => {
    const discountLines = st.tabDetailSource.reduce(
      (a, b) => new Decimal(b.discountValue || 0).plus(a).toNumber(),
      0
    );
    return new Decimal(discountLines || 0)
      .plus(st.form.additionalDiscount.amount || 0)
      .toNumber();
  },
  ["GET_TOTAL_TAX"]: (st): number => {
    const taxLines = st.tabDetailSource.reduce(
      (a, b) => new Decimal(b.taxAmount || 0).plus(a).toNumber(),
      0
    );
    return taxLines || 0;
  },
  /**
   * get freight from preference
   */
  ["GET_FREIGHT"]: (st): number => {
    const freightId =
      preferenceStore.state.appPreference.find(
        (x) => x.key === PREFERENCE_KEY.FEATURE_FREIGHT
      )?.value || "";
    const freightLines = st.tabDetailSource.reduce((a, b) => {
      if (b.productId === freightId) {
        return new Decimal(b.subTotal || 0).plus(a || 0).toNumber();
      }
      return a;
    }, 0);
    return freightLines || 0;
  },
  ["GET_SUM_BASE_AMOUNT"]: (st): number => {
    const sumBaseAmount = st.tabDetailSource.reduce(
      (a, b) => new Decimal(b.baseAmount || 0).plus(a).toNumber(),
      0
    );
    return sumBaseAmount || 0;
  },
  ["GET_GRAND_TOTAL"]: (st, getrs): number => {
    // return new Decimal(st.form.total || 0).minus(st.form.additionalDiscount.amount || 0).minus(getrs.GET_TOTAL_APPLIED_PREPAYMENT || 0).plus(getrs.GET_FREIGHT).minus(getrs.GET_TOTAL_DISCOUNT).plus(getrs.GET_TOTAL_TAX).toNumber();
    return new Decimal(getrs.GET_SUM_BASE_AMOUNT || 0)
      .plus(getrs.GET_TOTAL_TAX || 0)
      .toNumber();
  },
  // ["GET_SUM_GROSS_AFTER_DISCOUNT"]: (st, getrs): number => {
  //   const dp = localStorageService.load(APP_DECIMAL_PLACES.DP) ?? DECIMAL_PLACES_CURRENCY;
  //   return st.tabDetailSource.reduce((a, b) => {
  //     let gross = new Decimal(b.qty || 0).times(b.price || 0);
  //     const taxRate = new Decimal(b.taxRate || 0).dividedBy(100).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);

  //     if (getrs.TAX_CALCULATION_INCLUSIVE) {
  //       gross = gross.dividedBy(taxRate.plus(1)).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
  //     }

  //     const grossAfterDiscount = gross.minus(b.discountValue || 0);
  //     return new Decimal(grossAfterDiscount).plus(a).toNumber();
  //   }, 0);
  // },
  // ["GET_SUM_DPP_AFTER_PRORATE"]: (st): number => {
  //   return st.tabDetailSource.reduce((a, b) => {
  //     return new Decimal(b.dppAfterProRate || 0).plus(a).toNumber();
  //   }, 0);
  // },
  // ["GET_SUM_AMOUNT_AFTER_DISCOUNT_INVOICE"]: (st): number => {
  //   return st.tabDetailSource.reduce((a, b) => {
  //     return new Decimal(b.amountAfterProRateAdditionalDiscount || 0).plus(a).toNumber();
  //   }, 0);
  // },
  ["TAX_CALCULATION_EXCLUSIVE"]: (st): boolean => {
    return st.form.taxType === TAX_CALCULATION.EXCLUSIVE;
  },
  ["TAX_CALCULATION_INCLUSIVE"]: (st): boolean => {
    return st.form.taxType === TAX_CALCULATION.INCLUSIVE;
  },
  ["TAX_CALCULATION_NONE"]: (st): boolean => {
    return st.form.taxType === TAX_CALCULATION.NONE;
  },
  // ["GET_SUM_GROSS"]: (st): boolean => {
  //   return st.tabDetailSource.reduce((a, b) => {
  //     const gross = new Decimal(b.qty || 0).times(b.price);
  //     return gross.plus(a).toNumber();
  //   }, 0);
  // },
  // ["GET_SUM_GROSS_EXCLUDE_PRODUCT_SERVICE"]: (st, getrs): number => {
  //   const dp = localStorageService.load(APP_DECIMAL_PLACES.DP) ?? DECIMAL_PLACES_CURRENCY;
  //   return st.tabDetailSource.reduce((a, b) => {
  //     const taxRate = new Decimal(b.taxRate || 0).dividedBy(100).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
  //     let gross = new Decimal(0);
  //     // product bukan service
  //     if (b.fromDO) {
  //       if (getrs.TAX_CALCULATION_INCLUSIVE) {
  //         gross = new Decimal(b.qty || 0).times(b.price).dividedBy(taxRate.plus(1)).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).minus(b.discountValue);
  //       } else {
  //         gross = new Decimal(b.qty || 0).times(b.price).minus(b.discountValue);
  //       }
  //     }
  //     return gross.plus(a).toNumber();
  //   }, 0);
  // },
  // ["GET_SUM_PRO_RATE_ADITIONAL_DISCOUNT_AMOUNT"]: (st): number => {
  //   return st.tabDetailSource.reduce((a, b) => {
  //     return new Decimal(b.proRateAdditionalDiscountAmount || 0).plus(a).toNumber();
  //   }, 0);
  // },
  /**
   * sum amount after discount invoice
   * exclude service
   */
  // ["GET_SUM_AMOUNT_AFTER_DISCOUNT_INVOICE_EXCLUDE_SERVICE"]: (st): number => {
  //   return st.tabDetailSource.reduce((a, b) => {
  //     let amount = new Decimal(0);
  //     if (b.fromDO) {
  //       amount = new Decimal(b.amountAfterProRateAdditionalDiscount || 0);
  //     }
  //     return amount.plus(a).toNumber();
  //   }, 0);
  // },
  // ["GET_SUM_AMOUNT_PRODUCT_SERVICE"]: (st, getrs): number => {
  //   const dp = localStorageService.load(APP_DECIMAL_PLACES.DP) ?? DECIMAL_PLACES_CURRENCY;
  //   return st.tabDetailSource.reduce((a, b) => {
  //     const taxRate = new Decimal(b.taxRate || 0).dividedBy(100).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
  //     let amount = new Decimal(0);
  //     if (!b.fromDO) {
  //       if (getrs.TAX_CALCULATION_INCLUSIVE) {
  //         amount = new Decimal(b.qty || 0).times(b.price).dividedBy(taxRate.plus(1)).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN).minus(b.discountValue);
  //       } else {
  //         amount = new Decimal(b.qty || 0).times(b.price).minus(b.discountValue);
  //       }
  //     }
  //     return amount.plus(a).toNumber();
  //   }, 0);
  // }
};

const actions = {
  ["CALC_PRO_RATE_PRICING_V2"]: (context): void => {
    const dp =
      localStorageService.load(APP_DECIMAL_PLACES.DP) ??
      DECIMAL_PLACES_CURRENCY;
    let sumGrossAfterDiscountExcludeService = new Decimal(0);
    let sumAmountAfterDiscountInvoice = new Decimal(0);
    let sumTotalAppliedPrepayment = new Decimal(0);
    context.state.tabDetailSource.forEach((x) => {
      // C = A * B
      let gross = new Decimal(x.qty || 0).times(x.price || 0);

      // exclusive / none
      const taxRate = new Decimal(x.taxRate || 0)
        .dividedBy(100)
        .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);

      // find inclusive gross amount
      if (context.getters.TAX_CALCULATION_INCLUSIVE) {
        gross = gross
          .dividedBy(taxRate.plus(1))
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
      }

      // Calc Discount Value
      x.discountValue = gross
        .times(x.percentDiscount || 0)
        .dividedBy(100)
        .toNumber();

      // gross after discount line
      // E = C - D
      const grossAfterDiscount = gross.minus(x.discountValue || 0);
      // later define on model
      x.grossAfterDiscount = grossAfterDiscount.toNumber();

      // // break the loop
      // const sumGrossExcludeService = context.getters.GET_SUM_GROSS_EXCLUDE_PRODUCT_SERVICE;
      // const totalGrossExcludeProductService = new Decimal(sumGrossExcludeService || 0);

      // /**
      //  * start ratio calculation
      //  */
      // let proRateRatio = new Decimal(0);

      // if (x.fromDO) {
      //   proRateRatio = grossAfterDiscount.dividedBy(totalGrossExcludeProductService).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
      // }

      // x.proRateRatio = proRateRatio.toNumber();
      // // end

      // /**
      //  * additional discount invoice
      //  */
      // const additionalDiscountValue = new Decimal(context.state.form.additionalDiscount.amount || 0);

      // // pro rate discount per line
      // const proRateAdditionalDiscountAmount = additionalDiscountValue.times(proRateRatio).toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
      // x.proRateAdditionalDiscountAmount = proRateAdditionalDiscountAmount.toNumber();

      // /**
      //  * amount after discount invoice
      //  */
      // const amountAfterProRateAdditionalDiscount = grossAfterDiscount.minus(proRateAdditionalDiscountAmount);
      // x.amountAfterProRateAdditionalDiscount = amountAfterProRateAdditionalDiscount.toNumber();
    });

    // calc sum of gross after discount exclude service
    // formula = sum of gross after discount, exclude service
    sumGrossAfterDiscountExcludeService = context.state.tabDetailSource.reduce(
      (prev, curr) => {
        let grossAfterDiscountLine = new Decimal(0);
        // check if service product
        if (curr.fromDO) {
          grossAfterDiscountLine = new Decimal(curr.grossAfterDiscount || 0);
        }
        return grossAfterDiscountLine.plus(prev).toNumber();
      },
      0
    );

    context.state.tabDetailSource.forEach((x) => {
      // continue the loop
      // now you have sum gross after discount, exclude service product
      const sumGrossExcludeService = sumGrossAfterDiscountExcludeService;
      const totalGrossExcludeProductService = new Decimal(
        sumGrossExcludeService || 0
      );

      /**
       * start ratio calculation from additional
       * discount invoice
       */
      let proRateRatio = new Decimal(0);

      // exclude product service type
      if (x.fromDO) {
        proRateRatio = new Decimal(x.grossAfterDiscount || 0)
          .dividedBy(totalGrossExcludeProductService)
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
      }

      /**
       * now you have pro rate each line from
       * additional discount invoice
       */
      x.proRateRatio = proRateRatio.toNumber();
      // end

      /**
       * get additional discount invoice amount
       */
      const additionalDiscountValue = new Decimal(
        context.state.form.additionalDiscount.amount || 0
      );

      /**
       * calc amount of pro rate additional discount
       * formula:
       *
       * pro rate ratio from sum of gross after
       * discount exclude service
       *
       * X
       *
       * additional discount invoice amount
       *
       */
      const proRateAdditionalDiscountAmount = additionalDiscountValue
        .times(x.proRateRatio)
        .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
      // now you have, pro rate amount from additional discount exclude service product type
      x.proRateAdditionalDiscountAmount = proRateAdditionalDiscountAmount.toNumber();

      /**
       * find amount after pro rate additional discount invoice
       * formula = gross after discount each line - pro rate amount
       * aditional discount each line
       */
      const amountAfterProRateAdditionalDiscount = new Decimal(
        x.grossAfterDiscount || 0
      ).minus(x.proRateAdditionalDiscountAmount);
      /**
       * now you have amount after pro rate additional discount
       * on each line
       */
      x.amountAfterProRateAdditionalDiscount = amountAfterProRateAdditionalDiscount.toNumber();
    });

    /**
     * calc sum of amount after pro rate additional discount
     * EXCLUDE SERVICE PRODUCT TYPE
     */
    sumAmountAfterDiscountInvoice = context.state.tabDetailSource.reduce(
      (prev, curr) => {
        let amount = new Decimal(0);
        /**
         * exclude service product type
         */
        if (curr.fromDO) {
          amount = new Decimal(curr.amountAfterProRateAdditionalDiscount || 0);
        }
        return amount.plus(prev).toNumber();
      },
      0
    );
    // now you have sum of amount after pro rate discount invoice exclude service product type

    // get sum of total applied prepayment
    sumTotalAppliedPrepayment = new Decimal(
      context.getters.GET_TOTAL_APPLIED_PREPAYMENT || 0
    );

    context.state.tabDetailSource.forEach((x) => {
      /**
       * calc pro rate prepayment
       * formula = amount after discount invoice on each line
       *
       * divided by
       *
       * sum of amount after discount invoice EXCLUDE service producty type
       */
      const prepaymentRatio = new Decimal(
        x.amountAfterProRateAdditionalDiscount || 0
      )
        .dividedBy(sumAmountAfterDiscountInvoice)
        .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
      x.proRateAmountAfterAdditionalDiscountRatio = prepaymentRatio
        .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
        .toNumber();
      // now you have pro rate ratio pre payment for each line, exclude service product type

      // calc prepayment amount line
      const prepaymentLineAmount = new Decimal(
        x.proRateAmountAfterAdditionalDiscountRatio
      ).times(sumTotalAppliedPrepayment);
      x.proRatePrepaymentAmount = prepaymentLineAmount.toNumber();
      // now you have amount of pro rate prepayment for each line exclude service product type

      let baseAmount = new Decimal(0);
      let taxAmount = new Decimal(0);
      let subTotal = new Decimal(0);

      /**
       * tax rate
       */
      const taxRate = new Decimal(x.taxRate || 0)
        .dividedBy(100)
        .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);

      /**
       * calc base amount
       * formula = amount after discount invoice on each line
       *
       * minus
       *
       * amount of pro rate prepayment
       */
      baseAmount = new Decimal(
        x.amountAfterProRateAdditionalDiscount || 0
      ).minus(x.proRatePrepaymentAmount);

      if (!x.fromDO) {
        baseAmount = new Decimal(x.amountAfterProRateAdditionalDiscount || 0);
      }

      // tax calculation
      if (context.getters.TAX_CALCULATION_EXCLUSIVE) {
        taxAmount = baseAmount
          .times(taxRate)
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
        subTotal = baseAmount.plus(taxAmount);
        x.baseAmount = baseAmount
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
        x.taxAmount = taxAmount
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
        x.subTotal = subTotal
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
      } else if (context.getters.TAX_CALCULATION_INCLUSIVE) {
        const grossValue = new Decimal(x.qty || 0).times(x.price || 0);
        const baseAmountInclusive = grossValue.dividedBy(taxRate.plus(1));

        const baseAmountAfterDiscountLine = baseAmountInclusive.minus(
          x.discountValue
        );

        // additional discount invoice amount
        const additionalDiscountValue = new Decimal(
          context.state.form.additionalDiscount.amount || 0
        );
        /**
         * find amount of aditional discount amount after pro rate
         * on each line
         * formula = pro rate additional discount X additional discount invoice
         */
        const discountInvoiceValue = new Decimal(x.proRateRatio).times(
          additionalDiscountValue
        );
        const prepaymentValue = new Decimal(
          x.proRateAmountAfterAdditionalDiscountRatio
        ).times(sumTotalAppliedPrepayment);

        let $baseAmount = baseAmountAfterDiscountLine
          .minus(prepaymentValue)
          .minus(discountInvoiceValue);
        /**
         * check if service product type
         * exlcude it from pro rate prepayment amount
         * and pro rate additional discount amount
         */
        if (!x.fromDO) {
          $baseAmount = baseAmountAfterDiscountLine;
        }

        taxAmount = $baseAmount
          .times(taxRate)
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN);
        subTotal = $baseAmount.plus(taxAmount);
        x.baseAmount = $baseAmount
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
        x.taxAmount = taxAmount
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
        x.subTotal = subTotal
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
      } else {
        subTotal = baseAmount;
        x.baseAmount = baseAmount
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
        x.taxAmount = taxAmount
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
        x.subTotal = subTotal
          .toDecimalPlaces(+dp, Decimal.ROUND_HALF_EVEN)
          .toNumber();
      }
    });
  },
  ["ACT_PREFILL_TAB_TAX_DETAIL_SOURCE"]: ({ commit, state }): void => {
    contactServices.getContactData(state.form.customerId).then((cust) => {
      settingsServices
        .listOfTaxInvoiceCode({ search: `secureId~${cust.customerData.taxId}` })
        .then(() => {
          commit("SET_TAB_TAX_DETAIL_SOURCE", {
            ...state,
          });
        });
    });
  },
  ["ACT_FIND_CUSTOMER_TAX_DATA"]: ({ commit, state }): void => {
    if (!state.form.customerId) return;
    contactServices.getContactData(state.form.customerId).then((response) => {
      commit("SET_TAB_TAX_DETAIL_SOURCE", {
        ...state.tabTaxDetailSource,
        taxRegistrationNumber: response.taxRegisNumber,
        taxRegistrationName: response.taxRegisName,
      });
      settingsServices
        .listOfTaxInvoiceCode({
          search: `secureId~${response.customerData.taxId}`,
        })
        .then(({ data }) => {
          commit("SET_TAB_TAX_DETAIL_SOURCE", {
            ...state.tabTaxDetailSource,
            taxType: data.length ? data[0].code : "",
          });
        });
    });
  },
  ["RESET_FORM"]: ({ commit }): void => {
    commit("SET_FORM", initForm());
  },
  ["RESET_REQ_POST"]: ({ commit }): void => {
    commit("SET_REQ_POST", initReqCreate());
  },
  ["RESET_TAB_DETAIL_ROW"]: ({ commit }): void => {
    commit("SET_TAB_DETAIL_ROW", initTabDetailsSource());
  },
  ["RESET_STORE_INVOICE"]: ({ commit }): void => {
    commit("SET_REQ_POST", initReqCreate());
    commit("SET_FORM", initForm());
    commit("SET_FORM_CURRENCY_CODE", "IDR");
    commit("SET_TAB_DETAIL_SOURCE", []);
    commit("SET_TAB_APPLY_PREPAYMENT_SOURCE", []);
    commit("SET_TAB_STATUS_SOURCE", initTabStatusSource());
    commit("SET_TAB_DETAIL_ROW", initTabDetailsSource());
    commit("SET_DETAIL_INVOICE", initDetail());
    commit("SET_FORM_INVOICE_QC", []);
  },
  ["ACT_SET_TAB_DETAIL_ROW_BY_KEY"]: (
    context,
    payload: { key; data }
  ): void => {
    const idx = context.state.tabDetailSource.findIndex(
      (_x, i) => i === payload.data.no - 1
    );
    if (idx !== -1) {
      context.commit("SET_TAB_DETAIL_ROW_BY_KEY_V2", {
        idx,
        data: payload.data,
      });
      context.dispatch("RESET_TAB_DETAIL_ROW");
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
