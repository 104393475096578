import { RequestSalesOrderCreate, RequestSalesOrderUpdate, ResponseDetailSalesOrder, ResponseDetailSalesOrderLine } from "@interface/sales-order.interface";
import { initDetailSO, initFormSO, initReqCreate } from "./resource/sales-order.resource";

const state = {
  reqCreate: initReqCreate(),
  productLines: [],
  detailSO: initDetailSO(),
  deletedLines: [],
  formSO: initFormSO(),
};

const mutations = {
  ["SET_REQUEST_CREATE"]: (st, payload: RequestSalesOrderCreate): void => {
    st.reqCreate = payload;
  },
  ["SET_PRODUCT_LINES"]: (st, payload: any[]): void => {
    st.productLines = payload;
  },
  ["SET_DETAIL_SO"]: (st, payload: ResponseDetailSalesOrder): void => {
    st.detailSO = payload;
  },
  ["SET_DELETED_LINE"]: (st, payload: string[]): void => {
    st.deletedLines = payload;
  },
  ["SET_FORM_SO"]: (st, payload: any): void => {
    st.formSO = payload;
  },
};

const getters = {
};

const actions = {
  ["RESET_STATE"]: (context): void => {
    context.commit("SET_REQUEST_CREATE", initReqCreate());
    context.commit("SET_PRODUCT_LINES", []);
    context.commit("SET_DETAIL_SO", initDetailSO());
    context.commit("SET_DELETED_LINE", []);
    context.commit("SET_FORM_SO", initFormSO());
  },
  ["CONSTRUCT_REQUEST_FROM_DETAIL"]: (context): void => {
    const payload: RequestSalesOrderUpdate = {
      branchId: context.state.detailSO.branchId,
      creditLimit: context.state.detailSO.creditLimit,
      currencyId: context.state.detailSO.currencyId,
      customerBillToAddress: context.state.detailSO.customerBillToAddress,
      customerContact: context.state.detailSO.customerContact,
      customerId: context.state.detailSO.customerId,
      customerPoNumber: context.state.detailSO.customerPoNumber,
      customerShipToAddress: context.state.detailSO.customerShipToAddress,
      discountTotalInvoice: context.state.detailSO.discountTotalInvoice,
      etaDate: context.state.detailSO.etaDate,
      expireDate: context.state.detailSO.expireDate,
      freight: context.state.detailSO.freight,
      jobCostingId: context.state.detailSO.jobCostingId,
      notes: context.state.detailSO.notes,
      orderDate: context.state.detailSO.orderDate,
      salesName: context.state.detailSO.salesName,
      salesType: context.state.detailSO.salesType,
      taxCalculation: context.state.detailSO.taxCalculation,
      termOfPayment: context.state.detailSO.termOfPayment,
      salesOrderLines: context.state.detailSO.salesOrderLines.map((x: ResponseDetailSalesOrderLine) => ({
        batchId: x.batchNumberId,
        bottomPrice: x.bottomPrice,
        condition: x.condition,
        cutting: x.cutting,
        descriptionName: x.descriptionName,
        discount: x.discount,
        id: x.id,
        locationId: x.locationId,
        productId: x.productId,
        qty: x.qty,
        qtyAvailable: x.qtyAvailable,
        qtyFinal: x.qtyFinal,
        sellPrice: x.sellPrice,
        uomId: x.uomId,
        taxId: x.taxId,
        taxRate: x.taxRate || 0,
        alias: x.alias,
        isProcessed: x.isProcessed,
        processInfo: {...x.processInfo},
        /**
         * check line if from JC
         * batchNumber from JC should filled
         * automatically
         */
        batchDTOS: (x.batchNumberId ? [
          {
            batchId: x.batchNumberId,
            flag: true,
            locationId: x.locationId,
            qtyFinal: x.qtyFinal,
          }
        ] : []),
      })),
      deletedSalesOrderLineIds: [],
    };
    context.commit("SET_REQUEST_CREATE", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
