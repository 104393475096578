import { RouteConfig } from "vue-router";

import { config } from "@/models/constant/routes.constant";

const router: RouteConfig = {
  path: config.REPORTS_ROUTE.path,
  name: config.REPORTS_ROUTE.name,
  meta: config.REPORTS_ROUTE.meta,
  component: {
    render(c) { return c("router-view"); }
  },
  children: [
    {
      path: config.REPORTS_MOVING_ROUTE.path,
      name: config.REPORTS_MOVING_ROUTE.name,
      meta: config.REPORTS_MOVING_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */"@/views/reports/ReportMoving.vue")
    },
    {
      path: config.REPORTS_JOB_COSTING_ROUTE.path,
      name: config.REPORTS_JOB_COSTING_ROUTE.name,
      meta: config.REPORTS_JOB_COSTING_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */"@/views/reports/ReportJobCosting.vue")
    },
  ]
};

export default router;
