import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "@/models/interface/common.interface";

export const REPORTS_ROUTE: RoutePathModel = {
  path: "/report",
  url: "/report",
  name: "report",
  redirect: "/report",
  meta: {
    breadcrumb: [],
    title_head: "lbl_reports",
    need_auth: true,
  }
};

export const REPORTS_JOB_COSTING_ROUTE: RoutePathModel = {
  path: `${REPORTS_ROUTE.path}/job-costing`,
  url: `${REPORTS_ROUTE.path}/job-costing`,
  name: "report.jobcosting",
  meta: {
    breadcrumb: [
      {
        name: "lbl_reports",
        icon: "book",
      },
      {
        name: "lbl_report_job_costing",
        icon: "",
      },
    ],
    title_head: "lbl_reports",
    need_auth: true,
    mode: Mode.VIEW
  }
};

export const REPORTS_MOVING_ROUTE: RoutePathModel = {
  path: `${REPORTS_ROUTE.path}/moving`,
  url: `${REPORTS_ROUTE.path}/moving`,
  name: "report.moving",
  meta: {
    breadcrumb: [
      {
        name: "lbl_reports",
        icon: "book",
      },
      {
        name: "lbl_report_slow_fast_moving_item",
        icon: "",
      },
    ],
    title_head: "lbl_reports",
    need_auth: true,
    mode: Mode.VIEW
  }
};
