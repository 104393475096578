import "./custom/table";
import "./custom/pagination";
import "./custom/input";
import "./custom/modal";
import "./custom/qrcode";
import "./custom/button";
import "./custom/profile";

// shared
import "./shared/generate-batch-number";
import "./shared/generate-batch-inventory";
import "./shared/select-rack";
import "./shared/select-branch";
import "./shared/select-master-product-code";
import "./shared/select-master-product-name";
import "./shared/select-uom-conversions";
import "./shared/select-condition";

// Contact Data Form
import "@/views/contact-data/index";

// Invoice Prepayment
import "@/views/account-receivables/invoice-prepayment/index";

