import { QUOTATION_STATUS } from "@/models/enums/quotation.enum";
import { RequestQuotationConsumeItemCreate, RequestQuotationConsumeProcessInfoCreate, RequestQuotationConsumeProductCreate, ResponseQuotationDetail } from "@interface/quotation.interface";

export const initReqCreateProduceLine = (): RequestQuotationConsumeItemCreate => ({
  alias: "",
  condition: "",
  discount: 0,
  invoiceWeight: "",
  processInfo: [] as RequestQuotationConsumeProcessInfoCreate[],
  productConsume: [] as RequestQuotationConsumeProductCreate[],
  qtyOrder: 0,
  salesPrice: 0,
  warehouseNote: "",
  isProcessed: false,
});

export const initDetailQuotation = (): ResponseQuotationDetail => ({
  branchId: "",
  branchName: "",
  consume: [],
  currencyId: "",
  currencyName: "",
  customerBillToAddress: "",
  customerContact: "",
  customerId: "",
  customerName: "",
  customerCode: "",
  customerPo: "",
  customerPoAttachment: null as string | null,
  customerShipToAddress: "",
  etaDate: "",
  expireDate: "",
  freight: "",
  id: "",
  journalNumber: "",
  note: "",
  orderDate: "",
  rfqDate: "",
  rfqNumber: "",
  salesName: "",
  salesType: "",
  status: QUOTATION_STATUS.NEW,
  top: 0,
});
