import Vue from "vue";
import { Decimal } from "decimal.js-light";
import numeral from "numeral";
import { DECIMAL_PLACES_QTY } from "@/models/constant/global.constant";

Vue.filter("toDecimalQty", (value: number) => {
  if (value === null || value === undefined || isNaN(value)) return numeral(0).format("0,0.00");
  const newVal = new Decimal(value).toDecimalPlaces(DECIMAL_PLACES_QTY, 2).toNumber();
  return numeral(newVal).format("0,0.00");
});
