// Core
import { AxiosError } from "axios";
import { HttpClient } from "@/services/http.service";
// Class
import { Api } from "@/models/class/api.class";

// Interfaces
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  RequestContactDataCreate,
  ResponseContactData,
  ResponseListContactData,
} from "@/models/interface-v2/contact.interface";

export class ContactServices extends HttpClient {
  public constructor() {
    super();

    this.listContactData = this.listContactData.bind(this);
    this.createContactData = this.createContactData.bind(this);
    this.getContactData = this.getContactData.bind(this);
  }

  downloadReportContact = (params: RequestQueryParamsModel): Promise<Blob> => {
    return this.get<Blob>(Api.ContactData + "/report", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  public listContactData(
    params?: RequestQueryParamsModel
  ): Promise<ResponseListContactData> {
    return this.get<ResponseListContactData>(Api.ContactData, { params })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public createContactData(
    payload: RequestContactDataCreate
  ): Promise<ResponseContactData> {
    return this.post<ResponseContactData, RequestContactDataCreate>(
      Api.ContactData,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public updateContactData(
    payload: RequestContactDataCreate,
    id: string
  ): Promise<boolean> {
    return this.put<boolean, RequestContactDataCreate>(
      Api.ContactData + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public getContactData(id: string): Promise<ResponseContactData> {
    return this.get<ResponseContactData>(Api.ContactData + `/${id}`)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}
export const contactServices = new ContactServices();
