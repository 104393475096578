import { RouteConfig } from "vue-router";

// Models
import { config } from "@/models/constant/routes.constant";

const router: RouteConfig = {
  path: config.SETTINGS_ROUTE.path,
  name: config.SETTINGS_ROUTE.name,
  meta: config.SETTINGS_ROUTE.meta,
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: config.COA_ROUTE.path,
      name: config.COA_ROUTE.name,
      meta: config.COA_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./Coa.vue"),
    },
    {
      path: config.COMPANY_ROUTE.path,
      name: config.COMPANY_ROUTE.name,
      meta: config.COMPANY_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./Company.vue"),
    },
    {
      path: config.CURRENCY_ROUTE.path,
      name: config.CURRENCY_ROUTE.name,
      meta: config.CURRENCY_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./currency/index.vue"),
    },
    {
      path: config.CALENDAR_ROUTE.path,
      name: config.CALENDAR_ROUTE.name,
      meta: config.CALENDAR_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./Calendar.vue"),
    },
    {
      path: config.UOM_ROUTE.path,
      name: config.UOM_ROUTE.name,
      meta: config.UOM_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./UOM.vue"),
    },
    {
      path: config.BANK_ROUTE.path,
      name: config.BANK_ROUTE.name,
      meta: config.BANK_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./Bank.vue"),
    },
    {
      path: config.TAX_ROUTE.path,
      name: config.TAX_ROUTE.name,
      meta: config.TAX_ROUTE.meta,
      component: () => import(/* webpackPrefetch: true */ "./Tax.vue"),
    },
    {
      path: config.TAX_INVOICE_SERIAL_NUMBER.path,
      name: config.TAX_INVOICE_SERIAL_NUMBER.name,
      meta: config.TAX_INVOICE_SERIAL_NUMBER.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./taxinvoiceserialnumber/TaxInvoiceSerialNumber.vue"
        ),
    },
    {
      path: config.DETAIL_TAX_INVOICE_SERIAL_NUMBER.path,
      name: config.DETAIL_TAX_INVOICE_SERIAL_NUMBER.name,
      meta: config.DETAIL_TAX_INVOICE_SERIAL_NUMBER.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./taxinvoiceserialnumber/DetailTaxInvoiceSerialNumber.vue"
        ),
    },
    {
      path: config.TAX_INVOICE_CODE.path,
      name: config.TAX_INVOICE_CODE.name,
      meta: config.TAX_INVOICE_CODE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./TaxInvoiceCode.vue"),
    },
    {
      path: config.PREFERENCES_ROUTE.path,
      name: config.PREFERENCES_ROUTE.name,
      meta: config.PREFERENCES_ROUTE.meta,
      component: () =>
        import(/* webpackPrefetch: true */ "./preferences/Preferences.vue"),
    },
    {
      path: config.TRANSLATOR_BATCH_ROUTE.path,
      name: config.TRANSLATOR_BATCH_ROUTE.name,
      meta: config.TRANSLATOR_BATCH_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./translator-batch/TranslatorBatchList.vue"
        ),
    },
    {
      path: config.TRANSLATOR_BATCH_CREATE_ROUTE.path,
      name: config.TRANSLATOR_BATCH_CREATE_ROUTE.name,
      meta: config.TRANSLATOR_BATCH_CREATE_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./translator-batch/TranslatorBatchForm.vue"
        ),
    },
    {
      path: config.TRANSLATOR_BATCH_EDIT_ROUTE.path,
      name: config.TRANSLATOR_BATCH_EDIT_ROUTE.name,
      meta: config.TRANSLATOR_BATCH_EDIT_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./translator-batch/TranslatorBatchForm.vue"
        ),
    },
    {
      name: config.TRANSLATOR_BATCH_DETAIL_ROUTE.name,
      path: config.TRANSLATOR_BATCH_DETAIL_ROUTE.path,
      meta: config.TRANSLATOR_BATCH_DETAIL_ROUTE.meta,
      component: () =>
        import(
          /* webpackPrefetch: true */ "./translator-batch/TranslatorBatchForm.vue"
        ),
    },
  ],
};

export default router;
