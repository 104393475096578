export const initBatchingTransfer = () => {
  return {
    sourceLocationId: "",
    sourceLocationName: "",
    transferLines: [] as {
      batchId: string;
      batchNumber: string;
      productId: string;
      productCode: string;
      productName: string;
      uomId: string;
      uom: string;
      qty: number;
    }[]
  };
};