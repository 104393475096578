// Core
import { AxiosError} from "axios";
import { HttpClient } from "./http.service";
// Class
import { Api } from "@/models/class/api.class";

// Interfaces
import { ErrorResponseModel , RequestQueryParamsModel } from "@/models/interface/http.interface";

import {
  ResponseListOfCoa,
  RequestCoa,
  ResponseListOfTax,
  RequestTax,
  RequestMasterCurrency,
  ResponseListOfCurrency,
  ResponseListOfMasterUom,
  ResponseListOfAccountType,
  CreateCurrency ,ResponseListOfMasterCurrency,
  ResponseListOfCompany,
  RequestCompany,
  RequestUom,
  RequestUpdateClosePeriod,
  RequestCalendar,
  ResponseListOfCalendar,
  RequestTaxInvoiceCode,
  ResponseListOfTaxInvoiceSerialNumber,
  RequestTaxInvoiceSerialNumber,
  ResponseDetailOfTaxInvoiceSerialNumber,
  ResponsePreference,
  RequestUpdatePreference
} from "@/models/interface/settings.interface";
import { ResponseListOfTaxInvoiceCode } from "@/models/interface-v2/tax-invoice.interface";
export class SettingsServices extends HttpClient {
    public constructor() {
      super();
    }
     
      getPreference = (params: RequestQueryParamsModel): Promise<ResponsePreference[]> => {
        return this.get<ResponsePreference[]>(Api.Preference, { params })
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }

      public listOfCoa(params : RequestQueryParamsModel , path:string): Promise<ResponseListOfCoa> {
        return this.get<ResponseListOfCoa>(Api.Coa+path , { params })
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }

      public createCoa(payload: RequestCoa): Promise<boolean> {
        return this.post<boolean, RequestCoa>(Api.Coa , payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public updatePreference(payload: RequestUpdatePreference[]): Promise<boolean> {
        return this.put<boolean, RequestUpdatePreference[]>(Api.Preference , payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public updateCoa(payload: RequestCoa , path:string): Promise<boolean> {
        return this.put<boolean, RequestCoa>(Api.Coa+path , payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public deleteCoa(path:string): Promise<any> {
        return this.delete<any>(Api.Coa+path)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public listOfTax(params : RequestQueryParamsModel , path:string): Promise<ResponseListOfTax> {
        return this.get<ResponseListOfTax>(Api.Tax+path , { params })
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }

      public createTax(payload: RequestTax): Promise<boolean> {
        return this.post<boolean, RequestTax>(Api.Tax , payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public updateTax(payload: RequestTax , path:string): Promise<boolean> {
        return this.put<boolean, RequestTax>(Api.Tax+path , payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public deleteTax(path:string): Promise<any> {
        return this.delete<any>(Api.Tax+path)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public createMasterCurrency(payload: RequestMasterCurrency): Promise<boolean> {
        return this.post<boolean, RequestMasterCurrency>(Api.Currency , payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public createCurrency(payload: CreateCurrency): Promise<boolean> {
        return this.post<boolean, CreateCurrency>(Api.CurrencyConversion , payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public listOfCurrency(params : RequestQueryParamsModel , path:string): Promise<ResponseListOfCurrency> {
        return this.get<ResponseListOfCurrency>(Api.CurrencyConversion+path , { params })
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public listOfMasterCurrency(params : RequestQueryParamsModel , path:string): Promise<ResponseListOfMasterCurrency> {
        return this.get<ResponseListOfMasterCurrency>(Api.Currency+path , { params })
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public listOfMasterUom(params : RequestQueryParamsModel , path:string): Promise<ResponseListOfMasterUom> {
        return this.get<ResponseListOfMasterUom>(Api.MasterUom+path , { params })
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public listOfBaseUnit(params : RequestQueryParamsModel): Promise<ResponseListOfMasterUom> {
        return this.get<ResponseListOfMasterUom>(Api.MasterUom, { params })
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public deleteMasterUom(path:string): Promise<any> {
        return this.delete<any>(Api.MasterUom+path)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public createUom(payload: RequestUom): Promise<boolean> {
        return this.post<boolean, RequestUom>(Api.MasterUom , payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public updateUom(payload: RequestUom , path:string): Promise<boolean> {
        return this.put<boolean, RequestUom>(Api.MasterUom+path , payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public listOfAccountType(): Promise<ResponseListOfAccountType> {
        return this.get<ResponseListOfAccountType>(Api.AccountType)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public listOfCompany(params : RequestQueryParamsModel , path:string): Promise<ResponseListOfCompany> {
        return this.get<ResponseListOfCompany>(Api.Company+path , { params })
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }

      public createCompany(payload: RequestCompany): Promise<boolean> {
        return this.post<boolean, RequestCompany>(Api.Company , payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public updateCompany(payload: RequestCompany , path:string): Promise<boolean> {
        return this.put<boolean, RequestCompany>(Api.Company+path , payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public deleteCompany(path:string): Promise<any> {
        return this.delete<any>(Api.Company+path)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public listOfCalendar(params : RequestQueryParamsModel , path:string): Promise<ResponseListOfCalendar> {
        return this.get<ResponseListOfCalendar>(Api.Calendar+path , { params })
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public listOfTaxInvoiceCode(params : RequestQueryParamsModel): Promise<ResponseListOfTaxInvoiceCode> {
        return this.get<ResponseListOfTaxInvoiceCode>(Api.TaxCode , { params })
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public listOfTaxInvoiceSerialNumber(params : RequestQueryParamsModel): Promise<ResponseListOfTaxInvoiceSerialNumber> {
        return this.get<ResponseListOfTaxInvoiceSerialNumber>(Api.TaxSerialNumber , { params })
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public detailOfTaxInvoiceSerialNumber(params : RequestQueryParamsModel): Promise<ResponseDetailOfTaxInvoiceSerialNumber> {
        return this.get<ResponseDetailOfTaxInvoiceSerialNumber>(Api.TaxSerialNumberDetail , { params })
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public createTaxInvoiceSerialNumber(payload: RequestTaxInvoiceSerialNumber): Promise<boolean> {
        return this.post<boolean, RequestTaxInvoiceSerialNumber>(Api.TaxSerialNumber , payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public createTaxInvoiceCode(payload: RequestTaxInvoiceCode): Promise<boolean> {
        return this.post<boolean, RequestTaxInvoiceCode>(Api.TaxCode , payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public updateTaxInvoiceCode(payload: RequestTaxInvoiceCode , id:string): Promise<boolean> {
        return this.put<boolean, RequestTaxInvoiceCode>(Api.TaxCode + `/${id}`, payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public updateCalendarClosePeriod(payload: RequestUpdateClosePeriod): Promise<boolean> {
        return this.put<boolean, RequestUpdateClosePeriod>(Api.CalendarUpdate , payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
      public createCalendar(payload: RequestCalendar): Promise<boolean> {
        return this.post<boolean, RequestCalendar>(Api.Calendar , payload)
        .then(this.success)
        .catch((error: AxiosError<ErrorResponseModel>) => {
          throw this.error(error);
        });
      }
  }
  export const settingsServices = new SettingsServices();