import { SALES_ORDER_STATUS } from "@/models/enums/sales-order.enum";
import { TAX_CALCULATION } from "@/models/enums/tax.enum";
import { RequestSalesOrderCreate, RequestSalesOrderLine, ResponseDetailSalesOrder, ResponseDetailSalesOrderLine } from "@interface/sales-order.interface";
import { Moment } from "moment";

export const initReqCreate = (): RequestSalesOrderCreate => ({
  branchId: "",
  creditLimit: 0,
  currencyId: "",
  customerBillToAddress: "",
  customerContact: "",
  customerId: "",
  customerPoNumber: "",
  customerShipToAddress: "",
  discountTotalInvoice: 0,
  etaDate: "",
  expireDate: "",
  freight: "",
  jobCostingId: null,
  notes: "",
  orderDate: "",
  salesName: "",
  salesOrderLines: [] as RequestSalesOrderLine[],
  salesType: "",
  taxCalculation: TAX_CALCULATION.NONE,
  termOfPayment: 0,
});

export const initDetailSO = (): ResponseDetailSalesOrder => ({
  branch: "",
  branchId: "",
  createdDate: "",
  creditLimit: 0,
  currency: "",
  currencyId: "",
  customerBillToAddress: "",
  customerContact: "",
  customerId: "",
  customerName: "",
  customerPoNumber: "",
  customerShipToAddress: "",
  discountTotalInvoice: 0,
  etaDate: "",
  expireDate: "",
  freight: "",
  grandTotal: 0,
  id: "",
  jobCostingId: "",
  jobCostingNumber: "",
  modifiedDate: "",
  notes: "",
  orderDate: "",
  salesName: "",
  salesOrderLines: [] as ResponseDetailSalesOrderLine[],
  salesType: "",
  status: "" as SALES_ORDER_STATUS,
  taxCalculation: TAX_CALCULATION.NONE,
  termOfPayment: 0,
  total: 0,
  totalDiscount: 0,
  totalTax: 0,
});

export const initFormSO = () => ({
  customerId: undefined as string | undefined,
  customer: "",
  customerContact: "",
  customerPoNumber: "",
  customerShippingAddress: undefined as string | undefined,
  etaDate: null as null | Moment,
  etaHour: null as null | Moment,
  freight: undefined as string | undefined,
  orderDate: null as null | Moment,
  branch: undefined as string | undefined,
  salesName: "",
  salesType: undefined as string | undefined,
  expiredDate: null as null | Moment,
  notes: "",
  customerBillAddress: undefined as string | undefined,
  currency: undefined as string | undefined,
  top: undefined as string | undefined,
  taxCalculation: TAX_CALCULATION.NONE,
  creditLimit: 0,
  jobCostingNumber: "",
  status: "" as SALES_ORDER_STATUS,
  jobCostingId: "",
});

