import { Api } from "@/models/class/api.class";
import { RequestAccountingAccountCreate, ResponseAccountingAccount, ResponseAccountingAccountCreate, ResponseListAccountingAccount } from "@/models/interface-v2/accounting-account.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class AccountingAccountServices extends HttpClient {
  constructor() {
    super();
  }

  getListCOA(params: RequestQueryParamsModel): Promise<ResponseListAccountingAccount> {
    return this.get<ResponseListAccountingAccount>(Api.Coa, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  getDetailCOA(id: string): Promise<ResponseAccountingAccount> {
    return this.get<ResponseAccountingAccount>(`${Api.Coa}/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  deleteCoa(path: string): Promise<boolean> {
    return this.delete<boolean>(`${Api.Coa}/${path}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  updateCoa(payload: RequestAccountingAccountCreate, path:string): Promise<ResponseAccountingAccount> {
    return this.put<ResponseAccountingAccount, RequestAccountingAccountCreate>(`${Api.Coa}/${path}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  createCoa(payload: RequestAccountingAccountCreate): Promise<ResponseAccountingAccountCreate> {
    return this.post<ResponseAccountingAccountCreate, RequestAccountingAccountCreate>(Api.Coa, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

}

export const accountingAccountService = new AccountingAccountServices();
