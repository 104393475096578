// Interfaces
import { Mode } from "@/models/enums/global.enum";
import { RoutePathModel } from "../../interface/common.interface";

export const USER_ROUTE: RoutePathModel = {
  path: "/user-management",
  url: "/user-management",
  name: "user-management",
  redirect: "",
  meta: {
    breadcrumb: [
      {
        name: "lbl_user_management",
        icon: "team",
      },
    ],
    title_head: "lbl_user_management",
    need_auth: true,
  },
};

export const LIST_USER_ROUTE: RoutePathModel = {
  path: `${USER_ROUTE.path}/users`,
  url: `${USER_ROUTE.url}/users`,
  name: "user-management.users",
  meta: {
    breadcrumb: [
      {
        name: "lbl_user_management",
        icon: "team",
      },
      {
        name: "lbl_user",
        href: "/user-management/users",
      },
    ],
    title_head: "lbl_user",
    need_auth: true,
    mode: Mode.VIEW,
  },
};

export const CREATE_USER_ROUTE: RoutePathModel = {
  path: `${USER_ROUTE.path}/users/create`,
  url: `${USER_ROUTE.url}/users/create`,
  name: "user-management.users.create",
  meta: {
    breadcrumb: [
      {
        name: "lbl_user_management",
        icon: "team",
      },
      {
        name: "lbl_user",
        href: "/user-management/users",
      },
      {
        name: "lbl_create_user",
        href: "",
      },
    ],
    title_head: "lbl_create_user",
    mode: Mode.CREATE,
    need_auth: true,
  },
};

export const EDIT_USER_ROUTE: RoutePathModel = {
  path: `${USER_ROUTE.path}/users/:id/edit`,
  url: `${USER_ROUTE.path}/users/:id/edit`,
  name: "user-management.users.edit",
  meta: {
    breadcrumb: [
      {
        name: "lbl_user_management",
        icon: "team",
      },
      {
        name: "lbl_user",
        href: "/user-management/users",
      },
      {
        name: "lbl_edit_user",
      },
    ],
    title_head: "lbl_edit_user",
    mode: Mode.EDIT,
    need_auth: true,
  },
};

export const LIST_MENU_ROUTE: RoutePathModel = {
  path: "/menu/read",
  url: "/menu/read",
  name: "Menu",
  meta: {
    breadcrumb: [
      {
        name: "User Management",
        icon: "team",
      },
      {
        name: "Menu",
        href: "/menu/read",
      },
    ],
    title_head: "Menu",
    need_auth: true,
  },
};

export const LIST_ROLE_ROUTE: RoutePathModel = {
  path: "/role/read",
  url: "/role/read",
  name: "Role",
  meta: {
    breadcrumb: [
      {
        name: "User Management",
        icon: "team",
      },
      {
        name: "Role",
        href: "/role/read",
      },
    ],
    title_head: "Role",
    need_auth: true,
  },
};

export const LIST_PRIVILEGE_ROUTE: RoutePathModel = {
  path: "/privilege/read",
  url: "/privilege/read",
  name: "Privilege",
  meta: {
    breadcrumb: [
      {
        name: "User Management",
        icon: "team",
      },
      {
        name: "Privilage",
        href: "/privilege/read",
      },
    ],
    title_head: "Privilege",
    need_auth: true,
  },
};

export const CHANGE_PASSWORD_ROUTE: RoutePathModel = {
  path: "/change_password/update",
  url: "/change_password/update",
  name: "Change Password",
  meta: {
    breadcrumb: [
      {
        name: "Dashboard",
        icon: "dashboard",
        href: "/dashboard",
      },
      {
        name: "Change Password",
        href: "/change_password/update",
      },
    ],
    title_head: "Change Password",
    need_auth: true,
  },
};
