export enum Mode {
  CREATE  = "create",
  EDIT    = "edit",
  DELETE  = "delete",
  DETAIL  = "detail",
  SUBMIT  = "submit",
  VIEW    = "view",
  READ    = "read"
}

export enum Locales {
  ENGLISH = "en",
  BAHASA = "id",
  LANGUAGE = "lang"
}

export enum CURRENCY_CODE {
  IDR = "IDR"
}

export enum APP_DECIMAL_PLACES {
  DP = "dp",
}
