import Vue from "vue";
import moment from "moment";
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_FORMAT_TIME, DEFAULT_TIME_FORMAT } from "@/models/constant/date.constant";

Vue.filter("date", (value: string) => {
  const dt = moment(value);
  if (!dt.isValid() || value === undefined) return "-"; 
  return dt.format(DEFAULT_DATE_FORMAT);
});

Vue.filter("dateTime", value => {
  const dt = moment(value);
  if (!dt.isValid()) return "-"; 
  return dt.format(DEFAULT_DATE_FORMAT_TIME);
});

Vue.filter("time", value => {
  const dt = moment(value);
  if (!dt.isValid()) return "-"; 
  return dt.format(DEFAULT_TIME_FORMAT);
});

