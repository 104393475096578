import { initBatchingTransfer } from "./resource/batching.resource";

const state = {
  theBatch: initBatchingTransfer()
};

const mutations = {
  ["SET_BATCHING_TRANSFER"]: (st, payload): void => {
    st.theBatch = payload;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
