import { ISelectOption } from "@/models/interface-v2/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  ContactData,
  ResponseListContactData,
} from "@interface/contact.interface";
import { contactServices } from "@service/contact.service";

type ContactField = Partial<{
  firstName: string;
  lastName: string;
}>;

const useContactData = () => {
  const findAllEmployee = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListContactData> => {
    const employeeQuery = "employee~true_AND_active~true";
    const defaultQuery = [employeeQuery];
    if (params.search) defaultQuery.unshift(params.search);
    params.search = defaultQuery.join("_AND_");
    return contactServices.listContactData(params);
  };

  const toEmployeeOptions = (
    data: Array<ContactData>
  ): Array<ISelectOption<ContactData>> => {
    return data.map((item) => ({
      key: item.id,
      label: item.fullName ?? item.firstName,
      value: item.id,
      meta: item,
    }));
  };

  const findByQuery = ({
    firstName = "",
    lastName = "",
  }: ContactField): Array<string> => {
    const queries: Array<string> = [];

    if (firstName) queries.push(`firstName~*${firstName}*`);
    if (lastName) queries.push(`lastName~*${lastName}*`);

    return queries;
  };

  return { findAllEmployee, toEmployeeOptions, findByQuery };
};

export { useContactData };
