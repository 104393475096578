import { ResponseQuotationDetail } from "@/models/interface-v2/quotation.interface";
import { initDetailQuotation, initReqCreateProduceLine } from "./resource/quotation.resource";

const state = {
  reqCreateProduceLine: [],
  detailQuotation: initDetailQuotation(),
};

const mutations = {
  ["SET_REQ_CREATE_PRODUCE_LINE"]: (st, payload): void => {
    st.reqCreateProduceLine = payload;
  },
  ["SET_DETAIL_QUOTATION"]: (st, payload: ResponseQuotationDetail): void => {
    st.detailQuotation = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations
};