import { RequestJobCostingUpdate, ResponseJobCostingDetail } from "@interface/job-costing.interface";

export const initRequestJobCostingMeatroomUpdate = (): RequestJobCostingUpdate => {
  return {
    consume: [],
    operatorName: "",
  };
};

export const initDetailJobCostingMeatroom = (): ResponseJobCostingDetail => {
  return {
    branchId: "",
    branchName: "",
    consume: [],
    customerId: "",
    customerName: "",
    customerPo: "",
    etaDate: "",
    id: "",
    notes: "",
    operatorName: "",
    orderDate: "",
    rfqNumber: "",
    salesName: "",
    status: "",
  };
};

