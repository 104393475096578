import Vue from 'vue'
import VueI18n from 'vue-i18n'
import localStorageService from '@/services/localStorage.service';
import { Locales } from '@/models/enums/global.enum';

function loadLocaleMessages() {
  const locales = require.context(
    "../assets/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function loadPersistence(): string {
  return localStorageService.getCurrentLang() || Locales.ENGLISH
}

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: loadPersistence(),
  fallbackLocale: Locales.ENGLISH,
  messages: loadLocaleMessages()
})
