






































































import Vue from "vue";
import CSelectAccountingAccount from "@/components/shared/select-accounting-account/CSelectAccountingAccount.vue";

export default Vue.extend({
  name: "AccountDetails",
  components: {
    CSelectAccountingAccount,
  },
  props: ["dataCoa", "dataTop"],
  data() {
    return {
      formRules: {
        receivablesAccount: {
          label: this.$t("lbl_receivables_account"),
          name: "receivablesAccount",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "receivablesAccount",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        revenueAccount: {
          label: this.$t("lbl_revenue_account"),
          name: "revenueAccount",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "revenueAccount",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        payablesAccount: {
          label: this.$t("lbl_payables_account"),
          name: "payablesAccount",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "payablesAccount",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        prepaymentAccount: {
          label: this.$t("lbl_prepayment_account"),
          name: "prepaymentAccount",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "prepaymentAccount",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        termOfPayment: {
          label: this.$t("lbl_term_of_payment"),
          name: "termOfPayment",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "termOfPayment",
            {
              rules: [
                {
                  type: "string",
                  required: false,
                }
              ]
            }
          ],
        },
      },
    };
  },
});
