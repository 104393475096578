// eslint-disable-next-line
type DebounceProcess = <T = any>(fn: (...args: Array<T>) => void, delay?: number) => (...args: Array<T>) => void;
export const debounceProcess: DebounceProcess = (fn, delay) => {
  let timeoutId: number;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay || 1000);
  };
};