import { PropsModel } from "../interface/common.interface";

// Axios Setup
export const AXIOS_CREATE: PropsModel = {
  timeout: Number(process.env.VUE_APP_REQ_TIMEOUT),
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
};

export const MAXIMUM_FILE_SIZE = 25000000;

export const FILENAME_BOTTOM_PRICE_REPORT_TEMPLATE =
  "24122021033530-e949e-template_bottom_price.xlsx";

export const DECIMAL_PLACES_QTY = 2;
export const DECIMAL_PLACES_CURRENCY = 2;

export const APP_COMPANY_NAME = "PT. GLOBAL PRATAMA WIJAYA";

export const DEFAULT_PAGE_SIZE = 20;

export const SCALE_TOKEN =
  "eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NDc1NzYxMzB9.mo-uFAf1zU3UHFPTlVpJSrsz4ZEmOEhbegSob1H80WE";

export const ONE = 1;

export const FIRST_PAGE = ONE;
