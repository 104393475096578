import { Locales } from "@/models/enums/global.enum";
import { StorageKeys } from "@/models/enums/storages.enum";
import { USER } from "@/models/enums/user.enum";
import { IAuthorities } from "@/models/interface-v2/auth.interface";
import { DecodedToken } from "@/models/interface/common.interface";
import jwtDecode from "jwt-decode";

class LocalStorageService {

  save(key: string, value: string): void {
    window.localStorage.setItem(key, value);
  }

  load(key: string): string | null {
    return window.localStorage.getItem(key);
  }

  remove(key: string): void {
    return window.localStorage.removeItem(key);
  }

  getCurrentLang(): string | null {
    return this.load(Locales.LANGUAGE);
  }

  setCurrentLang(lang: string): void {
    this.save(Locales.LANGUAGE, lang);
  }

  saveUserRole(value: string): void {
    window.localStorage.setItem(USER.USER_ROLE, value);
  }

  loadUserRole(): string | null {
    return window.localStorage.getItem(USER.USER_ROLE);
  }

  loadUserAccessToken(): string | null {
    return window.localStorage.getItem(StorageKeys.ACCESS_TOKEN);
  }

  loadUserPrivilege(): IAuthorities[] {
    const token = this.loadUserAccessToken();
    if (token) {
      const privileges: DecodedToken = jwtDecode(token);
      const authorities: IAuthorities[] = [];
      privileges.authorities.forEach(item => {
        const str: string[] = item.split(":");
        const obj = authorities.find(x => x.key === str[0]);
        if (obj) {
          obj.privilege[str[1]] = str[1] === "create" || str[1] === "delete" || str[1] === "read" || str[1] === "update";
        } else {
          authorities.push({
            key: str[0],
            privilege: {
              create: str[1] === "create",
              delete: str[1] === "delete",
              read: str[1] === "read",
              update: str[1] === "update"
            }
          });
        }
      });
      return authorities;
    }
    return [];
  }

}

export default new LocalStorageService();
